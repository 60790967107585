import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { visuallyHidden } from "@mui/utils";
import { GetFinancierEmployees } from "hooks";
import { useAuth } from "context/user";
import { FinancerService } from "utility";
import { TransactionMail } from "./TransactionMail";
import { Btn } from "AbstractElements";

function createData(id, firstName, middleName, lastName, email, phone) {
  return {
    id,
    firstName,
    middleName,
    lastName,
    email,
    phone,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// firstName,
//   middleName,
//   lastName,
//   email,
//   phone

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "Voornaam",
  },
  {
    id: "middleName",
    numeric: true,
    disablePadding: false,
    label: "Tussenvoegsel",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Achternaam",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  // {
  //   id: "phone",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Telefoon",
  // },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Bedrijfsnaam",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    rows,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  // console.log(headCells);

  return (
    <TableHead stickyHeader>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ color: "#212F56" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCellsPopup = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Voornaam",
  },
  {
    id: "middleName",
    numeric: true,
    disablePadding: false,
    label: "Tussenvoegsel",
  },
  {
    id: "financierType",
    numeric: true,
    disablePadding: false,
    label: "Type financier",
  },
  {
    id: "lastName",
    numeric: true,
    disablePadding: false,
    label: "Achternaam",
  },
  {
    id: "awarded",
    numeric: false,
    disablePadding: false,
    label: "Geselecteerd",
  },
  {
    id: "participated",
    numeric: false,
    disablePadding: false,
    label: "Deelgenomen",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Acties",
  },
];

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    user,
    numSelected,
    onClickAddTransaction,
    handleAddCustomer,
    selected,
    handleEditCustomer,
    rows,
    setRows,
    selectedFinancerObject,
    financiersTranChecklistSel,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} geleselecteerd
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Werknemers
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const EmployeeDisplayForm = ({
  financialTypeIds,
  setTansactionAction,
  transection,
  trainsactionId,
  isAddTransaction,
  selectedFinancerObject,
  setSubpopupOpen,
  financiersTranChecklistSel,
  setSelectedEmployees,
  isFinancierTemplateEnable,
  transactionObj,
}) => {
  // console.log(financialTypeIds, financiersTranChecklistSel);
  const { user } = useAuth();
  const [errorState, setErrorState] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setSubpopupOpen(!modal);
  };
  const [displayNone] = useState("d-none");

  const [rows, setRows] = useState([]);
  const { data: employeeList, loading } = GetFinancierEmployees(
    selectedFinancerObject?.id
  );
  const [selectedListObject, setSelectedListObject] = useState();
  const [isEmailTemplateEnable, setIsEmailTemplateEnable] = useState(false);

  useEffect(() => {
    if (employeeList) {
      if (Object.keys(employeeList).length === 0) {
        return;
      }
      let translated = employeeList?.map((item) => {
        return createData(
          item.id,
          item.firstName,
          item.middleName,
          item.lastName,
          item.email,
          item.phone
        );
      });
      if (translated.length > 0) {
        setRows(translated);
      }
    }
  }, [employeeList]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("companyName");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [financiersCheckList, setFinanciersCheckList] = useState([]);
  const [allSelectedFinancierEmployee, setAllSelectedFinancierEmployee] =
    useState([]);
  const [financiersCheckListSelect, setFinanciersCheckListSelect] = useState(
    []
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedEmployees(newSelected);

      return;
    }
    setSelected([]);
    setSelectedEmployees([]);
  };

  useEffect(() => {
    employeeData(12);
  }, []);

  const employeeData = async (selectedId, name) => {
    const result = await FinancerService.getFinancierEmployees(selectedId);
    const data = await result.response;
    const nameOfCompany = name;
    if (data) {
      const transformedData = data.map((item) => ({
        ...item,
        companyName: nameOfCompany,
      }));
      return transformedData;
    }
  };

  const responses = [];

  useEffect(() => {
    if (financiersCheckListSelect.length > 0) {
      (async () => {
        try {
          responses.push(
            ...(await Promise.all(
              financiersCheckListSelect.map((item) =>
                employeeData(item.id, item.name)
              )
            ))
          );
        } catch (error) {
          console.error(error);
        }
        setAllSelectedFinancierEmployee(responses);
      })();
    }
  }, [financiersCheckListSelect]);

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setSelectedEmployees(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getFinancierData = async () => {
    try {
      const resp = await FinancerService.getFinancerList();

      const newStateData = resp.response.map((item) => {
        return { id: item.id, name: item.name };
      });
      setFinanciersCheckList(newStateData);

      const filteredFinancierArray = newStateData.filter((obj1) =>
        financiersTranChecklistSel.some((obj2) => obj2 === obj1.id)
      );

      setFinanciersCheckListSelect(filteredFinancierArray);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get data once
  useEffect(() => {
    getFinancierData();
  }, []);

  useEffect(() => {
    if (allSelectedFinancierEmployee.length > 0) {
      const employeeList = allSelectedFinancierEmployee.flat();
      setRows(employeeList);
    }
  }, [allSelectedFinancierEmployee]);

  const handleSelectedFinancierEmployees = () => {
    // console.log("button clicked");
    if (!isFinancierTemplateEnable) {
      setTansactionAction(transactionObj);
    }
    setIsEmailTemplateEnable(true);
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <React.Fragment>
        <Box sx={{ color: "#212F56", width: "100%" }}>
          <Paper
            sx={{ color: "#212F56", width: "100%", mb: 2 }}
            style={{ maxHeight: 700, overflow: "scroll" }}
          >
            {
              <EnhancedTableToolbar
                numSelected={selected.length}
                selected={selected}
                user={user}
                rows={rows}
                setRows={setRows}
                selectedFinancerObject={selectedFinancerObject}
              />
            }
            <TableContainer>
              <Table
                sx={{ color: "#212F56", minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
                // stickyHeader
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  // stickyHeader
                />
                <TableBody>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">{row.firstName}</TableCell>
                          <TableCell align="left">{row.middleName}</TableCell>
                          <TableCell align="left">{row.lastName}</TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">{row.companyName}</TableCell>
                        </TableRow>
                      );
                    })}

                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rijen per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} van ${count !== -1 ? count : `meer dan ${to}`}`
              }
            />
            <div className="mb-5">
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "mb-5 mt-2 float-end",
                  // onClick: () => setIsEmailTemplateEnable(true),
                  onClick: () => handleSelectedFinancierEmployees(),
                }}
              >
                Ga verder naar Mail Financiers
              </Btn>
            </div>
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Regelafstand"
          />
        </Box>
      </React.Fragment>
      {isEmailTemplateEnable && (
        <TransactionMail
          financialTypeIds={financialTypeIds}
          transection={transection}
          trainsactionId={trainsactionId}
          isEnable={isEmailTemplateEnable}
          isAddTransaction={isAddTransaction}
          financiersTranChecklistSel={financiersTranChecklistSel}
          selected={selected}
          addNewTransaction={setTansactionAction}
          isFinancierTemplateEnable={isFinancierTemplateEnable}
          transactionObj={transactionObj}
          // selectedFinancerObject={selectedFinancerObject}
          // setSubpopupOpen={setSubpopupOpen}
        ></TransactionMail>
      )}
    </ThemeProvider>
  );
};

export default EmployeeDisplayForm;
