import React, { Fragment } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { H4 } from "../../../AbstractElements";
import ConfigDB from "../../../Config/ThemeConfig";
import "./Knob.css";

const primary =
  localStorage.getItem("default_color") || ConfigDB.data.color.primary_color;

const CardData = () => {
  return (
    <Fragment>
      <Row>
        <Col sm="6" xl="6" lg="6">
          <Card className="o-hidden border-0">
            <CardBody className={"bg-primary"}>
              <div className="media-body text-center">
                <span className="m-0">1</span>
                <H4>2</H4>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="6" lg="6">
          <Card className="o-hidden border-0">
            <CardBody className={"bg-secondary"}>
              <div className="media-body text-center">
                <span className="m-0">1</span>
                <H4>2</H4>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col sm="6" xl="6" lg="6">
          <Card className="o-hidden border-0">
            <CardBody className={"bg-secondary"}>
              <div className="media-body text-center">
                <span className="m-0">1</span>
                <H4>2</H4>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="6" lg="6">
          <Card className="o-hidden border-0">
            <CardBody className={"bg-primary"}>
              <div className="media-body text-center">
                <span className="m-0">1</span>
                <H4>2</H4>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CardData;
