import React, { Fragment, useState, useRef } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CardHeader,
  Input,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";
import { AuthService } from "utility";
import { MarginLeft, PaddingLeft } from "Constant";

const RandvoorwaardenFormEdit = ({ randvoorwaarden, setRandvoorwaarden }) => {
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState();
  const [randboorwaardenList, setRandboorwaardenList] = useState();
  const toggle = () => setModal(!modal);
  const inputFileRef = useRef();
  const [displayNone] = useState("d-none");
  const ModalTitle = "Selecteer randvoorwaarden";
  const handleBtnClick = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);
    getFinancierData(formData);
  };
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const getFinancierData = async (formData) => {
    try {
      const resp = await AuthService.uploadFile(formData);
      setRandvoorwaarden(resp.response);
      const splitedData = resp.response[0].split("\n");
      setRandboorwaardenList(splitedData);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="color-secondary font-w800">RandvoorwaardenForm</h5>
            <Input
              style={{ marginLeft: "20px", marginRight: "20px" }}
              type="file"
              onChange={handleChange}
            />
            <Btn attrBtn={{ color: "primary", onClick: handleBtnClick }}>
              Toevoegen
            </Btn>
          </div>
        </CardHeader>
        <CardBody>
          <div className="col-sm-12">
            <div className="table-responsive">
              <table className="table table-bordernone m-0">
                <thead>
                  <tr>
                    <th scope="col">RandvoorwaardenForm</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ whiteSpace: "break-spaces" }}>
                    {randboorwaardenList ? (
                      <td style={{ whiteSpace: "break-spaces" }}>
                        <div style={{ whiteSpace: "break-spaces" }}>
                          {randboorwaardenList}
                        </div>
                      </td>
                    ) : (
                      <td>No Data Found</td>
                    )}
                  </tr>
                  {/* <div>{randboorwaardenList}</div>
                  {randboorwaardenList?.map((item) => {
                    // <div>{item}</div>
                    <tr key={item}>
                      <td><div>Alexander Lorem ipsum, dolor .</div></td>
                    </tr>
                  })}
                  <tr>
                    <td>Alexander Lorem ipsum, dolor .</td>
                  </tr>
                  <tr>
                    <td>Alexander Lorem ipsum, dolor sit amet consectetur .</td>
                  </tr>
                  <tr>
                    <td>
                      Alexander Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Alexander Lorem ipsum, dolor sit amet consectetur
                      adipisicing elit.
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </CardBody>
      </Card>
      <CommonModal
        isOpen={modal}
        title={ModalTitle}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
      >
        <Container>
          <Row className="p-t-20 p-b-50">
            <Col sm="5" className="p-r-50 p-l-50">
              <Card className="min-h-200">{""}</Card>
            </Col>
            <Col
              sm="2"
              className="d-flex align-items-center justify-content-center"
            >
              <Row className="justify-content-md-center g-2">
                <Btn attrBtn={{ color: "primary" }}>
                  Toevoegen <i className="fa fa-arrow-right"></i>
                </Btn>
                <Btn attrBtn={{ color: "primary" }}>
                  <i className="fa fa-arrow-left"></i> Verwijderen
                </Btn>
              </Row>
            </Col>
            <Col sm="5" className="p-l-50 p-r-50">
              <Card className="min-h-200">{""}</Card>
            </Col>
          </Row>
        </Container>
      </CommonModal>
    </Fragment>
  );
};

export default RandvoorwaardenFormEdit;
