import { BaseService } from "./base";
import { BrowserUtility } from "../browser-utility";
import { APIPath, StorageConstant } from "utility/constant";

class User {
  getUserList(reqData) {
    return BaseService.get(`${APIPath.userList}?usertypes=1,2`);
  }

  getActivateLoginForPerson(personId, password) {
    return BaseService.post(
      `${APIPath.activateLoginForPerson}?personId=${personId}&password=${password}`
    );
  }

  getEmployeeList(reqData) {
    return BaseService.get(`${APIPath.userList}?userType=2`);
  }

  setUserList(reqData) {
    return BaseService.post(
      `${APIPath.insertUpdateFinancierEmployee}`,
      reqData,
      true
    );
  }

  deleteFinancier(reqData) {
    return BaseService.post(
      `${APIPath.deleteFinancier}?identifier=${reqData}`,
      reqData,
      true
    );
  }

  deleteFinancierEmployee(reqData) {
    return BaseService.post(
      `${APIPath.deleteFinancierEmployee}?id=${reqData}`,
      reqData,
      true
    );
  }

  insertUpdateFinancier(reqData) {
    return BaseService.post(`${APIPath.insertUpdateFinancier}`, reqData, true);
  }

  insertUpdateFinancierEmployee(reqData) {
    return BaseService.post(
      `${APIPath.insertUpdateFinancierEmployee}`,
      reqData,
      true
    );
  }

  deleteUser(reqData) {
    return BaseService.post(
      `${APIPath.deleteUser}?identifier=${reqData}`,
      reqData,
      true
    );
  }
}

const UserService = new User();
Object.freeze(UserService);
export { UserService };
