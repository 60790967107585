import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { styled as newstyled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Btn } from "../../../AbstractElements";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BiedingenHistory from "./BiedingenHistory";
import { useAuth } from "context/user";
import TablePagination from "@mui/material/TablePagination";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TransctiesService } from "utility";

import { visuallyHidden } from "@mui/utils";
import styled from "styled-components";
import { Rowing } from "@mui/icons-material";

function createData(
  financierId,
  transactionFinanciersId,
  financierName,
  proposalPercentage,
  companyName,
  comapnyCode,
  fee,
  surcharge,
  swapRate,
  showFees,
  userName,
  isSelected,
  proposalId,
  isSelectedByDealer,
  isSelectedByWingman
) {
  return {
    financierId,
    transactionFinanciersId,
    financierName,
    proposalPercentage,
    companyName,
    comapnyCode,
    fee,
    surcharge,
    swapRate,
    showFees,
    userName,
    isSelected,
    proposalId,
    isSelectedByDealer,
    isSelectedByWingman,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

const DangerText = styled.div`
  color: #ff0000cc !important;
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 15px;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Naam geldgever",
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "Naam User",
  },
  {
    id: "tariefExclFee",
    numeric: false,
    disablePadding: false,
    label: "Tarief (excl. fee)",
  },

  {
    id: "fee",
    numeric: false,
    disablePadding: false,
    label: "Fee",
  },
  {
    id: "tariefInclFee",
    numeric: false,
    disablePadding: false,
    label: "Tarief (incl. fee)",
  },
  {
    id: "opslag",
    numeric: false,
    disablePadding: false,
    label: "Opslag",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "history",
    numeric: false,
    disablePadding: false,
    label: "Logboek",
  },
];

const StyledTableCell = newstyled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, onClickAddTransaction } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", color: "#212F56" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Biedingen
      </Typography>

      {/* <IconButton>
        <Link to="/RecenteVoltooideTranscties">
          <h6 style={{ color: "#FF9E00" }}> Bekijk alles</h6>
        </Link>
      </IconButton> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const Biedingen = (props) => {
  const {
    refresh,
    financiarList,
    isTTCPassed,
    detail,
    proposals,
    setDisableTTC,
  } = props;
  const [rows, setRows] = useState([]);
  const { user } = useAuth();
  const [isSignedByDealer, setIsSignedByDealer] = useState(false);
  const [signedRow, setSignedRow] = useState([]);

  useEffect(() => {
    if (proposals.length > 0) {
      const rowArray = proposals?.map((item) => {
        return createData(
          item.financierId,
          item.transactionFinanciersId,
          item.financierName,
          item.proposalPercentage,
          item.companyName,
          item.comapnyCode,
          item.fee,
          item.surcharge,
          item.swapRate,
          item.showFees,
          item.userName,
          item.isSelected,
          item.id,
          item.isSelectedByDealer,
          item.isSelectedByWingman
        );
      });
      const biddingArray = rowArray.filter((item) => item.surcharge !== 0.0);
      const withOutBiddingArray = rowArray.filter(
        (item) => item.surcharge === 0.0
      );
      const arrayForDisplay = biddingArray.concat(withOutBiddingArray);
      setRows(arrayForDisplay);
    } else {
      return;
    }
  }, [proposals]);

  const navigate = useNavigate();

  const onClickAddTransaction = () => {
    navigate(`/transacties/add`);
  };

  const [historyId, setHistoryId] = useState();

  const onClickDetailsransaction = (e, row) => {
    setHistoryId(row?.financierId);
    historyViewToggle();
    // navigate(`/transacties/dashboard/` + row.transactionFinanciersId);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("companyType");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [oldProposals, setOldProposals] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    if (oldProposals.length === 0) {
      setOldProposals(rows);
    } else {
      return;
    }
  }, [oldProposals]);

  const checkForUpdate = (id) => {
    const newObj = oldProposals.findIndex((item) => {
      return item.proposalId === id;
    });
    if (newObj === -1) {
      if (rows.length > oldProposals.length) {
        setTimeout(() => {
          setOldProposals(rows);
        }, 10000);
        return "#fff";
      } else {
        setTimeout(() => {
          setOldProposals(rows);
        }, 10000);
        return "#fff4b3";
      }
    } else {
      return null;
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.transactionFinanciersId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, transactionFinanciersId) => {
    const selectedIndex = selected.indexOf(transactionFinanciersId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, transactionFinanciersId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const { control, handleSubmit } = useForm();

  const isSelected = (transactionFinanciersId) =>
    selected.indexOf(transactionFinanciersId) !== -1;

  const [modal, setModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [accepectedModal, setAccepectedModal] = useState(false);
  const [proposalPending, setProposalPending] = useState(true);
  const [proposalSelected, setProposalSelected] = useState(false);
  const [rowsForDisplay, setRowsForDisplay] = useState([]);

  const [isAKL, setAKL] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const accepectedToggle = () => {
    setAccepectedModal(!accepectedModal);
  };

  const historyViewToggle = () => setHistoryModal(!historyModal);
  const NewMessage = "Transactie toekennen";
  // Accept flow parameter
  const historyPopupTitle = "Logboek aanpassingen";
  const [displayNone] = useState("d-none");
  const [selectedRow, setSelectedRow] = useState();
  const [isTTCEmpty, setIsTTCEmpty] = useState(true);
  // const [notBidded, setNotBidded] = useState(false);

  const handleButton = (e, item) => {
    setSelectedRow(item);
    toggle();
  };


  const [error, setError] = useState("");

  const handleCheckBox = (e) => {
    setAKL(!isAKL);
  };

  const handleAddUserSubmit = async (formdata) => {
    if (isAKL) {
      setError("");
      const submitObject = {
        // userId: user?.userId,
        transactionId: parseInt(detail?.id),
        financierId: parseInt(selectedRow?.financierId),
      };
      try {
        const resp = await TransctiesService.setTransactionOfferAccept(
          submitObject
        );
        // toast.success(resp.message, {
        //   position: toast.POSITION.TOP_RIGHT
        // })
        toggle();
        if (detail?.wingmanUserId === user?.userId) {
          accepectedToggle();
        }
        if (detail?.dealerUserId === user?.userId) {
          refresh();
        }
       
      } catch (error) {
        toast.success(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        toggle();
      }
    } else {
      setError("Bevestiging is verplicht"); ///here wee need to add text for accept propal akn popup
    }
  };

  const handleRefresh = async (formdata) => {
    accepectedToggle();
    // window.location.reload();
    refresh();
  };

  useEffect(() => {
    if (detail?.checkLists?.length > 0) {
      setIsTTCEmpty(false);
    } else {
      setIsTTCEmpty(true);
    }
  }, [detail]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    if (rows.length > 0) {
      const countRows = rows.filter(
        (item) => item.fees > 0 || item.proposalPercentage > 0
      );
      if (countRows.length > 0) {
        setProposalPending(false);
        // setProposalSelected(true);
      } else {
        setProposalPending(true);
        // setProposalSelected(false);
      }
    }
  }, [rows]);

  useEffect(() => {
    if (rows.length > 0) {
      const countRows = rows.filter((item) => item.isSelected === true);
      if (countRows.length > 0) {
        setProposalSelected(true);
      } else {
        setProposalSelected(false);
      }
    }
  }, [rows]);

  const isFinancierSelected = (row) => {
    if (row.isSelected === true) {
      return "Geselecteerd";
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (rows.length > 0) {
      const hasDealerSigned = rows.filter(
        (row) => row.isSelectedByDealer === true
      );
      if (hasDealerSigned.length === 1) {
        setIsSignedByDealer(true);
        setSignedRow(hasDealerSigned);
        setDisableTTC(true);
      }
    }
    return;
  }, [rows]);
  const handleDeselectProposal = async () => {
    const rejectProposal = {
      transactionId: detail?.id,
      financierId: signedRow[0]?.financierId,
    };
    const resp = await TransctiesService.rejectTransactionProposal(
      rejectProposal
    );
    setIsSignedByDealer(false);
    setSignedRow([]);
  };
  return (
    <ThemeProvider theme={outerTheme}>
      <Box sx={{ color: "#212F56", width: "100%" }}>
        <Paper
          sx={{
            color: "#212F56",
            width: "100%",
            mb: 2,
            padding: "20px",
            borderRadius: "15px",
          }}
        >
          <EnhancedTableToolbar
            numSelected={selected.length}
            onClickAddTransaction={onClickAddTransaction}
          />
          {rows?.length > 0 && (
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(
                        row.transactionFinanciersId
                      );
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.transactionFinanciersId)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.financierId}
                          style={{
                            backgroundColor:
                              checkForUpdate(row.proposalId) !== null
                                ? checkForUpdate(row.proposalId)
                                : "white",
                          }}
                          // selected={isItemSelected}
                        >
                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            {row.financierName}
                          </TableCell>
                          {row.userName === "" ? (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              -
                            </TableCell>
                          ) : (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              {row.userName.split(" ")[0]}
                            </TableCell>
                          )}

                          {row.userName === "" ? (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              -
                            </TableCell>
                          ) : (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              {(row.surcharge / 100).toLocaleString("nl-NL", {
                                style: "percent",
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                                useGrouping: true,
                              })}
                            </TableCell>
                          )}

                          {row?.showFees === true ? (
                            <>
                              {row.userName === "" ? (
                                <TableCell
                                  align="left"
                                  sx={{ color: "#212F56" }}
                                >
                                  -
                                </TableCell>
                              ) : (
                                <TableCell
                                  align="left"
                                  sx={{ color: "#212F56" }}
                                >
                                  {(row.fee / 100).toLocaleString("nl-NL", {
                                    style: "percent",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                    useGrouping: true,
                                  })}
                                </TableCell>
                              )}
                            </>
                          ) : (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              N/A
                            </TableCell>
                          )}
                          {row.userName === "" ? (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              -
                            </TableCell>
                          ) : (
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              {(row.proposalPercentage / 100).toLocaleString(
                                "nl-NL",
                                {
                                  style: "percent",
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                  useGrouping: true,
                                }
                              )}
                            </TableCell>
                          )}

                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            {(row?.swapRate / 100).toLocaleString("nl-NL", {
                              style: "percent",
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                              useGrouping: true,
                            })}
                          </TableCell>
                          <>
                            {isTTCEmpty === true ? (
                              <>
                                {proposalPending === true ? (
                                  <TableCell
                                    align="left"
                                    sx={{ color: "#212F56" }}
                                  ></TableCell>
                                ) : (
                                  <TableCell align="left">
                                    {proposalSelected === true ? (
                                      <span>
                                        {" "}
                                        {isFinancierSelected(row) ===
                                        "Geselecteerd" ? (
                                          <div
                                            className="  dateicon"
                                            style={{
                                              backgroundColor: "#ff9e0047",
                                              color: "#212F56",
                                            }}
                                          >
                                            Geselecteerd
                                          </div>
                                        ) : (
                                          <span></span>
                                        )}
                                      </span>
                                    ) : (
                                      <p>
                                        {" "}
                                        {row.surcharge !== 0.0 ? (
                                          <p
                                            align="left"
                                            sx={{
                                              color: "#212F56",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {detail?.dealerUserId ===
                                              user?.userId && (
                                              <>
                                                {row.isSelectedByDealer ===
                                                false ? (
                                                  <div
                                                    className="bg-secondary  dateicon"
                                                    sx={{ cursor: "Pointer" }}
                                                    onClick={(e) =>
                                                      handleButton(e, row)
                                                    }
                                                  >
                                                    Toekennen
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="bg-secondary  dateicon"

                                                    // onClick={(e) =>
                                                    //   handleButton(e, row)
                                                    // }
                                                  >
                                                    Annuleren
                                                  </div>
                                                )}
                                              </>
                                            )}
                                            {detail?.wingmanUserId ===
                                              user?.userId && (
                                              <>
                                                {row?.isSelectedByDealer ===
                                                true ? (
                                                  <div
                                                    className="bg-secondary  dateicon"
                                                    onClick={(e) =>
                                                      handleButton(e, row)
                                                    }
                                                  >
                                                    Bevestigen
                                                  </div>
                                                ) : (
                                                  <div className="bg-secondary  dateicon">
                                                    Toekennen
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </p>
                                        ) : (
                                          <span
                                            align="left"
                                            sx={{
                                              color: "#212F56",
                                              // cursor: "pointer",
                                            }}
                                            // onClick={(e) =>
                                            //   handleButton(e, row)
                                            // }
                                          >
                                            <div
                                              className="dateicon font-size-inherit"
                                              style={{
                                                backgroundColor: "#ff9e0047",
                                                color: "#212F56",
                                              }}
                                              // className="fw-light font-size-inherit "
                                            >
                                              Niet Geboden
                                            </div>
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </TableCell>
                                )}
                              </>
                            ) : (
                              <>
                                {proposalPending === true ? (
                                  <TableCell
                                    align="left"
                                    sx={{ color: "#212F56" }}
                                  ></TableCell>
                                ) : (
                                  <TableCell align="left">
                                    {proposalSelected === true ? (
                                      <span>
                                        {" "}
                                        {isFinancierSelected(row) ===
                                        "Geselecteerd" ? (
                                          <div
                                            className="  dateicon"
                                            style={{
                                              backgroundColor: "#ff9e0047",
                                              color: "#212F56",
                                            }}
                                          >
                                            Geselecteerd
                                          </div>
                                        ) : (
                                          <span></span>
                                        )}
                                      </span>
                                    ) : (
                                      <div>
                                        {isTTCPassed ? (
                                          <>
                                            {row.surcharge !== 0.0 ? (
                                              <p
                                                align="left"
                                                sx={{
                                                  color: "#212F56",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                {detail?.dealerUserId ===
                                                user?.userId ? (
                                                  <>
                                                    {isSignedByDealer ===
                                                    false ? (
                                                      <div
                                                        className="bg-secondary  dateicon"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) =>
                                                          handleButton(e, row)
                                                        }
                                                      >
                                                        Toekennen
                                                      </div>
                                                    ) : (
                                                      <>
                                                        {" "}
                                                        {row.isSelectedByDealer ? (
                                                          <div
                                                            className="bg-secondary  dateicon"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={
                                                              handleDeselectProposal
                                                            }
                                                          >
                                                            Annuleren
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className="dateicon font-size-inherit"
                                                            style={{
                                                              backgroundColor:
                                                                "#ff9e0047",
                                                              color: "#212F56",
                                                            }}
                                                          >
                                                            Toekennen
                                                          </div>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    {detail?.wingmanUserId ===
                                                    user?.userId ? (
                                                      <>
                                                        {row?.isSelectedByDealer ===
                                                        true ? (
                                                          <div
                                                            className="bg-secondary  dateicon"
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={(e) =>
                                                              handleButton(
                                                                e,
                                                                row
                                                              )
                                                            }
                                                          >
                                                            Bevestigen
                                                          </div>
                                                        ) : (
                                                          <div
                                                            className="dateicon font-size-inherit"
                                                            style={{
                                                              backgroundColor:
                                                                "#ff9e0047",
                                                              color: "#212F56",
                                                            }}
                                                          >
                                                            Toekennen
                                                          </div>
                                                        )}
                                                      </>
                                                    ) : (
                                                      <div
                                                        className="dateicon font-size-inherit"
                                                        style={{
                                                          backgroundColor:
                                                            "#ff9e0047",
                                                          color: "#212F56",
                                                        }}
                                                      >
                                                        Toekennen
                                                      </div>
                                                    )}
                                                  </>
                                                )}

                                                {/* {detail?.wingmanUserId !==
                                                  user?.userId ||
                                                  (detail?.dealerUserId !==
                                                    user?.userId && (
                                                    <>
                                                      <div className="bg-secondary  dateicon">
                                                        Toekennen
                                                      </div>
                                                    </>
                                                  ))} */}
                                              </p>
                                            ) : (
                                              <span
                                                align="left"
                                                sx={{
                                                  color: "#212F56",
                                                  // cursor: "pointer",
                                                }}
                                                // onClick={(e) =>
                                                //   handleButton(e, row)
                                                // }
                                              >
                                                <div
                                                  className="dateicon font-size-inherit"
                                                  style={{
                                                    backgroundColor:
                                                      "#ff9e0047",
                                                    color: "#212F56",
                                                  }}
                                                  // className="fw-light font-size-inherit "
                                                >
                                                  Niet Geboden
                                                </div>
                                              </span>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {row.surcharge !== 0.0 ? (
                                              <span
                                                align="left"
                                                sx={{
                                                  color: "#212F56",
                                                }}
                                                className="fw-light font-size-inherit"
                                              >
                                                TTC in behandeling
                                              </span>
                                            ) : (
                                              <span
                                                align="left"
                                                style={{
                                                  color: "#212F56",
                                                }}
                                                className="fw-light font-size-inherit "
                                              >
                                                Nog te bieden
                                              </span>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                  </TableCell>
                                )}
                              </>
                            )}
                          </>

                          <TableCell align="center">
                            {row.proposalPercentage > 0 ? (
                              <VisibilityIcon
                                onClick={(e) =>
                                  onClickDetailsransaction(e, row)
                                }
                                style={{ color: "#FF9E00", margin: "10px" }}
                              />
                            ) : (
                              ""
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 20, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rijen per pagina"
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} van ${count !== -1 ? count : `meer dan ${to}`}`
            }
          />
        </Paper>
      </Box>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
        showFooter={false}
      >
        <Row className="welkon-form-popup-main-div">
          <Col sm="10" className="d-flex flex-column justify-content-between">
            {detail?.dealerUserId === user?.userId ? (
              <div className="welkon-form-popup-cont-div">
                {`Je staat op het punt de offerte ${
                  selectedRow?.proposalPercentage
                } % van 
                ${selectedRow?.userName.split(" ")[0]}
               van ${
                 selectedRow?.financierName
               } te accepteren. Om de transactie definitief te maken dient de wingman de acceptatie nog te bevestigen.`}
              </div>
            ) : (
              <div className="welkon-form-popup-cont-div">
                {`Je staat op het punt de acceptatie van de offerte ${
                  selectedRow?.proposalPercentage
                } % van ${selectedRow?.userName.split(" ")[0]} van ${
                  selectedRow?.financierName
                }  te bevestigen.`}
              </div>
            )}

            <Form
              className="welkon-form-popup-main"
              onSubmit={handleSubmit(handleAddUserSubmit)}
            >
              <FormGroup>
                <Row>
                  <Col className="text-center">
                    {/* <Form.Check
                      label="Transactie voldoet aan de randvoorwaarden."
                      id="checkbox-id"
                    /> */}
                    <div className="checkbox checkbox-dark">
                      <Input
                        id="inline-3"
                        type="checkbox"
                        checked={isAKL}
                        onClick={handleCheckBox}
                        name="akl"
                        control={control}
                      />
                      <Label for="inline-3">
                        Transactie voldoet aan de randvoorwaarden.
                      </Label>
                    </div>
                    {error && <DangerText>*{error}</DangerText>}
                  </Col>
                </Row>
              </FormGroup>
              <Row className="step1_header">
                <Col>
                  {isAKL ? (
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "welkon-form-popup-btn-attr ",
                      }}
                    >
                      Accepteren
                    </Btn>
                  ) : (
                    <Btn
                      attrBtn={{
                        color: "secondary",
                        className: "welkon-form-popup-btn-attr ",
                      }}
                    >
                      Accepteren
                    </Btn>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CommonModal>
      <CommonModal
        isOpen={accepectedModal}
        title={NewMessage}
        toggler={accepectedToggle}
        size="xl"
        displayNone={displayNone}
        showFooter={false}
      >
        <Row className="welkon-form-popup-main-div">
          <Col sm="10" className="d-flex flex-column justify-content-between">
            <div className="welkon-form-popup-cont-div">
              <p>
                De Transactie is gegund aan{" "}
                <b>{`${selectedRow?.financierName} (${selectedRow?.userName})`}</b>
              </p>
              <p>
                Vanaf dit moment zijn de bieding opties voor financiers
                gedeactiveerd voor deze Transactie.
              </p>
            </div>
            <Form
              className="welkon-form-popup-main"
              onSubmit={handleSubmit(handleRefresh)}
            >
              <Row className="step1_header">
                <Col>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "welkon-form-popup-btn-attr ",
                    }}
                  >
                    Sluiten
                  </Btn>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CommonModal>
      <CommonModal
        isOpen={historyModal}
        title={historyPopupTitle}
        toggler={historyViewToggle}
        size="xl"
        displayNone={displayNone}
      >
        <BiedingenHistory
          historyId={historyId}
          proposals={proposals}
          financiarList={financiarList}
          isTTCPassed={isTTCPassed}
          detail={detail}
        ></BiedingenHistory>
      </CommonModal>
    </ThemeProvider>
  );
};

export default Biedingen;
