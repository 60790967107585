import React from "react";
import {
  ArrowSvgIcon,
  BagSvgIcon,
  ScreenSvgIcon,
  LightSvgIcon,
  SubmitSvgIcon,
} from "../SvgIcons";

export const DealerBox = [
  {
    id: 1,
    img: require("../../../../assets/images/dashboard-2/1.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 2,
    img: require("../../../../assets/images/dashboard-2/8.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 3,
    img: require("../../../../assets/images/dashboard-2/3.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 4,
    img: require("../../../../assets/images/dashboard-2/4.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 5,
    img: require("../../../../assets/images/dashboard-2/5.png"),
    name: "Brown davis",
    place: "China",
  },
  {
    id: 6,
    img: require("../../../../assets/images/dashboard-2/6.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 7,
    img: require("../../../../assets/images/dashboard-2/7.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 8,
    img: require("../../../../assets/images/dashboard-2/8.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 9,
    img: require("../../../../assets/images/dashboard-2/8.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
  {
    id: 10,
    img: require("../../../../assets/images/dashboard-2/8.png"),
    name: "Lorem ipsum dolor sit ameta",
    place: "Netherlands",
  },
];

export const RecentOrders = [
  {
    id: 1,
    img: require("../../../../assets/images/dashboard/product-1.png"),
    name: "Gemeente Utrecht",
    date: "20-05-2022",
    quantity: "54146",
    value: require("../../../../assets/images/dashboard/graph-1.png"),
    rate: "€ 20.000.000",
    status: "Annuïtair",
  },
  {
    id: 2,
    img: require("../../../../assets/images/dashboard/product-1.png"),
    name: "Vivium Zorggroep",
    date: "20-05-2022",
    quantity: "54146",
    value: require("../../../../assets/images/dashboard/graph-1.png"),
    rate: "€ 20.000.000",
    status: "Annuïtair",
  },
  {
    id: 3,
    img: require("../../../../assets/images/dashboard/product-1.png"),
    name: "Provincie Utrecht",
    date: "20-05-2022",
    quantity: "54146",
    value: require("../../../../assets/images/dashboard/graph-1.png"),
    rate: "€ 20.000.000",
    status: "Annuïtair",
  },
];

export const LatestActivity = [
  {
    id: 1,
    icon: <ArrowSvgIcon />,
    name: "Financiering naam X",
    subtitle: "1 Juni 2022 - 10.00 uur",
  },
  {
    id: 2,
    icon: <BagSvgIcon />,
    name: "Financiering naam X",
    subtitle: "1 Juni 2022 - 10.00 uur",
  },
  {
    id: 3,
    icon: <LightSvgIcon />,
    name: "Financiering naam X",
    subtitle: "1 Juni 2022 - 10.00 uur",
  },
  {
    id: 4,
    icon: <ScreenSvgIcon />,
    name: "Financiering naam X",
    subtitle: "1 Juni 2022 - 10.00 uur",
  },
  {
    id: 5,
    icon: <SubmitSvgIcon />,
    name: "Financiering naam X",
    subtitle: "1 Juni 2022 - 10.00 uur",
  },
];
