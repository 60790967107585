import { H5, H6 } from "../../../AbstractElements";
import React from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

const InfoCard = ({ detail }) => {
  return (
    <Card>
      <CardHeader className="pb-0">
        <div className="header-top d-sm-flex justify-content-between align-items-center">
          <H5>Modaliteiten</H5>
        </div>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={6}>
            <div className="mb-4">
              <H6>Geldnemer</H6>
              <p>{detail?.customer?.company?.name}</p>
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-4">
              <H6>Transactie</H6>
              <p>{detail?.identifier}</p>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-4">
              <H6>Dealer</H6>
              <p>{detail?.dealerValue}</p>
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-4">
              <H6>Wingman</H6>
              <p>{detail?.wingmanValue}</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InfoCard;
