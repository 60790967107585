import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  CardHeader,
  Label,
  Input,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";
import axios from "axios";
import { TaskApi } from "../../../api";
import { TaskService } from "utility";
import FinancialTransactiesChecklistComponentEdit from "./FinancialTransactiesChecklistComponentEdit";

const FinancialTransactieChecklistEdit = (financialTranChecklistDataSet) => {
  const {
    financialTranChecklistSel,
    setFinancialTranChecklistSel,
    transection,
    transactionTypeValue,
  } = financialTranChecklistDataSet;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [displayNone] = useState("d-none");
  const ModalTitle = "Selecteer Financial Transactie Checklist(TTC)";
  const [tranCheckList, setTranCheckList] = useState([]);

  const getTask = async () => {
    try {
      const resp = await TaskService.getTasksList(
        transection.customerId,
        transection.transactionType
      );
      let selectedData = [];
      const newStateData = resp.response.map((item) => {
        selectedData.push(item.id);
        return { ...item, checked: true };
      });
      setTranCheckList(newStateData);
      setFinancialTranChecklistSel(selectedData);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get data once
  useEffect(() => {
    getTask();
  }, [transection]);

  const onItemChecked = (item) => {
    const newTranCheckList = tranCheckList.map((obj) => {
      if (item.taskId === obj.taskId) {
        return { ...obj, checked: item.checked ? false : true };
      }
      return obj;
    });
    setTranCheckList(newTranCheckList);
    setFinancialTranChecklistSel(
      newTranCheckList
        .filter((item) => {
          if (item.checked) return true;
        })
        .map((item) => {
          return item.taskId;
        })
    );
  };

  return (
    <Fragment>
      <Card className="px-5">
        {/* <CardHeader className="pb-0">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="color-secondary font-w800">Financial Transactie Checklist(TTC)</h5>
            <Btn attrBtn={{ color: "primary", onClick: toggle }}>Toevoegen</Btn>
          </div>

        </CardHeader> */}
        <CardBody>
          <div className="col-sm-12">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Task</th>
                  </tr>
                </thead>
                <tbody>
                  {financialTranChecklistSel?.length > 0 ? (
                    tranCheckList
                      .filter((_item) => {
                        if (_item.checked) return true;
                      })
                      .map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item.title}
                            {/* <Label for="inline-1">{item.taskName}</Label> */}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td>No data found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {/* </div> */}
          </div>
        </CardBody>
      </Card>
      <CommonModal
        isOpen={modal}
        title={ModalTitle}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
      >
        <FinancialTransactiesChecklistComponentEdit
          data={tranCheckList}
          setFinancialTranChecklistSel={setFinancialTranChecklistSel}
          financialTranChecklistSel={financialTranChecklistSel}
          setTranCheckList={setTranCheckList}
        />
      </CommonModal>
    </Fragment>
  );
};

export default FinancialTransactieChecklistEdit;
