import React, { Fragment, useState, useEffect } from "react";
// import { Col, Card, Button, Table, Label, Input } from "reactstrap";
import {
  Container,
  Row,
  Col,
  Label,
  Table,
  Form,
  FormGroup,
  Input,
  Card,
} from "reactstrap";
import { Btn } from "../../../AbstractElements";
import CommonModal from "../../UiKits/Modals/common/modal";

const Gebruikers = () => {
  const [modal, setModal] = useState(false);
  const [displayNone] = useState("d-none");
  const ModalTitle = "Gegevens gebruiker aanpassen";

  const toggle = () => setModal(!modal);

  return (
    <Fragment>
      <Col sm="12">
        <h4 className="font-w800">Gebruikers</h4>
        <Card className="p-3" style={{ color: "#212F56", height: "700px" }}>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th scope="col">
                    <div className="checkbox checkbox-dark">
                      <Input id="inline-1" type="checkbox" defaultChecked />
                      <Label for="inline-1">{Option}</Label>
                    </div>
                  </th>
                  <th scope="col">Gebruiker ID</th>
                  <th scope="col">Actief sinds</th>
                  <th scope="col">Naam</th>
                  <th scope="col">Rol</th>
                  <th scope="col">Laatste keer ingelogd</th>
                  <th scope="col">status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="checkbox checkbox-dark">
                      <Input id="inline-1" type="checkbox" defaultChecked />
                      <Label for="inline-1">{Option}</Label>
                    </div>
                  </td>
                  <td>#0001</td>
                  <td>20 juni 2022</td>
                  <td>Stephan van der Willik</td>
                  <td>FO</td>
                  <td>
                    <p className="text-secondary">20 juni 2022</p>
                  </td>
                  <td>
                    <Btn
                      attrBtn={{ color: "primary", onClick: () => toggle() }}
                    >
                      <i class="fa fa-check"></i>
                      Actief
                    </Btn>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="checkbox checkbox-dark">
                      <Input id="inline-1" type="checkbox" defaultChecked />
                      <Label for="inline-1">{Option}</Label>
                    </div>
                  </td>
                  <td>#0002</td>
                  <td>20 juni 2022</td>
                  <td>Michel Hensen</td>
                  <td>FOA</td>
                  <td>
                    <p className="text-secondary">20 juni 2022</p>
                  </td>
                  <td>
                    <Btn
                      attrBtn={{ color: "primary", onClick: () => toggle() }}
                    >
                      <i class="fa fa-check"></i>
                      Actief
                    </Btn>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="checkbox checkbox-dark">
                      <Input id="inline-1" type="checkbox" defaultChecked />
                      <Label for="inline-1">{Option}</Label>
                    </div>
                  </td>
                  <td>#0003</td>
                  <td>20 juni 2022</td>
                  <td>Marko Versteeg</td>
                  <td>Financier</td>
                  <td>
                    <p className="text-secondary">20 juni 2022</p>
                  </td>
                  <td>
                    <Btn
                      attrBtn={{ color: "primary", onClick: () => toggle() }}
                    >
                      <i class="fa fa-check"></i>
                      Actief
                    </Btn>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <nav
            aria-label="Page navigation example"
            style={{ marginTop: "200px" }}
          >
            <ul
              className="pagination justify-content-center"
              style={{ gap: "1rem" }}
            >
              <li className="page-item" style={{ borderRadius: "30%" }}>
                <a className="page-link active" href="#">
                  1
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
            </ul>
          </nav>
        </Card>
      </Col>
      <CommonModal
        isOpen={modal}
        title={ModalTitle}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
        showFooter={false}
      >
        <Row className="welkon-form-popup-main-div">
          <Col sm="12" className="d-flex flex-column justify-content-between">
            <Form className="welkon-form-popup-main">
              <FormGroup>
                <Row className="p-b-30">
                  <Col>
                    <Label className="col-form-label" for="recipient-name">
                      {"Voornaam"}
                    </Label>
                    <Input className="form-control" type="text" />
                  </Col>
                  <Col>
                    <Label className="col-form-label" for="recipient-name">
                      {"Tussenvoegsel"}
                    </Label>
                    <Input className="form-control" type="text" />
                  </Col>
                  <Col>
                    <Label className="col-form-label" for="recipient-name">
                      {"Achternaam "}
                    </Label>
                    <Input className="form-control" type="text" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="col-form-label" for="recipient-name">
                      {"Bedrijf"}
                    </Label>
                    <Input className="form-control" type="text" />
                  </Col>
                  <Col>
                    <Label className="col-form-label" for="recipient-name">
                      {"Rol en rechten"}
                    </Label>
                    <Input className="form-control" type="text" />
                  </Col>
                  <Col>
                    <Label className="col-form-label" for="recipient-name">
                      {"Status"}
                    </Label>
                    <Input className="form-control" type="text" />
                  </Col>
                </Row>
              </FormGroup>
              <Row className="welkon-form-popup-cont"></Row>
              <Row className=" welkon-form-popup-cont-div p-t-20">
                <Col className="text-right">
                  <Btn
                    attrBtn={{
                      color: "primary",
                      claGeriessName: "welkon-form-popup-btn-attr ",
                    }}
                  >
                    Gegevens
                  </Btn>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CommonModal>
    </Fragment>
  );
};

export default Gebruikers;
