import { H5, H6 } from "../../../AbstractElements";
import React from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { Date } from "Constant";
import moment from "moment";
// function time(date) {
//   if (date) {
//     let tDate = date.replace("T", " ").split(":");
//     return tDate[0] + ":" + tDate[1];
//   }
//   return date;
// }
function time(date) {
  if (date) {
    let momentObj = moment(date);
    momentObj.locale("nl");
    let netherlandsTime = momentObj.format("DD-MM-YYYY HH:mm");
    return netherlandsTime + " uur";
  }
}

const Tijdschema = ({ detail }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <div className="mb-4">
              <H6>
                <center>Tijdschema biedingsperiode</center>
              </H6>
              {/* <p>{detail?.transactionFrom}</p> */}
            </div>
            <div>
              <center>{time(detail?.transactionStartDateTime)}</center>
            </div>
            {/* <div>
              <center>Einde: {time(detail?.transactionEndDateTime)}</center>
            </div> */}
            {/* <div>
                            <center>End Time : {detail?.endTimeSlot}</center>
                        </div> */}
          </Col>
          {/* <Col md={6}>
            <div className="mb-4">
              <H6>Dealer(D)</H6>
              <p>{detail?.foUserName}</p>
            </div>
          </Col> */}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Tijdschema;
