import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import styled from "styled-components";

const ErrorText = styled.p`
  color: #000000 !important;
  margin-bottom: 0px !important;
`;

export const FormTextField = ({
  label,
  control,
  name,
  defaultValue,
  placeholder,
  errors,
  required,
  value,
  type,
  rows,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <FormGroup>
          <Label className="col-form-label">{label}</Label>
          <Input
            className="form-control"
            required={required}
            type={type}
            value={value ? value : props.field.value}
            placeholder={placeholder || label}
            rows={rows}
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
          />
          {errors?.message && <ErrorText>*{errors?.message}</ErrorText>}
        </FormGroup>
      )}
    />
  );
};

export const FormNumberField = ({
  label,
  min,
  step = "any",
  max,
  control,
  name,
  defaultValue,
  placeholder,
  errors,
  required,
  value,
  type,
  rows,
  onChange,

  register,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      // onChange={onChange}
      render={(props) => (
        <FormGroup>
          <Label className="col-form-label">{label}</Label>
          <Input
            className="form-control"
            required={required}
            type={type}
            min={min}
            step={step}
            max={max}
            value={value ? value : props.field.value}
            placeholder={placeholder || label}
            rows={rows}
            onChange={(e) => {
              // props.field.onChange(e.target.value);
              onChange(e.target.value);
            }}
          />
          {errors?.message && <ErrorText>*{errors?.message}</ErrorText>}
        </FormGroup>
      )}
    />
  );
};

export const FormPasswordField = ({
  label,
  control,
  name,
  defaultValue,
  placeholder,
  errors,
  required,
}) => {
  const [togglePassword, setTogglePassword] = useState(false);
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <FormGroup className="position-relative">
          <Label className="col-form-label">{label}</Label>
          <Input
            className="form-control"
            type={togglePassword ? "text" : "password"}
            onChange={(e) => props.field.onChange(e.target.value)}
            required={required}
            value={props.field.value}
            placeholder={placeholder || label}
          />
          <div
            className="show-hide"
            onClick={() => setTogglePassword(!togglePassword)}
          >
            <span className={togglePassword ? "" : "show"}></span>
          </div>
          {errors?.message && <ErrorText>*{errors?.message}</ErrorText>}
        </FormGroup>
      )}
    />
  );
};

export const FormSelectField = ({
  label,
  control,
  name,
  defaultValue,
  placeholder,
  errors,
  required,
  options,
  value,
  disabled,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <FormGroup>
          <Label className="col-form-label">{label}</Label>
          <select
            name="select"
            disabled={disabled ? true : false}
            value={value ? value : props.field.value}
            className="form-control form-control-primary btn-square form-select"
            onChange={(e) => {
              props.field.onChange(e.target.value);
            }}
          >
            {options.map((e, key) => {
              return (
                <option key={key} value={e.value}>
                  {" "}
                  {e.name}
                </option>
              );
            })}
          </select>
          {errors?.message && <ErrorText>*{errors?.message}</ErrorText>}
        </FormGroup>
      )}
    />
  );
};
