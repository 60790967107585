import React from "react";
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "../Layout/Loader";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import FirstStap from "../Auth/Tabs/LoginTab/FirstStep";
import Verification from "../Auth/Tabs/LoginTab/Verification";
import { AuthProvider } from "../context/user";
const Routers = () => {
  return (

    <BrowserRouter basename={"/"}>
      <AuthProvider>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path={`/*`} element={<LayoutRoutes />} />
            <Route
              exact
              path={`FirstStap`}
              element={<FirstStap />}
            />
            <Route
              exact
              path={`verification`}
              element={<Verification />}
            />
            <Route
              exact
              path={`/`}
              element={<Signin />}
            />
            <Route
              exact
              path={`login`}
              element={<Signin />}
            />
          </Routes>
        </Suspense>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routers;
