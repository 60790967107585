import React, { Fragment, useState, useEffect } from "react";
import { H4, Btn } from "../../../AbstractElements";
import { TransctiesService } from "utility/services/transcties";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import "./Knob.css";
import { TransactionMail } from "../Transacties/TransactionMail";

const CardDataSingle = ({ transaction }) => {
  const [modal, setModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [swapRateData, setSwapRateData] = useState();
  const [swapRateValueData, setSwapRateValueData] = useState();
  const NewMessage = "Transctie toekennen";
  const [displayNone] = useState("d-none");
  const [showSwaprente, setShowSwaprente] = useState(false);
  // console.log(transaction);
  // useEffect(() => {
  //   TransctiesService?.getSwapRate()
  //     .then((data) => {
  //       setSwapRateData(data?.response);
  //     })
  //     .catch((Error) => {
  //       console.log(Error.error);
  //     });
  // }, [refresh]);

  // const getSwaprate = () => {
  //   const num = swapRateData?.swaprente * 100;
  //   return num.toFixed(3);
  // };
  useEffect(() => {
    if (transaction?.detail) {
      TransctiesService?.getSwapRateValue(transaction?.detail?.identifier)
        .then((data) => {
          setSwapRateValueData(data?.swaprente * 100);
        })
        .catch((Error) => {
          console.log(Error.error);
        });
    }
  }, [transaction]);
  // const getSwaprateValue = () => {
  //   const num = swapRateValueData?.swaprente * 100;
  //   return num.toFixed(3);
  // };

  const updatedSwapRate = () => {
    setRefresh((prev) => prev + 1);
  };

  const checkSwaprente = () => {
    if (
      transaction?.detail?.transactionSubTypeValue === "Annuïtair" ||
      transaction?.detail?.transactionSubTypeValue === "Fixe" ||
      transaction?.detail?.transactionSubTypeValue === "Lineair"
    ) {
      return true;
    }
    return false;
  };

  function dateComparater(date2) {
    const currentDate = new Date();
    const givenDate = new Date(date2);
    const currentMillis = currentDate.getTime();
    const givenMillis = givenDate.getTime();
    if (currentMillis > givenMillis) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Fragment>
      <Row>
        <Col sm="12" xl="12" lg="12">
          <Card className="o-hidden border-0 min-h-100">
            <CardBody className={"bg-secondary"}>
              {checkSwaprente() === true && (
                <div className="media-body text-center">
                  <h4 className="m-0">Swaprente</h4>
                  <>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ margin: "20px" }}>
                        <h5>Huidige Swaprente</h5>
                        <H4>
                          {(swapRateValueData / 100).toLocaleString("nl-NL", {
                            style: "percent",
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                            useGrouping: true,
                          })}
                        </H4>
                      </div>

                      <>
                        {transaction?.detail?.swapRate === 0.0 ||
                        dateComparater(
                          transaction?.detail?.transactionStartDateTime
                        ) === false ? (
                          <div style={{ margin: "20px" }}>
                            <h5>Transactiedatum Swaprente</h5>
                            <h3>--</h3>
                          </div>
                        ) : (
                          <div style={{ margin: "20px" }}>
                            <h5>Transactiedatum Swaprente</h5>
                            <h4>
                              {(
                                transaction?.detail?.swapRate / 100
                              ).toLocaleString("nl-NL", {
                                style: "percent",
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                                useGrouping: true,
                              })}
                            </h4>
                          </div>
                        )}
                      </>
                    </div>
                  </>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        size="xl"
        displayNone={displayNone}
        showFooter={false}
      >
        <Row className="welkon-form-popup-main-div">
          <Col sm="10" className="d-flex flex-column justify-content-between">
            <div className="welkon-form-popup-cont-div">
              Het 4-ogen-principe is bedoeld om menselijke fouten tot een
              minimum te beperken door te eisen dat twee personen onafhankelijk
              van elkaar een actie verifiëren en bevestigen voordat deze wordt
              uitgevoerd.
            </div>
            <Form className="welkon-form-popup-main">
              <FormGroup>
                <Row>
                  <Col className="text-center">
                    {/* <Form.Check
                      label="Transactie voldoet aan de randvoorwaarden."
                      id="checkbox-id"
                    /> */}
                    <div className="checkbox checkbox-dark">
                      <Input id="inline-3" type="checkbox" />
                      <Label for="inline-3">
                        Transactie voldoet aan de randvoorwaarden.
                      </Label>
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              <Row className="step1_header">
                <Col>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "welkon-form-popup-btn-attr ",
                    }}
                  >
                    Accepteren
                  </Btn>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CommonModal>
    </Fragment>
  );
};

export default CardDataSingle;
