import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  InputGroup,
  Row,
} from "reactstrap";

const DatumenTijdstipFormEdit = (transferedData) => {
  const { transection, setTransection, errorState } = transferedData;
  const [startDate, setStartDate] = useState("");

  const handleValidationCustom01 = (e) => {
    setTransection({ ...transection, endDate: e.target.value });
  };
  const currentDate = new Date();
  function convertToISO(date) {
    const offset = 1;
    date.setUTCHours(date.getUTCHours() + offset);
    return date.toISOString().slice(0, 16);
  }
  useEffect(() => {
    if (currentDate) {
      const newDate = convertToISO(currentDate);
      setStartDate(newDate);
    }
  });

  // const handleValidationCustom02 = (e) => {
  //   setTransection({
  //     ...transection,
  //     transactionStartDateTime: e.target.value,
  //   });
  //   console.log(e.target.value);
  // };

  const handleValidationCustom03 = (e) => {
    setTransection({
      ...transection,
      transactionEndDateTime: e.target.value,
      transactionStartDateTime: startDate,
    });
  };

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">
            Datum en tijdstip transactievoorstel
          </h5>
        </CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate="">
            <Row className="g-3">
              {/* <Col md="4">
                <Label className="form-label" for="validationCustom01">
                  Datum
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="date"
                  name="Datum"
                  onChange={handleValidationCustom01}
                />
              </Col> */}
              <Col md="6" className="d-none">
                <Label htmlFor="validationCustomUsername">
                  Start tijidslot
                </Label>

                <InputGroup>
                  {/* <input
                    className="form-control"
                    name="starttimeslot"
                    type="datetime-local"
                    htmlFor="transactionStartDateTime"
                    value={startDate}
                    disabled
                    onChange={handleValidationCustom02}
                  /> */}
                  <p>{startDate}</p>
                </InputGroup>
                {errorState.includes("transactionStartDateTime") && (
                  <div>*Start tijdslot is vereist</div>
                )}
              </Col>
              <Col md="6 mb-3">
                <Label htmlFor="validationCustomUsername">Einde tijdslot</Label>
                <InputGroup>
                  <input
                    className="form-control"
                    name="userName"
                    type="datetime-local"
                    htmlFor="transactionEndDateTime"
                    onChange={handleValidationCustom03}
                  />
                </InputGroup>
                {errorState.includes("transactionEndDateTime") && (
                  <div>*Einde tijdslot is verplicht</div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DatumenTijdstipFormEdit;
