import React, { Fragment, useEffect, useState } from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  InputGroup,
  Row,
} from "reactstrap";

const DatumenTijdstipForm = (transferedData) => {
  const { transection, setTransection, errorState } = transferedData;
  const [endDate, setEndDate] = useState("");
  const [minDate, setMinDate] = useState("");

  // const handleValidationCustom01 = (e) => {
  //   setTransection({ ...transection, endDate: e.target.value });
  // };
  const currentDate = new Date();

  const nextYearDate = new Date(
    currentDate.getFullYear() + 1,
    currentDate.getMonth(),
    currentDate.getDate()
  );
  function convertToISO(nextYearDate) {
    const offset = 1;
    nextYearDate.setUTCHours(nextYearDate.getUTCHours() + offset);
    return nextYearDate.toISOString().slice(0, 16);
  }
  useEffect(() => {
    if (nextYearDate) {
      const formattedEndDate = convertToISO(nextYearDate);
      const formattedMinDate = convertToISO(currentDate);
      setEndDate(formattedEndDate);
      setMinDate(formattedMinDate);
    }
  }, []);

  const handleValidationCustom02 = (e) => {
    setTransection({
      ...transection,
      transactionStartDateTime: e.target.value,
      transactionEndDateTime: endDate,
    });
  };

  // const handleValidationCustom03 = (e) => {
  //   setTransection({
  //     ...transection,
  //     transactionEndDateTime: e.target.value,
  //     transactionStartDateTime: startDate,
  //   });
  // };

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">
            Datum en tijdstip transactievoorstel
          </h5>
        </CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate="">
            <Row className="g-3">
              {/* <Col md="4">
                <Label className="form-label" for="validationCustom01">
                  Datum
                </Label>
                <input
                  className="form-control"
                  id="validationCustom01"
                  type="date"
                  name="Datum"
                  onChange={handleValidationCustom01}
                />
              </Col> */}
              <Col md="6">
                <Label htmlFor="validationCustomUsername">
                  Transactiedatum & tijd
                </Label>

                <InputGroup>
                  <input
                    className="form-control"
                    name="starttimeslot"
                    type="datetime-local"
                    htmlFor="transactionStartDateTime"
                    min={minDate}
                    // value={startDate}
                    // disabled
                    onChange={handleValidationCustom02}
                  />
                  {/* <p>{startDate}</p> */}
                </InputGroup>
                {errorState.includes("transactionStartDateTime") && (
                  <div>*Transactiedatum & tijd is vereist</div>
                )}
              </Col>
              {/* <Col md="6 mb-3" className="d-none">
                <Label htmlFor="validationCustomUsername">Einde tijdslot</Label>
                <InputGroup>
                  <input
                    className="form-control"
                    name="userName"
                    type="datetime-local"
                    htmlFor="transactionEndDateTime"
                    value={endDate}
                    disabled
                    onChange={handleValidationCustom03}
                  />
                </InputGroup>
                {errorState.includes("transactionEndDateTime") && (
                  <div>
                    *Einde tijdslot is verplicht/Voer de tijd handmatig in
                  </div>
                )}
              </Col> */}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default DatumenTijdstipForm;
