import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from 'utility/constant'

class TransactiesVoltooid {

    getTransactionList(reqData) {
        return BaseService.get(`${APIPath.TransactiesVoltooid}`)
    }

}

const TransactiesVoltooidService = new TransactiesVoltooid()
Object.freeze(TransactiesVoltooidService)
export { TransactiesVoltooidService }
