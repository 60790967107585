import React, { Fragment, useState } from "react";
import { MarketChart } from "../../Common/Data/ApexChart";
import Charts from "react-apexcharts";
import { H4, Btn } from "../../../AbstractElements";
import BubbleChartClass from "../../Charts/ApexCharts/BubbleChart";
import ConfigDB from "../../../Config/ThemeConfig";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import "./Knob.css";
import { toggleBlockquote } from "easymde";

const CardGraphSingle = () => {
  const [modal, setModal] = useState(false);
  const NewMessage = "Opslagcurve";
  const [displayNone] = useState("d-none");
  const toggle = () => setModal(!modal);


  return (
    <Fragment>
      <Row>
        <Col sm="12" xl="12" lg="12">
          <Card className="o-hidden border-0">
            <CardBody className={"bg-primary"}>
              <div className="media-body text-center">
                <Btn
                  attrBtn={{
                    color: "primary",
                    className: "text-white ",
                    onClick: () => toggle()
                  }}
                >
                  Opslagcurve
                </Btn>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CommonModal
        isOpen={modal}
        title={NewMessage}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
      >
        <Row className="welkon-form-popup-main-div">
          <Col xl="12" className="box-col-12 des-xl-100">
            {/* <UserCard /> */}
            <BubbleChartClass title="Opslagcurve" />
          </Col>
        </Row>
      </CommonModal>
    </Fragment>
  );
};

export default CardGraphSingle;
