import React, { Fragment } from "react";
import { Card } from "reactstrap";
import { Btn, LI } from "../../../AbstractElements";
import { firebase_app } from "../../../Config/Config";
import { LogOut } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "context/user";

const LogoutClass = () => {
  const navigate = useNavigate();
  const { logout } = useAuth()
  const logoutService = () => {
    firebase_app.auth().signOut();
    logout()
    navigate('/')
  };

  return (
    <Fragment>
      <LI className="onhover-dropdown p-0" >
        <Btn
          attrBtn={{
            as: Card.Header,
            className: "btn btn-primary-light",
            color: "default",
          }}
        >
          <div onClick={logoutService}>
            <LogOut />
            Uitloggen
          </div>
        </Btn>
      </LI>
    </Fragment>
  );
};

export default LogoutClass;
