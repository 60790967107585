import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { visuallyHidden } from "@mui/utils";

function createData(
  transactionId,
  transactionFrom,
  repaymentType,
  principalAmount,
  proposalDate,
  companyName,
  identifier
) {
  return {
    transactionId,
    transactionFrom,
    repaymentType,
    principalAmount,
    proposalDate,
    companyName,
    identifier,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "transactionFrom",
    numeric: false,
    disablePadding: false,
    label: "Klant",
  },
  {
    id: "proposalDate",
    numeric: false,
    disablePadding: false,
    label: "Transactiedatum", //Changed from Startdatum and Further changed form Vereffeningsdatum
  },
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Financier",
  },
  {
    id: "repaymentType",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  // {
  //   id: "principalAmounta",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Opslag",
  // },
  {
    id: "principalAmount",
    numeric: false,
    disablePadding: false,
    label: "Bedrag",
  },
  // {
  //   id: "looptijd",
  //   numeric: false,
  //   label: "Looptijd",
  // },
  {
    id: "view",
    numeric: false,
    label: "Opties",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({theme}) => ({
//   '&:Mui-selected': {
//     backgroundColor: "#FF9E00",
//   }
// }));

function EnhancedTableHead(props) {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}{" "}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ color: "#212F56", flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Recent voltooide transacties
        {/* Previous text => Recente voltooide transacties */}
      </Typography>

      {/* <IconButton>
        <Link to="/RecenteVoltooideTranscties">
          <h6 style={{ color: "#FF9E00" }}> Bekijk alles</h6>
        </Link>
      </IconButton> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const RecenteVoltooideTransacties = (props) => {
  const { recentcompleted } = props;
  const rows = recentcompleted.map((item) => {
    return createData(
      item.transactionId,
      item.customerName,
      item.repaymentType,
      item.principalAmount,
      item.proposalDate,
      item.companyName,
      item.identifier
    );
  });

  const navigate = useNavigate();

  const onClickAddTransaction = () => {
    navigate(`/transacties/add`);
  };

  const onClickDetailsransaction = (e, row) => {
    navigate(`/transacties/dashboard/` + row.identifier);
  };

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("transactionFrom");
  const [selected, setSelected] = useState([]);
  const [page] = useState(0);
  const [dense] = useState(true);
  const [rowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.transactionId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, transactionId) => {
    const selectedIndex = selected.indexOf(transactionId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, transactionId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (transactionId) => selected.indexOf(transactionId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const principalAmountConverter = (number) => {
    const convertedNumber = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(number);
    return convertedNumber.slice(0, -1);
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ color: "#212F56", width: "100%", mb: 2, padding: "20px" }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            onClickAddTransaction={onClickAddTransaction}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                {rows.length !== 0 ? (
                  stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.transactionId);

                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.transactionId)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.transactionId}
                          // selected={isItemSelected}
                        >
                          <TableCell align="left">
                            {row.transactionFrom}
                          </TableCell>
                          <TableCell align="left">{row.proposalDate}</TableCell>
                          <TableCell align="left">{row.companyName}</TableCell>
                          <TableCell align="left">
                            {row.repaymentType}
                          </TableCell>
                          {/* <TableCell align="left"></TableCell> */}
                          <TableCell align="left">
                            {
                              row &&
                                "€ " +
                                  principalAmountConverter(row.principalAmount)
                              // "€ " + row?.principalAmount
                              // new Intl.NumberFormat(
                              //   "de-DE",
                              //   { style: "currency", currency: "EUR" }.format(
                              //     row.principalAmount
                              //   )
                              // )
                            }
                          </TableCell>
                          {/* <TableCell align="left"></TableCell> */}
                          <TableCell allgn="center">
                            <VisibilityIcon
                              onClick={(e) => onClickDetailsransaction(e, row)}
                              style={{ color: "#FF9E00", margin: "10px" }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      padding: 3,
                      alignItems: "center",
                    }}
                  >
                    <h5>Geen data</h5>
                  </div>
                )}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default RecenteVoltooideTransacties;
