import React, { Fragment, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { AuthService } from "utility";
import { Btn, H4, P } from "../../../AbstractElements";
import {
  DangerButton,
  EmailAddress,
  Password,
  RememberPassword,
  SignIn,
} from "../../../Constant";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "context/user";
import { FormPasswordField, FormTextField } from "CommonElements/FormElements";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const DangerText = styled.div`
  color: #ff0000cc !important;
  margin-top: -15px;
  margin-bottom: 13px;
  font-size: 15px;
`;

const LoginSchema = yup.object().shape({
  email: yup.string().required("E-mail is vereist").email("Ongeldig e-mail"),
  password: yup.string().required("Een wachtwoord is verplicht"),
});

const LoginTab = ({}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const authContext = useAuth();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const submit = async (formdata) => {
    try {
      const data = new FormData();
      data.append("Email", formdata.email);
      data.append("Password", formdata.password);
      setLoading(true);
      const response = await AuthService.login(data);

      authContext.login(response.response);
      navigate("/verification");
    } catch (error) {
      setError("De logingegevens zijn niet correct. Probeer het opnieuw.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Form className="theme-form" noValidate onSubmit={handleSubmit(submit)}>
        <H4>Inloggen</H4>
        <P>{"Vul je logingegevens in."}</P>
        <FormTextField
          control={control}
          name="email"
          required={true}
          label={EmailAddress}
          errors={errors?.email}
        />
        <FormPasswordField
          control={control}
          name="password"
          required={true}
          label={Password}
          errors={errors?.password}
        />
        <div className="form-group mb-0">
          <div className="checkbox mb-3">
            {/* <Input id="checkbox1" type="checkbox" />
            <Label className="text-muted" for="checkbox1">
              {RememberPassword}
            </Label> */}
          </div>
          {error && <DangerText>*{error}</DangerText>}
          <Btn>{loading ? "LADEN..." : SignIn}</Btn>
        </div>
      </Form>
    </Fragment>
  );
};

export default LoginTab;
