import ChartjsContext from "_helper/Chartjs";
import configDB from "../../../Config/ThemeConfig";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") ||
  configDB.data.color.secondary_color;


const generateDataForAnuutair = () => {
  var series = [];
  series.push([0, 1, 5])
  series.push([5, 2, 5])
  series.push([10, 3, 5])
  series.push([15, 5, 5])
  series.push([20, 7, 5])
  series.push([25, 8, 5])
  series.push([30, 9, 5])
  series.push([35, 10, 5])
  return series;
};

const generateDataForLineair = () => {
  var series = [];
  series.push([0, 1, 5])
  series.push([5, 2, 5])
  series.push([7, 3, 5])
  series.push([9, 5, 5])
  series.push([15, 7, 5])
  series.push([20, 8, 5])
  series.push([25, 9, 5])
  series.push([30, 10, 5])
  return series;
};

export const apex3DbubbleCharts = {
  series: [
    {
      name: "Annuïtair",
      data: generateDataForAnuutair(),
    },
    {
      name: "Lineair",
      data: generateDataForLineair(),
    },
    /*{
      name: "Product3",
      data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
        min: 10,
        max: 60,
      }),
    },
    {
      name: "Product4",
      data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
        min: 10,
        max: 60,
      }),
    },
    */
  ],
  options: {
    chart: {
      height: 350,
      type: "bubble",
      toolbar: {
        show: true,
      },
      events: {
        dataPointSelection: function (e, chart, opt) {
          const tooltipEl = opt.w.globals.tooltip.getElTooltip();

          if (opt.selectedDataPoints[0].length) {
            tooltipEl.classList.add("apexcharts-tooltip-active");
          } else {
            tooltipEl.classList.remove("apexcharts-tooltip-active");
          }
        }
      },
    },

    tooltip: {
      shared: false,
      intersect: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        const getCategoryName = (seriesIndex) => {
          return apex3DbubbleCharts.series[seriesIndex].name;
        }

        return (
          '<div class="custom-tooltip">' +
          "<span>" +
          w.config.xaxis.categories[dataPointIndex] +
          ": " +
          series[seriesIndex][dataPointIndex] +
          "  <br /> " +
          getCategoryName(seriesIndex) +
          "  <br /> " +
          "</span>" +
          "</div>"
        );
      }
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "solid",
    },
    title: {
      text: "",
    },
    xaxis: {
      tickAmount: 12,
      type: "int",
      min: 2000,
      max: 2024,
      labels: {
        rotate: 0,
      },
    },
    colors: [primary, secondary, "#51bb25", "#544fff"],
    yaxis: {
      max: 15,
    },
    theme: {
      palette: "palette",
    },
  },
};



