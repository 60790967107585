import { useAuth } from "context/user";
import React, { useEffect, useState } from "react";
import { H4, Btn } from "../../../AbstractElements";
import { TransctiesService, UserRoles } from "utility";
import { toast } from "react-toastify";
import { Button } from "reactstrap";

const TimerComponent = ({ detail, completeTransactionToggler, fetchData }) => {
  const [time, setTime] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [reset, setReset] = useState(false);
  const { user } = useAuth();

  function convertSeconds(seconds) {
    let d, h, m, s;
    s = Math.floor(seconds % 60);
    m = Math.floor(((seconds - s) / 60) % 60);
    h = Math.floor(((seconds - m * 60 - s) / 3000) % 24);
    d = Math.floor((seconds - h * 3600 - m * 60 - s) / 86400);
    if (s < 0) {
      fetchData();
    }
    setTime(`${d}d ${h}u ${m}m ${s}s`);
  }
  useEffect(() => {
    const timer = setInterval(() => {
      if (detail != null) {
        const transactionStartDate = new Date(detail?.transactionStartDateTime);
        const currentDate = new Date();
        const transactionStartDateSec = transactionStartDate.getTime() / 1000;
        const currentDateSec = currentDate.getTime() / 1000;
        const timerPeriod = detail?.lockupTime;

        if (detail.isLockedUp === true) {
          const remainingTime =
            transactionStartDateSec + timerPeriod * 60 - currentDateSec;
          convertSeconds(remainingTime);
        } else {
          if (currentDateSec > transactionStartDateSec + timerPeriod * 60) {
            setShowButton(true);
          } else {
            const remainingTime = transactionStartDateSec - currentDateSec;
            convertSeconds(remainingTime);
          }
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [detail]);

  return detail?.status === 4 || detail?.status === 5 ? (
    <span></span>
  ) : detail?.isLockedUp === true ? (
    <h5>Transactie vergrendeld: nog {time} om een bieding te selecteren.</h5>
  ) : (
    <>
      {showButton === true ? (
        <span style={{ display: "flex" }}>
          <h5>Transactie actief: selecteer een bod of sluit de transactie.</h5>
          {(detail?.dealerUserId === user?.userId ||
            detail?.wingmanUserId === user?.userId ||
            user.userType === UserRoles.admin) && (
            <Button
              color="primary"
              style={{ margin: "0 10px 0 15px", padding: "5px" }}
              onClick={() => completeTransactionToggler(detail?.identifier)}
            >
              Transactie voltooien
            </Button>
          )}
        </span>
      ) : (
        <h5>
          Transactie Actief: je hebt nog {time} om een ​​bod te selecteren.
        </h5>
      )}
    </>
  );
};

export default TimerComponent;
