import { DangerText } from "Components/Common/Comman";
import { useAuth } from "context/user";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Form, Input, Row } from "reactstrap";
import { AuthService, UserRoles } from "utility";
import { Btn, H4, P, Image } from "../../../AbstractElements";
import OtpInput from 'react-otp-input';

const Verification = ({ selected }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("")
  const [number, setNumber] = useState([]);
  let shouldFocus = true;
  const navigate = useNavigate()
  const { user } = useAuth()
  const [img, setImg] = useState("");
  const [checkResend, setCheckResend] = useState(true)

  useEffect(async () => {
    const response = await AuthService.get2FAQRcodeImage()
    setImg(response.response.qrCode);
  }, []);

  const GoToCheckAuth = async (e) => {
    try {
      const otp = { code: number + "" }
      const response = await AuthService.validate2FACode(otp)
      if (response.status) {
        if (user.userType == UserRoles.admin) {
          navigate(`/admin-dashboard`);

        } else if (user.userType == UserRoles.employee) {
          navigate(`/employee-dashboard`);

        } else if (user.userType == UserRoles.financier) {
          navigate(`/financiers-dashboard`);
        }
      } else {
        setError(response.message)
      }
    } catch (error) {
      console.log('info', error);
    }
  };

  const ResendOtp = async () => {

    const response = await AuthService.reset2FA()
    response.status ? setCheckResend(false) : setCheckResend(true)

  }

  // const ReStore = (e, i) => {
  //   const newArray = [...number];
  //   newArray[i] = e.target.value;
  //   setNumber(newArray);
  // };
  const handleChange = (otp) => setNumber(otp);

  return (

    <Container fluid={true} className="sec-stap-container">
      <Col md="12" sm="12" xs="12">
        <Row>
          <Col ms="12" sm="12" xs="12">
            <div className="fsform-card-main p-50">
              <Form className="theme-form fsverify-form ">
                <center>
                  <H4>Beveiliging - stap 1</H4>
                </center>
                <Row>
                  <Col md="6" sm="6" xs="12" className="p-r-20">
                    {/* <img src="" alt="" className="fsform-rimg" /> */}

                    {user?.twoFAEnabled && checkResend ?
                      <img
                        src={require("../../../assets/images/Group 74.png")}
                        alt=""
                        className="img-fluid"
                      /> : <img
                        src={img}
                        alt=""
                        className="img-fluid"
                      />}
                  </Col>
                  <Col md="6" sm="6" xs="12" className="p-l-20">
                    {user?.twoFAEnabled && checkResend ?
                      <Row className=" fsform-title">
                        TOM is een platform dat gebruik maakt van Google 2-factor
                        authenticatie voor extra veiligheid. Om het platform te
                        kunnen gebruiken, is het noodzakelijk om Google 2-factor
                        authenticatie in te stellen. Deze extra veiligheidsmaatregel
                        zorgt ervoor dat alleen geautoriseerde gebruikers toegang
                        hebben tot het platform. Het helpt ook om gebruikersgegevens
                        te beschermen tegen toegang door onbevoegde gebruikers.
                      </Row> :
                      <Row className=" fsform-title">
                        TOM is een platform dat gebruik maakt van Google 2-factor
                        authenticatie voor extra veiligheid. Om het platform te
                        kunnen gebruiken, is het noodzakelijk om Google 2-factor
                        authenticatie in te stellen. Deze extra
                        veiligheidsmaatregel zorgt ervoor dat alleen
                        geautoriseerde gebruikers toegang hebben tot het platform.
                        Het helpt ook om gebruikersgegevens te beschermen tegen
                        toegang door onbevoegde gebruikers.
                      </Row>}
                    <Row className=" fsform-strong">
                      {user?.twoFAEnabled && checkResend ? <strong>
                        Uitleg over gebruik van Google 2-factor autenticatie
                      </strong> :
                        <strong>
                          Uitleg over gebruik van Google 2-factor autenticatie
                        </strong>}
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row md="12" sm="12" xs="12" className="otpRow">
                      {/* {[0, 1, 2, 3, 4, 5].map((i) => {
                        const tempFocus = !number[i] && shouldFocus;
                        if (tempFocus) {
                          shouldFocus = !shouldFocus;
                        }
                        console.log(tempFocus, shouldFocus, number);
                        return (
                          <Col>
                            <Input
                              className="form-control text-center opt-text secstap-inp-sty"
                              type="text"
                              maxLength="1"
                              onChange={(e) => ReStore(e, i)}
                              autoFocus={tempFocus}
                            />
                          </Col>
                        );
                      })} */}
                      <OtpInput
                        value={number}
                        onChange={handleChange}
                        numInputs={6}
                      // separator={<span>-</span>}
                      />
                    </Row>
                    {error && <DangerText>*{error}</DangerText>}
                    <Row className="justify-content-center p-b-50">
                      <Btn
                        attrBtn={{
                          className: "fsverify-btn",
                          disabled: loading ? loading : loading,
                          onClick: (e) => GoToCheckAuth(e),
                        }}
                      >

                        <span className="fsform-btn-stap">Volgende stap</span>
                        <i
                          className="fa fa-arrow-right arrow-style-first"
                          aria-hidden="true"
                        ></i>
                      </Btn>
                      <span className="fsform-btn-stap m-3" onClick={() => {
                        ResendOtp()
                      }}><u className="text-dark">Reset 2FA</u></span>

                    </Row>

                    {/* <div className="row"></div> */}
                  </Col>
                </Row>
              </Form>
              {/* </div> */}
            </div>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default Verification;
