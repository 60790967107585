import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Modaliteiten from "./Modaliteiten";
import CardDataSingle from "./CardDataSingle";
import TimerComponent from "./TimerComponent";
import TransationRequestUi from "./TransationRequestUi";
import InfoCard from "./InfoCard";
import { useParams } from "react-router-dom";
import { DashboardService } from "utility/services/dashboard";
import Tijdschema from "./Tijdschema";
import { Box } from "@mui/material";

const FinancerTransactiesDashboard = () => {
  const [isTTCPassed, setTTCPassed] = useState(false);
  const MINUTES_MS = 5000;
  const doRefresh = async () => {
    const trans = await DashboardService.getFinancierTransaction(id);
    let detailData = trans.response;
    let setData = {
      detail: detailData,
      conditions: trans.response?.listOfCondition,
      taskList: trans.response?.listOfTask,
      financiar: trans.response?.listOfFinancier,
    };
    setTransaction(setData);
  };

  const [transaction, setTransaction] = useState({
    detail: null,
    conditions: [],
    taskList: [],
    financiar: [],
  });

  const { id } = useParams();

  const fetchData = async () => {
    const trans = await DashboardService.getFinancierTransaction(id);
    let detailData = trans.response;
    // delete detailData.listOfTask;
    // delete detailData.listOfFinancier;
    // delete detailData.listOfCondition;
    let setData = {
      detail: detailData,
      conditions: trans.response?.listOfCondition,
      taskList: trans.response?.listOfTask,
      financiar: trans.response?.listOfFinancier,
    };

    // const getTransactionlogs = await TransctiesService.getTransactionOfferLogs(detailData?.transactionId);

    setTransaction(setData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Auto refresh logic
  useEffect(() => {
    if (
      transaction?.detail?.status === 4 ||
      transaction?.detail?.status === 5
    ) {
      fetchData();
    } else {
      const interval = setInterval(() => {
        fetchData();
      }, MINUTES_MS);

      return () => clearInterval(interval);
    }
  }, []);
  // console.log(transaction?.detail?.status);
  return (
    <Container fluid={true} className="dashboard-2-main">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
        <h4>Transactie Dashboard</h4>
        <TimerComponent
          fetchData={fetchData}
          detail={transaction?.detail}
        ></TimerComponent>
      </div>
      <Row className="d-flex align-items-stretch">
        <Col xl="12" className=" des-xl-100 box-col-12">
          <Row className="d-flex align-items-stretch">
            <Col sm="6" xl="6" className="col-xl-4 box-col-7 top-sell-sec">
              <Modaliteiten detail={transaction?.detail} />
            </Col>
            <Col sm="6" xl="6" className="col-xl-4 box-col-5 top-sell-sec">
              {/* <CardDataSingle transaction={transaction} /> */}
              <InfoCard detail={transaction?.detail} />
              <TransationRequestUi
                doRefresh={doRefresh}
                detail={transaction?.detail}
              />
              <Tijdschema detail={transaction?.detail} />
            </Col>
            <Col sm="6" xl="4" className="col-xl-4 box-col-4 top-sell-sec">
              {/* <TransationRequestUi
                doRefresh={doRefresh}
                detail={transaction?.detail}
              />
              <Tijdschema detail={transaction?.detail} /> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FinancerTransactiesDashboard;
