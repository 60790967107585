import React, { Fragment, useState } from "react";
import { Col, Card, CardHeader, CardBody, Form, Label, Row } from "reactstrap";
import { GetEmployeeList } from "hooks";

const FoAndFoAUserSelectionComponent = (transferedData) => {
  const { transection, setTransection, errorState } = transferedData;
  const { data: userList } = GetEmployeeList();

  const [mainUserData, setMainUserData] = useState();

  React.useEffect(() => {
    if (userList) {
      setMainUserData(userList);
    }
  }, [userList]);

  const handleFoUser = (e) => {
    setTransection({
      ...transection,
      dealerUserId: mainUserData[e.target.value].userId,
    });
  };

  const handleFoaUser = (e) => {
    setTransection({
      ...transection,
      wingmanUserId: mainUserData[e.target.value].userId,
    });
  };

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">
            Selecteer Dealer en Wingman
          </h5>
        </CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate="">
            <Row className="g-3">
              <Col md="6">
                <Label className="form-label" for="dealerUserId">
                  Selecteer Dealer
                </Label>

                <select
                  name="select"
                  className="form-control form-control-primary btn-square form-select"
                  onChange={handleFoUser}
                >
                  <option value="0">Selecteer </option>
                  {mainUserData?.map(
                    (value, index) =>
                      value.userRoleId == 2 && (
                        <option
                          key={index}
                          value={index}
                          label={value.firstName + " " + value.lastName}
                        />
                      )
                  )}
                </select>
                {errorState.includes("dealerUserId") && (
                  <div>*Dealer is vereist</div>
                )}
              </Col>
              <Col md="6 mb-3">
                <Label className="form-label" for="">
                  Selecteer Wingman
                </Label>

                <select
                  name="select"
                  className="form-control form-control-primary btn-square form-select"
                  onChange={handleFoaUser}
                >
                  <option value="0">Selecteer </option>
                  {mainUserData?.map(
                    (value, index) =>
                      value.userRoleId == 2 && (
                        <option
                          key={index}
                          value={index}
                          label={value.firstName + " " + value.lastName}
                        />
                      )
                  )}
                </select>
                {errorState.includes("wingmanUserId") && (
                  <div>*Wingman is vereist</div>
                )}
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default FoAndFoAUserSelectionComponent;
