import axios from 'axios'
import { AuthService } from './auth'
import { APIPath } from '../constant'

const onSuccess = response => response.data

const onError = async error =>
    Promise.reject({
        error: error?.response?.data?.error || error?.response?.data,
        status: error?.response?.status,
    })

const refreshToken = async (error) => {
    const originalRequest = error.config;
    // if (error?.response?.status === 403 && !originalRequest._retry) {
    //     originalRequest._retry = true;
    //     const refresh_token = UserService.getRefreshToken();
    //     if (refresh_token) {
    //         try {
    //             const params = {
    //                 refresh_token,
    //             };
    //             const result = await UserService.refreshToken(params);
    //             UserService.storeUser(result);
    //             originalRequest.headers['Authorization'] = 'Bearer ' + result.access_token;
    //             return originalRequest;
    //         } catch (error) {
    //             window.location.href = '/'
    //         }
    //     } else {
    //         window.location.href = '/'
    //     }
    // }
    if (error?.response?.status === 401 && !originalRequest._retry) {
        window.location.href = '/'
    }
    return Promise.reject(error);
}

const request = async (options, isSecure) => {
    let headers = {};

    if (isSecure) {
        const token = AuthService.getToken();
        headers['Authorization'] = `Bearer ${token}`;
    }

    const client = axios.create({
        baseURL: APIPath.server,
        headers: headers,
        body: options.data
    });

    client.interceptors.response.use((response) => {
        return response
    }, async (error) => {
        const refreshReq = await refreshToken(error);
        return client(refreshReq);
    });

    return client(options)
        .then(onSuccess)
        .catch(onError);
}

const uploadFiles = async (url, data, headers) => {
    let headerss = {};
    const token = AuthService.getToken();
    headerss['Authorization'] = `Bearer ${token}`;

    const client = axios({
        url,
        data,
        method: 'POST',
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
        },
    })

    return client.then(onSuccess).catch(onError)
}

export class BaseService {
    static get(url, isSecure = true) {
        return request(
            {
                url,
                method: 'GET',
            },
            isSecure,
        )
    }

    static post(url, data, isSecure = true) {
        return request(
            {
                url,
                method: 'POST',
                mode: 'no-cors',
                data,
            },
            isSecure,

        )
    }

    static put(url, data, isSecure = true) {
        return request(
            {
                url,
                method: 'PUT',
                data,
            },
            isSecure,
        )
    }

    static extenralAPICall(url) {
        const client = axios({
            url,
            method: 'GET',
            timeout: 1000 * 3,
        })
        return client.then(onSuccess).catch(onError)
    }

    static remove(url, isSecure = true) {
        return request(
            {
                url,
                method: 'DELETE',
            },
            isSecure,
        )
    }

    static upload = (url, data, header) => {
        return uploadFiles(url, data, header)
    }
}
