// const basePath = "https://tomapi.veamex.com/api/";
const basePath = "https://apitransacties.thesor.nl/";
// const basePath = "https://localhost:44373/api/";

export const APIPath = {
  server: basePath,
  // auth
  login: `${basePath}Authorization/Login`,
  get2FAQRcodeImage: `${basePath}Authorization/Get2FAQRcodeImage`,
  reset2FA: `${basePath}Authorization/Reset2FA`,
  validate2FACode: `${basePath}Authorization/Validate2FACode`,

  getEmployeeDashboard: `${basePath}Dashboard/GetEmployeeDashboard`,
  getChartData: `${basePath}Transaction/GetChartData`,
  completeTransaction: `${basePath}Transaction/CompleteTransaction`,
  getTransactions: `${basePath}Transaction/GetTransactions`,
  getTransactionFields: `${basePath}Transaction/GetTransactionFields`,
  transactionDashboard: `${basePath}Dashboard/GetTransactionDashboard`,
  transactionFinancierDashboard: `${basePath}Dashboard/GetTransactionDashboardForFinancier`,

  financiersDashboard: `${basePath}Dashboard/GetFinancierDashboard`,

  getFinanciers: `${basePath}Financier/GetFinanciers`,
  getFinancierEmployees: `${basePath}Financier/GetFinancierEmployees`,

  getFinanciersType: `${basePath}Financier/GetFinancierTypes`,
  deleteFinancierEmployee: `${basePath}Financier/DeleteFinancierEmployee`,

  getCustomers: `${basePath}Customer/GetCustomers`,
  setTransactionTaskCheck: `${basePath}Transaction/ChecklistStatusChange`,
  getTransactionOfferLogs: `${basePath}Transaction/GetProposalLog`,
  getProposals: `${basePath}Transaction/GetProposals`,
  getTTC: `${basePath}Transaction/GetTTC`,
  setCustomerList: `${basePath}Customer/InsertUpdateCustomer`, //post method
  deleteCustomer: `${basePath}Customer/DeleteCustomer`, //post method
  getCustomerTypes: `${basePath}Customer/GetCustomerTypes`,
  tasksList: `${basePath}Transaction/GetDefaultChecklist`,
  setTransaction: `${basePath}Transaction/InsertTransaction`,
  setTransactionoffer: `${basePath}Transaction/NewProposal`,
  deleteTransaction: `${basePath}Transaction/DeleteTransaction`,
  setTransactionOfferAccept: `${basePath}Transaction/ApproveProposalAndCloseTransaction`,
  insertUpdateFinancier: `${basePath}Financier/InsertUpdateFinancier`,
  deleteFinancier: `${basePath}Financier/DeleteFinancier`,
  deleteUser: `${basePath}User/DeleteUser`,

  getSwaprate: `${basePath}Transaction/GetSwaprate`,
  getSwaprateValue: `${basePath}Transaction/GetSwaprateValue`,
  insertUpdateFinancierEmployee: `${basePath}Financier/InsertUpdateFinancierEmployee`,

  // Above new one

  validateToken: `${basePath}users/ValidateToken`,

  //user
  userList: `${basePath}User/GetUserList`,
  activateLoginForPerson: `${basePath}Authorization/ActivateLoginForPerson`,

  setUserList: `${basePath}User/SetUserRegister`,
  userPost: `${basePath}User/PostUser`,

  //Customer

  customerById: `${basePath}Master/GetCustomerbyid`,
  // deleteCustomer: `${basePath}Master/DeleteCustomer`, //post method

  //transaction
  transactionResentList: `${basePath}Transaction/GetRecentTransactions`,
  transactionUpcommingList: `${basePath}Transaction/GetUpcomingTransaction`,
  GetEmailTemplateList: `${basePath}Transaction/GetEmailTemplate`,
  TransactionMailSend: `${basePath}Transaction/SendEmail`,
  getRecentlyCompletedTransactions: `${basePath}Transaction/GetRecentlyCompletedTransactions`,
  rejectTransactionProposal: `${basePath}Transaction/RejectTransactionProposal`,
  //financer

  //tasks

  setTask: `${basePath}Master/setTask`,

  //upload file
  getContextFromPDF: `${basePath}Transaction/GetTextFromPDF`,
};
