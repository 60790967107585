import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from 'utility/constant'

class Financers {

    getFinancerDashboard() {
        return BaseService.get(`${APIPath.financiersDashboard}`)
    }

    getFinancerList(reqData) {
        return BaseService.get(`${APIPath.getFinanciers}`)
    }

    getFinancierEmployees(reqData) {
        return BaseService.get(`${APIPath.getFinancierEmployees}?financierId=${reqData}`)
    }

    getFinancerType(reqData) {
        return BaseService.get(`${APIPath.getFinanciersType}`)
    }

}

const FinancerService = new Financers()
Object.freeze(FinancerService)
export { FinancerService }
