import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useState } from "react";
import Aankomendetransacties from "./Aankomendetransacties";
import LaatsteBiedingen from "./LaatsteBiedingen";
import IncomeCard from "./IncomeCard";
import { FinancerService } from "utility/services/financiers";

export const FinancierDashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [UpcomingTransaction, setUpcomingTransaction] = useState([]);
  const [recentcompleted, setRecentcompleted] = useState([]);
  const [listofAcceptedoffers, setlistofAcceptedoffers] = useState([]);
  const [listOfOffers, setListOfOffers] = useState([]);
  const [isDirectFinancier, setIsDirectFinancier] = useState(false);
  const [transaction, setTransaction] = useState([]);

  const handleChange = (date) => {
    setStartDate(date);
  };
  useEffect(() => {
    const getTransaction = async () => {
      const response = await FinancerService.getFinancerDashboard();

      const listofBox = response?.response?.listofBox;
      setTransaction(listofBox);
      const listofUpcomingTransaction =
        response?.response?.upcomingTransactions;
      setUpcomingTransaction(listofUpcomingTransaction);
      const listofRecentcompleted = response?.response?.upcomingTransactions;
      setRecentcompleted(listofRecentcompleted);
      const listofAcceptedoffers = response?.response?.acceptedProposals;
      setlistofAcceptedoffers(listofAcceptedoffers);
      setListOfOffers(response?.response?.proposals);
    };
    getTransaction();
    // return () => {
    //   setTransaction([]);
    // };
  }, []);
  return (
    <Container fluid={true} className="dashboard-default-sec">
      <Row>
        <Col xl="9" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" className="box-col-12 des-xl-100">
              <Row>
                {transaction.map((item, i) => (
                  <Col
                    xl="4"
                    md="4"
                    sm="6"
                    key={i}
                    className={
                      i % 2
                        ? `box-col-3 des-xl-25 rate-sec box-primary`
                        : `box-col-3 des-xl-25 rate-sec box-secondary`
                    }
                  >
                    <IncomeCard
                      topline={item.title}
                      amount={item.value}
                      title={item.subTitle}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="box-col-12 des-xl-100">
              <LaatsteBiedingen
                title="Laatste biedingen"
                recentcompleted={listOfOffers}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xl="3"
          md="12"
          sm="12"
          className="box-col-12 des-xl-100"
          style={{ paddingBottom: "20px" }}
        >
          <Aankomendetransacties
            UpcomingTransaction={UpcomingTransaction.slice(0, 5)}
          />
        </Col>
      </Row>
      <Row>
        <Col xl="12" className="box-col-12 des-xl-100">
          <LaatsteBiedingen
            title="Gewonnen biedingen"
            recentcompleted={listofAcceptedoffers}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FinancierDashboard;
