import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { FormNumberField } from "CommonElements/FormElements";
import { toast } from "react-toastify";
import { TransctiesService } from "utility";
import { Step, Stepper, StepLabel, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Modaliteiten from "../../../Components/Pages/FinancerTransactionDetails/Modaliteiten";
import InfoCard from "../../../Components/Pages/FinancerTransactionDetails/InfoCard";
import { Btn } from "AbstractElements";
import DisplayTransactions from "./DisplayTransactions";
import { sassTrue } from "sass";
import { display } from "@mui/system";

const SelectedFinancierTransaction = ({ transaction, details }) => {
  // console.log(transaction);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(-1);
  const [isAKL, setAKL] = useState(false);
  const [isPrev, setIsPrev] = useState(true);
  const [displayInfo, setdisplayInfo] = useState([]);
  const [tariefForDisplay, setTariefForDisplay] = useState(0);
  const [feesForDisplay, setFeesForDisplay] = useState(0);
  // console.log(details, transaction);

  const { control, handleSubmit, getValues, errors } = useForm();

  const handleSteps = () => {
    // console.log("button clicked", activeStep);
    if (activeStep < 0) {
      // console.log("1, here", activeStep);
      setActiveStep((prev) => prev + 1);
    } else if (activeStep >= 0 && activeStep < transaction.length) {
      // console.log("2, here", activeStep, transaction.length);
      transaction.map((id, index) => {
        if (activeStep === index) {
          if (details[index]?.showFees === false) {
            // console.log("hello");
            if (tariefForDisplay) {
              // console.log("inside if");
              activeStep < transaction.length &&
                setActiveStep((prev) => prev + 1);
              createDisplayData(
                details[index].customer?.company?.name,
                details[index]?.depositDate,
                details[index]?.loanEndDate,
                "N/A",
                "N/A",
                tariefForDisplay,
                decimalToCommaSeparatedInt(tariefForDisplay)
              );
              setTariefForDisplay(0);
              console.log(details[index]);
            }
          } else {
            // console.log("here");
            if (feesForDisplay && tariefForDisplay) {
              // console.log("inside if");
              activeStep < transaction.length &&
                setActiveStep((prev) => prev + 1);
              createDisplayData(
                details[index].customer?.company?.name,
                details[index]?.depositDate,
                details[index]?.loanEndDate,
                feesForDisplay,
                decimalToCommaSeparatedInt(feesForDisplay),
                tariefForDisplay,
                decimalToCommaSeparatedInt(tariefForDisplay)
              );
              setTariefForDisplay(0);
              setFeesForDisplay(0);
            }
          }
        }
      });
    } else {
      // console.log("3, here", activeStep);
      setActiveStep((prev) => prev + 1);
    }
  };

  const handlePreSteps = () => {
    if (activeStep === transaction.length + 1) {
      setActiveStep(activeStep - 1);
      const info = [...displayInfo];

      // info.pop();
      setdisplayInfo(info);
      // console.log(displayInfo);
    } else {
      setActiveStep(activeStep - 1);
      const info = [...displayInfo];

      info.pop();
      setdisplayInfo(info);
      // console.log(displayInfo);
    }
  };

  const handleCheckBox = () => {
    setAKL(!isAKL);
    setIsPrev(!isPrev);
  };

  const createDisplayData = (
    customerName,
    startdate,
    enddate,
    fees,
    feesForDisplay,
    opslag,
    opslagForDisplay
  ) => {
    console.log(customerName, startdate, enddate, fees, opslag);
    const info = [...displayInfo];
    info.push({
      customerName: customerName,
      looptijd: timeDurationFormat(startdate, enddate),
      fees: fees,
      feesForDisplay: feesForDisplay,
      opslag: opslag,
      opslagForDisplay: opslagForDisplay,
    });
    setdisplayInfo(info);
    console.log(info);
  };
  const timeDurationFormat = (loanStartDate, loanEndDate) => {
    const endDay = new Date(loanEndDate);
    const endDateInSeconds = endDay.getTime() / 1000;
    const startDay = new Date(loanStartDate);
    const startDateInSeconds = startDay.getTime() / 1000;
    let differenceSeconds = endDateInSeconds - startDateInSeconds;
    const days = Math.floor(differenceSeconds / (24 * 60 * 60));
    const duration = days / 365;
    const durationWithDecimal = duration.toFixed(2).toString();
    const durationWithCommaString = durationWithDecimal.replace(".", ",");

    return `${durationWithCommaString} jaar`;
  };

  const setData = (identifier) => {
    console.log(identifier);
    let a = [{}];
    a.shift();
    console.log(displayInfo);
    identifier.map((id, index) => {
      return a.push({
        transactionId: parseFloat(id),
        offerPercentage: displayInfo[index].opslag,
        fee: details[index]?.showFees === false ? "0" : displayInfo[index].fees,
      });
    });
    console.log(a);
    isAKL &&
      a.map(async (data, i) => {
        await Promise.all(TransctiesService.setTransactionoffer(data))
          .then(
            toast.success("Uw voorstel is verstuurd.", {
              position: toast.POSITION.TOP_RIGHT,
            })
          )
          .catch((error) => {
            setIsSubmitted(false);
            toast.success(error, {
              position: toast.POSITION.TOP_RIGHT,
            });
          });
        setActiveStep((prev) => prev + 1);
        setIsSubmitted(true);
      });
  };

  setTimeout(function () {
    activeStep < 0 && handleSteps();
  }, 1000);

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-root.Mui-active": { color: "orange" },
      "& .MuiStepIcon-root.Mui-completed": { color: "orange" },
      "& .Mui-disabled .MuiStepIcon-root": { color: "gray" },
    },
  }));
  const c = useStyles();

  const feesChangeHandler = (e) => {
    // console.log(e);
    setFeesForDisplay(e);
  };
  const tariefChangeHandler = (e) => {
    setTariefForDisplay(e);
  };

  function decimalToCommaSeparatedInt(decimalString) {
    const number = Number(decimalString);
    const checkForInteger = Number.isInteger(number);
    // console.log(number);
    if (checkForInteger === true) {
      return `(${decimalString} %)`;
    } else if (checkForInteger === false) {
      const numberAdjustment = parseFloat(decimalString).toFixed(3);
      // console.log(numberAdjustment);
      if (numberAdjustment === ".") {
        let num = decimalString.replace(".", "");
        return `(${num} %)`;
      } else {
        const parts = numberAdjustment.toString().split(".");
        const integarValue = parts[0];
        const decimalValue = parts[1];
        // console.log(integarValue);
        return `(${integarValue},${decimalValue} %)`;
      }
    }
  }

  return (
    <div>
      {transaction.length < 6 && (
        <Box
          width="80%"
          m="30px auto"
          // sx={{ display: { xs: "none", sm: "none", md: "none", lg: "block" } }}
        >
          <Stepper className={c.root} activeStep={activeStep}>
            {transaction?.map((id, key) => {
              return (
                <Step key={"step" + id}>
                  <StepLabel key={"label" + id}>Transactie {key + 1}</StepLabel>
                </Step>
              );
            })}
            <Step>
              <StepLabel>Toon gegevens</StepLabel>
            </Step>
            <Step>
              <StepLabel>Overzicht en Bevestigen</StepLabel>
            </Step>
          </Stepper>
        </Box>
      )}

      <Row className="d-flex justify-content-center">
        <Col sm={10} className="d-flex flex-column justify-content-between">
          <Form className="" onSubmit={handleSubmit(handleSteps)}>
            <FormGroup>
              <div className="w-100 row" style={{ margin: "0.0%" }}>
                {transaction?.map((detail, index) => {
                  return (
                    <>
                      {activeStep === index && (
                        <>
                          <div className="col-lg-12 m-0">
                            <InfoCard detail={details[activeStep]} />
                          </div>
                          <Modaliteiten
                            detail={details[activeStep]}
                            isModel={true}
                          />
                        </>
                      )}
                    </>
                  );
                })}
              </div>

              {transaction?.map((i, key) => {
                return (
                  <>
                    {activeStep == key && (
                      <>
                        <Row style={{ margin: "0.0%" }}>
                          {details[key]?.showFees === true && (
                            <Col className="col-lg-6" key={"Fee_" + i}>
                              <FormNumberField
                                className="form-control"
                                min="0"
                                max="100"
                                step="0.01"
                                required={true}
                                type="number"
                                label={`Fee
                                  ${decimalToCommaSeparatedInt(feesForDisplay)}
                                `}
                                name={"Fee_" + key}
                                placeholder="Fee"
                                control={control}
                                errors={errors}
                                value={feesForDisplay}
                                onChange={(e) => feesChangeHandler(e)}
                              />
                            </Col>
                          )}
                          {details[key]?.showFees === false && (
                            <Col className="col-lg-6" key={"Opslag_" + i}>
                              <FormNumberField
                                className="form-control"
                                min="0"
                                max="100"
                                type="number"
                                step="0.01"
                                label={`Tarief ${decimalToCommaSeparatedInt(
                                  tariefForDisplay
                                )}
                                `}
                                required={true}
                                name={"Opslag_" + key}
                                control={control}
                                errors={errors}
                                placeholder="Tarief"
                                value={tariefForDisplay}
                                onChange={(e) => tariefChangeHandler(e)}
                              />
                            </Col>
                          )}
                          {details[key]?.showFees === true && (
                            <Col className="col-lg-6" key={"Opslag_" + i}>
                              <FormNumberField
                                className="form-control"
                                min="0"
                                max="100"
                                type="number"
                                step="0.01"
                                label={`Tarief (excl. fee) ${decimalToCommaSeparatedInt(
                                  tariefForDisplay
                                )}
                                `}
                                required={true}
                                name={"Opslag_" + key}
                                control={control}
                                errors={errors}
                                value={tariefForDisplay}
                                placeholder="Tarief (excl. fee)"
                                onChange={(e) => tariefChangeHandler(e)}
                              />
                            </Col>
                          )}

                          {/* <Col className="col-lg-6" key={"Opslag_" + i}>
                            <FormNumberField
                              className="form-control"
                              min="0"
                              max="100"
                              type="number"
                              step="0.01"
                              label="Tarief"
                              required={true}
                              name={"Opslag_" + key}
                              control={control}
                              errors={errors}
                            />
                          </Col> */}
                        </Row>
                      </>
                    )}
                  </>
                );
              })}
            </FormGroup>
            {activeStep === transaction.length + 2 && isSubmitted && (
              <div className="card">
                <div className="card-body">
                  <div className="text-center text-dark">
                    <h3>bedankt voor het indienen van een aanbieding</h3>
                  </div>
                </div>
              </div>
            )}
            {activeStep === transaction.length && (
              <div className="card">
                <div className="card-body">
                  <Row className="justify-content-center">
                    <DisplayTransactions displayInfo={displayInfo}>
                      {/* {console.log(activeStep)} */}
                    </DisplayTransactions>
                  </Row>
                </div>
              </div>
            )}

            {activeStep === transaction.length + 1 && (
              <div className="card">
                <div className="card-body">
                  <Row className="justify-content-center">
                    {/* <div className="text-center">
                      1Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      Earum, dolor! Sit minus rerum, sapiente amet praesentium
                      labore animi aut, eius nesciunt, adipisci incidunt quos
                      corrupti cum. Modi sequi ratione a? Lorem, ipsum dolor sit
                      amet consectetur adipisicing elit. Earum, dolor! Sit minus
                      rerum, sapiente amet
                    </div> */}
                    <div className="checkbox checkbox-dark text-center mt-5">
                      <Input
                        id="inline-3"
                        type="checkbox"
                        checked={isAKL}
                        onClick={handleCheckBox}
                        name="akl"
                        control={control}
                      />
                      <Label for="inline-3">
                        Ik ga akkoord met het versturen van dit voorstel.
                      </Label>
                      <Col>
                        {isAKL ? (
                          <Btn
                            attrBtn={{
                              color: "primary",

                              className: "plus-popup mt-2",
                              onClick: () => setData(transaction),
                            }}
                          >
                            Aanbieding indienen
                          </Btn>
                        ) : (
                          <Btn
                            attrBtn={{
                              color: "secondary",
                              className: "plus-popup mt-2",
                              onClick: () => setData(transaction),
                            }}
                          >
                            Aanbieding indienen
                          </Btn>
                        )}
                      </Col>
                    </div>
                  </Row>
                </div>
              </div>
            )}
            <Row className=" text-center">
              {activeStep > 0 && isPrev && (
                <Col>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "plus-popup mt-2",
                      onClick: () => handlePreSteps(),
                    }}
                  >
                    Vorig
                  </Btn>
                </Col>
              )}
              {activeStep < transaction.length + 1 && (
                <Col>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "plus-popup mt-2",
                      onClick: () => handleSteps(),
                    }}
                  >
                    Volgend
                  </Btn>
                </Col>
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default SelectedFinancierTransaction;
