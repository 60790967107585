import React, { useEffect, useState } from "react";
import { LatestActivity } from "../../Common/Data/Dashboard";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { DashboardService } from "utility/services/dashboard";
import { H5 } from "AbstractElements";
import { Box } from "@mui/material";

const Aankomendetransacties = (props) => {
  const { UpcomingTransaction } = props;

  // const [UpcomingTransaction, setUpcomingTransaction] = useState([])
  // useEffect(() => {
  //   const getListofUpcomingTransaction = async () => {
  //     const response = await DashboardService.getEmployee();
  //     const listofUpcomingTransaction = response?.response?.listofUpcomingTransaction;
  //     setUpcomingTransaction(listofUpcomingTransaction);
  //   }
  //   getListofUpcomingTransaction();
  //   return () => {
  //     setUpcomingTransaction([]);
  //   }
  // }, [])
  // console.log(UpcomingTransaction);

  return (
    // <Card className="latest-update-sec latest-update-sec-two fullheight-card  full-width">
    <Card style={{ height: "100%" }}>
      <Box>
        <CardHeader>
          {/* <div className="header-top d-flex justify-content-between align-items-center"> */}
          <h5 className="header-top full-width d-flex justify-content-between align-items-center">
            Aankomende transacties
          </h5>
          {/* <Link to={`${process.env.PUBLIC_URL}/dashboard/transcties`}>
            <p style={{ color: "#FF9E00" }}> Bekijk alles</p>
          </Link> */}

          {/* <Link to="/upcoming-transactions">
            <h6 style={{ color: "#FF9E00" }}> Bekijk alles</h6>
          </Link> */}
          {/* </div> */}
        </CardHeader>
        <Box>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-bordernone m-0">
                <tbody>
                  {UpcomingTransaction.length === 0 ? (
                    <tr
                      style={{
                        color: "#212F56",
                        marginTop: "50%",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        opacity: "0.7",
                      }}
                    >
                      Nog geen transacties bepaald.
                    </tr>
                  ) : (
                    UpcomingTransaction.map((item) => (
                      <tr hover key={item.transactionId}>
                        <td
                          style={{
                            borderBottom: "1px solid #ced4da",
                          }}
                        >
                          <Link
                            // to={`${process.env.PUBLIC_URL}/dashboard/transactiondashboard`}
                            to={`/financiers-transctie-dashboard/${item.identifier}`}
                          >
                            <div className="media">
                              <div className="bg-secondary  dateicon">
                                {item?.date.replace("-", "\n")}
                              </div>
                              <div
                                className="media-body"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <span>{item.customerName}</span>
                                <p>{item.time.split("-")[0]}</p>
                              </div>
                            </div>
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Box>
      </Box>
    </Card>
  );
};

export default Aankomendetransacties;
