import { ConstructionOutlined, LegendToggle } from "@mui/icons-material";
import { useAuth } from "context/user";
import React, { useEffect, useState } from "react";
import { UserRoles } from "utility";

const TimerComponent = ({ detail, fetchData }) => {
  const { user } = useAuth();
  const [time, setTime] = useState("");
  const [afterLockUpTime, setAfterLockupTime] = useState(false);
  var timer = 0;

  function convertSeconds(seconds) {
    let d, h, m, s;
    s = Math.floor(seconds % 60);
    m = Math.floor(((seconds - s) / 60) % 60);
    h = Math.floor(((seconds - m * 60 - s) / 3000) % 24);
    d = Math.floor((seconds - h * 3600 - m * 60 - s) / 86400);
    if (s < 0) {
      fetchData();
    }
    setTime(`${d}d ${h}u ${m}m ${s}s`);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      // console.log(detail);
      if (detail != null) {
        const transactionStartDate = new Date(detail?.transactionStartDateTime);
        const currentDate = new Date();
        const transactionStartDateSec = transactionStartDate.getTime() / 1000;
        const currentDateSec = currentDate.getTime() / 1000;
        const timerPeriod = detail?.lockupTime;
        // const timerPeriod = 11;

        if (detail.isLockedUp === true) {
          const remainingTime =
            transactionStartDateSec + timerPeriod * 60 - currentDateSec;
          convertSeconds(remainingTime);

          // console.log("1", remainingTime);
        } else {
          if (currentDateSec > transactionStartDateSec + timerPeriod * 60) {
            // console.log("2");
            setAfterLockupTime(true);
          } else {
            const remainingTime = transactionStartDateSec - currentDateSec;
            convertSeconds(remainingTime);
            // console.log("3", transactionStartDateSec, currentDateSec);
          }
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [detail]);

  const isFinancierSelected = (detail) => {
    console.log(detail);
    if (detail?.status === 5) {
      return "Deze transactie is beëindigd zonder dat er een offerte is geselecteerd.";
    } else {
      if (detail?.proposal !== null) {
        if (detail?.proposal?.isSelected === true) {
          return "Uw voorstel is geaccepteerd";
        } else {
          return "Uw voorstel is niet geselecteerd";
        }
      } else {
        return "Uw voorstel is niet geselecteerd";
      }
    }
  };

  return detail?.status === 4 || detail?.status === 5 ? (
    <span>
      {user.userType === UserRoles.financier && (
        <h5>{isFinancierSelected(detail)}</h5>
      )}
    </span>
  ) : detail?.isLockedUp === true ? (
    <>
      {detail?.proposal === null ? (
        <h5>
          Transactie vergrendeld voor {time}: u kunt geen bieding plaatsen op
          dit moment.
        </h5>
      ) : (
        <h5>
          Transactie vergrendeld voor {time}: u kunt geen bieding aanpassen op
          dit moment
        </h5>
      )}
    </>
  ) : (
    <>
      {afterLockUpTime === true ? (
        <>
          {" "}
          {detail?.proposal === null ? (
            <h5>Transactie actief: U kunt een bod uitbrengen.</h5>
          ) : (
            <h5>Transactie actief: u kunt uw bod aanpassen.</h5>
          )}
        </>
      ) : (
        <>
          {" "}
          {detail?.proposal === null ? (
            <h5>
              Transactie actief: U heeft nog {time} om een ​​bod uit te brengen.
            </h5>
          ) : (
            <h5>
              Transactie actief: U heeft nog {time} om een ​​bod aan te passen.
            </h5>
          )}
        </>
      )}
    </>
  );
};

export default TimerComponent;
