import { useEffect, useState } from 'react'
import {
    ErrorConstant,
    TransctiesService,
} from 'utility'

export const GetTransactionList = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await TransctiesService.getTransactionList()
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}

// export const getTransactionFields = () => {
//     const [data, setData] = useState(null)
//     const [loading, setLoading] = useState(false)
//     const [error, setError] = useState('')

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 setLoading(true)
//                 const result = await TransctiesService.getTransactionFields()
//                 setData(result.response || {})
//             } catch {
//                 setError(ErrorConstant.default)
//             } finally {
//                 setLoading(false)
//             }
//         }

//         if (!loading) {
//             fetchData()
//         }
//     }, [])

//     return {
//         data,
//         loading,
//         error,
//         setData,
//     }
// }

export const GetTransactionRecentList = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await TransctiesService.getTransactionResentList()
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}

export const GetRecentlyCompletedTransactions = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await TransctiesService.getRecentlyCompletedTransactions()
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}


export const GetTransactionUpcomingList = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await TransctiesService.getTransactionUpcommingList()
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}
