import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import { AuthService, UserRoles } from "utility";
import { useAuth } from "context/user";

import { GetCustomerList, GetCustomerTypes } from "hooks";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormSelectField, FormTextField } from "CommonElements/FormElements";

import { CustomerService } from "utility/services/customer";
import { toast } from "react-toastify";

import { Col, Row, Card, Form, FormGroup, Label } from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Btn } from "../../../AbstractElements";

// / item.identifier,
// item.id,
// item.name,
// item.address,
// item.customerType,
// item.sector

function createData(
  identifier,
  id,
  name,
  address,
  customerType,
  sector,
  customerTypeId
) {
  return {
    identifier,
    id,
    name,
    address,
    customerType,
    sector,
    customerTypeId,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: "id",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Klant ID",
  // },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Klantnaam",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Plaats",
  },
  {
    id: "sector",
    numeric: false,
    disablePadding: false,
    label: "Sector ",
  },
  {
    id: "customerType",
    numeric: false,
    disablePadding: false,
    label: "Klanttype",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Acties",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:Mui-selected': {
//     backgroundColor: "#FF9E00",
//   }
// }));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell> */}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ color: "#212F56" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    onClickAddTransaction,
    handleAddCustomer,
    selected,
    handleEditCustomer,
    handleDeleteCustomer,
  } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Klanten
        </Typography>
      )}

      {numSelected === 1 && (
        <IconButton>
          <Tooltip title="Klantgegevens aanpassen">
            <EditIcon
              onClick={() => handleEditCustomer(selected[0])}
              style={{ color: "#FF9E00", margin: "10px" }}
            />
          </Tooltip>{" "}
        </IconButton>
      )}

      {numSelected > 0 ? (
        <IconButton>
          <Tooltip title="Delete">
            <DeleteIcon
              onClick={() => handleDeleteCustomer(selected)}
              style={{ color: "#FF9E00", margin: "10px" }}
            />
          </Tooltip>
        </IconButton>
      ) : (
        <IconButton>
          <Tooltip title="Klant toevoegen">
            <AddIcon
              onClick={() => handleAddCustomer()}
              style={{ color: "#FF9E00", margin: "10px", muiName: "Add" }}
            />
          </Tooltip>
          {/* <Tooltip title="Filters">
            <FilterListIcon style={{ color: "#FF9E00", margin: "10px" }} />
          </Tooltip> */}
        </IconButton>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const CustomerManagementList = () => {
  const { user } = useAuth();
  const [rows, setRows] = useState([]);
  const [mainUserData, setMainUserData] = useState();
  const [customerTypes, setCustomerTypes] = useState([]);
  const [selectedCustomerObject, setSelectedListObject] = useState();
  const { data: userList, loading } = GetCustomerList();
  const [existingNames, setExistingNames] = useState([]);
  const { data: _customerTypes, loading: customerTypeLoading } =
    GetCustomerTypes();
  const [errorState, setErrorState] = useState([]);
  const [sectorTypes, setSectorTypes] = useState([
    { name: "Selecteer", id: "" },
    { name: "Fido-gemeente", id: "1" },
    { name: "Fido-provincie", id: "2" },
    { name: "Fido-waterschap", id: "3" },
    { name: "Gez. zorg", id: "4" },
    { name: "Nutsbedrijf", id: "5" },
    { name: "Onderwijs", id: "6" },
    { name: "Woningcorporatie", id: "7" },
    { name: "Overig", id: "8" },
  ]);

  // console.log(userList, loading, "123");
  useEffect(() => {
    // const { data: userList, loading } = GetUserList();
    if (userList) {
      setMainUserData(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (_customerTypes) {
      const concateCustomerData = [];
      concateCustomerData.push({ name: "Selecteer", value: 0 });
      _customerTypes.map((item) => {
        concateCustomerData.push({ ...item, value: item.id });
      });
      setCustomerTypes(concateCustomerData);
    }
  }, [_customerTypes]);
  // console.log(_customerTypes);

  useEffect(() => {
    if (mainUserData) {
      let translated = mainUserData.map((item) => {
        return createData(
          item.identifier,
          item.id,
          item.name,
          item.address,
          item.customerType,
          item.sector,
          item.customerTypeId
        );
      });
      setRows(translated);
    }
  }, [mainUserData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (mainUserData) {
      setExistingNames(mainUserData);
    }
  }, [mainUserData]);

  const onClickAddTransaction = () => {
    navigate(`/transacties/add`);
  };

  const onClickDetailsransaction = (e, row) => {
    navigate(`/transacties/dashboard/` + row.id);
  };

  // const userList =
  const [modal, setModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const toggle = () => setModal(!modal);
  // const [isSbpopupOpen, setSubpopupOpen] = useState(false);
  const [displayNone] = useState("d-none");

  const handleAddCustomer = () => {
    setValue("id", 0);
    setValue("name", "");
    setValue("address", "");
    setValue("sector", "");
    setValue("customerType", "");
    setValue("customerTypeId", 0);
    setSelectedListObject({
      id: 0,
      name: "",
      address: "",
      sector: "",
      customerType: "",
      customerTypeId: 0,
      identifier: "",
    });
    toggle();
    setErrorState([]);
  };

  const deleteAPICall = async (id) => {
    const response = await CustomerService.deleteCustomer(id);
    if (response?.status) {
      toast.success("De gegevens van de klant zijn aangepast.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning(
        "Klanten is verbonden aan transactie dus kan niet worden verwijderd.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    try {
      const result = await CustomerService.getCustomerList();
      setRows(result.response);
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteCustomer = async (selected) => {
    const data = rows.filter((item) => {
      if (item.id === selected) return item;
    });
    try {
      // selected.map((item) => {
      // const object = rows.filter((_item) => { if (_item.id === item) return _item })
      deleteAPICall(data[0].identifier);

      // })
      // setSelected([])
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEditCustomer = (id) => {
    setIsEditing(true);
    const editableArray = existingNames.filter((item) => item.id !== id);
    setExistingNames(editableArray);
    const data = rows.filter((item) => {
      if (item.id === id) return item;
    });
    // console.log(data);
    setValue("id", data[0]?.id || "");
    setValue("name", data[0]?.name || 1);
    setValue("address", data[0]?.address || "");
    setValue("sector", data[0]?.sector || "");
    setValue("customerType", data[0]?.customerType);
    setValue("customerTypeId", data[0]?.customerTypeId || 0);
    setSelectedListObject({
      id: data[0].id,
      identifier: data[0].identifier,
      name: data[0].name,
      address: data[0].address,
      sector: data[0].sector,
      customerType: data[0].customerType,
      customerTypeId: data[0].customerTypeId,
    });

    toggle();
    setErrorState([]);
  };

  const Schema = yup.object().shape({
    isEditing: yup.boolean(),
    firstname: yup.string(),
    name: yup
      .string()
      .required("Klantnaam is verplicht")
      .test(
        "name-uniqueness",
        "Naam is al in gebruik, vul alstublieft de nieuwe naam in",
        (value) => {
          return !existingNames.some(
            (existingName) => existingName.name === value
          );
        }
      ),
    address: yup.string().required("Plaats is vereist"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const handleAddUserSubmit = async (formdata) => {
    let hasError = false;
    let errorColletion = [];
    if (formdata.name === "") {
      hasError = true;
      errorColletion = [...errorColletion, "name"];
    }

    if (formdata.address === "") {
      hasError = true;
      errorColletion = [...errorColletion, "address"];
    }

    if (formdata.sector === "") {
      hasError = true;
      errorColletion = [...errorColletion, "sector"];
    }

    if (formdata.customerTypeId === 0 || formdata.customerTypeId === "0") {
      hasError = true;
      errorColletion = [...errorColletion, "customerTypeId"];
    }

    if (!hasError) {
      setErrorState([]);
      try {
        // console.log(formdata);
        const requestObject = {
          identifier: selectedCustomerObject.identifier,
          name: formdata.name,
          address: formdata.address,
          sector: formdata.sector,
          customerTypeId: parseInt(formdata.customerTypeId),
        };

        const addRequestObject = {
          name: formdata.name,
          address: formdata.address,
          sector: formdata.sector,
          customerTypeId: parseInt(formdata.customerTypeId),
        };
        try {
          // console.log(requestObject, "12345");
          await CustomerService.setCustomerList(
            selectedCustomerObject.identifier ? requestObject : addRequestObject
          );
          toggle();

          {
            selectedCustomerObject?.id
              ? toast.success("De gegevens van de klant zijn aangepast.", {
                  position: toast.POSITION.TOP_RIGHT,
                })
              : toast.success("De gegevens van de klant zijn toegevoegd.", {
                  position: toast.POSITION.TOP_RIGHT,
                });
          }
          const result = await CustomerService.getCustomerList();
          setRows(result.response);
        } catch (error) {
          toast.error("Serverfout", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        toast.error("Serverfout", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setErrorState(errorColletion);
    }
    if (mainUserData) {
      setExistingNames(mainUserData);
    }
  };

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <ThemeProvider theme={outerTheme}>
      <React.Fragment>
        <Box sx={{ color: "#212F56", width: "100%" }}>
          <Paper sx={{ color: "#212F56", width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onClickAddTransaction={onClickAddTransaction}
              handleAddCustomer={handleAddCustomer}
              // handleEditCustomer={handleEditCustomer}
              // handleDeleteCustomer={handleDeleteCustomer}
              selected={selected}
            />
            <TableContainer>
              <Table
                sx={{ color: "#212F56", minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell> */}
                          {/* <TableCell
                            sx={{ color: "#212F56" }}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.id}
                          </TableCell> */}
                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            {row.name}
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            {row.address}
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            {row.sector}
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            {row.customerType}
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#212F56" }}>
                            <IconButton>
                              <Tooltip title="Klantgegevens aanpassen">
                                <EditIcon
                                  onClick={() => handleEditCustomer(row.id)}
                                  style={{ color: "#FF9E00" }}
                                />
                              </Tooltip>{" "}
                            </IconButton>
                            {user?.userType === UserRoles.admin && (
                              <IconButton>
                                <Tooltip title="Delete">
                                  <DeleteIcon
                                    onClick={() => handleDeleteCustomer(row.id)}
                                    style={{ color: "#FF9E00" }}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        color: "#212F56",
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{ color: "#212F56" }}
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rijen per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} van ${count !== -1 ? count : `meer dan ${to}`}`
              }
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Regelafstandg"
          />
        </Box>
        <CommonModal
          isOpen={modal}
          title="Klant"
          toggler={toggle}
          size="xl"
          displayNone={displayNone}
          showFooter={false}
        >
          <Row className="welkon-form-popup-main-div">
            <Col sm="10" className="d-flex flex-column justify-content-between">
              <Form
                className="welkon-form-popup-main"
                noValidate
                onSubmit={handleSubmit(handleAddUserSubmit)}
              >
                <FormGroup>
                  <Row>
                    <Col>
                      <FormTextField
                        label="Klantnaam"
                        className="form-control"
                        type="text"
                        name="name"
                        required={true}
                        errors={errors.name}
                        control={control}
                      />
                    </Col>
                    <Col>
                      <FormTextField
                        label="Plaats"
                        className="form-control"
                        required={true}
                        errors={errors.address}
                        type="text"
                        name="address"
                        control={control}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col>
                      <FormTextField
                        label="Sector"
                        className="form-control"
                        type="select"
                        name="sector"
                        required={true}
                        errors={errors.sector}
                        control={control}
                      />
                    </Col> */}
                    <Col>
                      <FormSelectField
                        label="Sector"
                        className="form-control"
                        type="select"
                        name="sector"
                        required={true}
                        control={control}
                        options={sectorTypes}
                      />
                      {errorState.includes("sector") && (
                        <div className="text-dark">*Sector is verplicht</div>
                      )}
                      {/* <Label className="form-label" for="sector">
                        Sector
                      </Label>
                      <select
                        className="form-select"
                        id="sector"
                        name="sector"
                        control={control}
                        onChange={handleSectorType}
                      >
                        <option value="">Selecteer</option> //key is needed
                        {sectorTypes.map((item) => {
                          return (
                            <option key={item.value} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      {errorState.includes("interestMethod") && (
                        <div>* sector is verplicht</div>
                      )} */}
                    </Col>
                    <Col>
                      <FormSelectField
                        label="Klanttype"
                        className="form-control"
                        type="select"
                        name="customerTypeId"
                        control={control}
                        options={customerTypes}
                      />
                      {errorState.includes("customerTypeId") && (
                        <div className="text-dark">*Klanttype is verplicht</div>
                      )}
                    </Col>
                  </Row>
                </FormGroup>
                <Row className="step1_header">
                  <Col className="right">
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "welkon-form-popup-btn-attr ",
                      }}
                    >
                      Opslaan
                    </Btn>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CommonModal>
      </React.Fragment>
    </ThemeProvider>
  );
};
