import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import FinancialTransactieChecklist from "../Transacties/FinancialTransactieChecklist";
import DatumenTijdstipForm from "../Transacties/DatumenTijdstipForm";
import FoAndFoAUserSelectionComponent from "../Transacties/FoAndFoAUserSelectionComponent";
import FinanciersForm from "../Transacties/FinanciersForm";
import { TransctiesService } from "utility";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "context/user";
import { toast } from "react-toastify";
import { DashboardService } from "utility/services/dashboard";
import { ResponsiveTables } from "Constant";
import BasicFormEdit from "./BasicFormEdit";
import ModaliteitenFormEdit from "./ModaliteitenFormEdit";
import RandvoorwaardenFormEdit from "./RandvoorwaardenFormEdit";
import DatumenTijdstipFormEdit from "./DatumenTijdstipFormEdit";
import FinanciersFormEdit from "./FinanciersFormEdit";
import FoAndFoAUserSelectionComponentEdit from "./FoAndFoAUserSelectionComponentEdit";
import FinancialTransactieChecklistEdit from "./FinancialTransactieChecklistEdit";

const TransactiesEdit = () => {
  const [transactionFields, setTransactionFields] = useState();
  const [editFields, setEditFields] = useState(null);
  const [autoSelect, setAutoSelect] = useState({
    Aflossingstype: "",
    AflossingstypeValue: "",
    Rentebasis: "",
    Renteconventie: "",
    dagtelling: "",
    dagtellingValue: "",
  });
  const { id } = useParams();
  // console.log("1", id);

  useEffect(() => {
    getTransactionFieldsData();
    getTransaction(id);
  }, []);

  const getTransactionFieldsData = async () => {
    const result = await TransctiesService.getTransactionFields();
    setTransactionFields(result.response);
    // console.log("transaction field", result);
  };
  const getTransaction = async (id) => {
    const response = await DashboardService.getTransaction(id);
    setEditFields(response?.response);
    // console.log("get edit field", response);
  };

  const [transection, setTransection] = useState({
    customerId: "",
    transactionNo: "",
    transactionType: "",
    transactionsubtype: "",
    transactionProposal: "",
    dealCode: "",
    leningnummer: "",
    principalAmount: "",
    depositDate: "",
    firstCoupon: "",
    loanEndDate: "",
    interestConverionDate: "",
    repaymentType: "",
    interestMethod: "",
    rollday: "",
    dayCount: "",
    convention: "",
    clause: "",
    guarantee: "",
    surchargeRevision: "",
    interestBasis: "",
    dealerUserId: "",
    wingmanUserId: "",
    transactionStartDateTime: "",
    transactionEndDateTime: "",
    endDate: "",
  });
  const [isEmailTemplateEnable, setEmailTemplateEnable] = useState(false);
  const [trainsactionId, setTransactionId] = useState(0);
  const [transactionTypeValue, setTransactionTypeValue] = useState("");
  const [transactionSubTypeValue, setTransactionSubTypeValue] = useState("");
  const [modaliteItensSel, setModaliteItensSel] = useState([
    "dealCode",
    "leningnummer",
    "principalAmount",
    "depositDate",
    "firstCoupon",
    "loanEndDate",
    "interestConverionDate",
    "repaymentType",
    "interestMethod",
    "rollday",
    "dayCount",
    "convention",
    "guarantee",
    "surchargeRevision",
    "clause",
    "interestBasis",
  ]);
  const [modaliteItens, setModaliteItens] = useState("");
  const [financialTranChecklistSel, setFinancialTranChecklistSel] = useState(
    []
  );
  const [financialTypeIds, setFinancialTypeIds] = useState([]);
  const [financiersTranChecklistSel, setFinanciersTranChecklistSel] = useState(
    []
  );
  const [randvoorwaarden, setRandvoorwaarden] = useState([]);
  const [errorState, setErrorState] = useState([]);
  const [isEditable, setIsEditable] = useState(true);

  const navigate = useNavigate();

  const doVerifyTransData = (collection) => {
    let errorStateMGT = [];
    if (collection?.customerId === "") {
      errorStateMGT = [...errorStateMGT, "customerId"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "customerId";
      });
    }

    if (collection?.transactionNo === "") {
      errorStateMGT = [...errorStateMGT, "transactionNo"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionNo";
      });
    }

    if (collection?.transactionType === "") {
      errorStateMGT = [...errorStateMGT, "transactionType"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionType";
      });
    }

    if (collection?.transactionsubtype === "") {
      errorStateMGT = [...errorStateMGT, "transactionsubtype"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionsubtype";
      });
    }

    if (collection?.transactionProposal === "") {
      errorStateMGT = [...errorStateMGT, "transactionProposal"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionProposal";
      });
    }

    if (collection?.dealCode === "") {
      errorStateMGT = [...errorStateMGT, "dealCode"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "dealCode";
      });
    }

    // if (collection?.leningnummer === '') {
    //   errorStateMGT = ([...errorStateMGT, 'leningnummer']);
    // } else {
    //   errorStateMGT = (errorStateMGT.filter(obj => { return obj !== 'leningnummer' }));
    // }

    if (collection?.principalAmount === "") {
      errorStateMGT = [...errorStateMGT, "principalAmount"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "principalAmount";
      });
    }

    if (collection?.depositDate === "") {
      errorStateMGT = [...errorStateMGT, "depositDate"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "depositDate";
      });
    }

    if (collection?.firstCoupon === "") {
      errorStateMGT = [...errorStateMGT, "firstCoupon"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "firstCoupon";
      });
    }

    if (collection?.loanEndDate === "") {
      errorStateMGT = [...errorStateMGT, "loanEndDate"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "loanEndDate";
      });
    }

    if (collection?.interestConverionDate === "") {
      errorStateMGT = [...errorStateMGT, "interestConverionDate"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "interestConverionDate";
      });
    }

    if (collection?.repaymentType === "") {
      errorStateMGT = [...errorStateMGT, "repaymentType"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "repaymentType";
      });
    }

    if (collection?.interestMethod === "") {
      errorStateMGT = [...errorStateMGT, "interestMethod"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "interestMethod";
      });
    }

    if (collection?.rollday === "") {
      errorStateMGT = [...errorStateMGT, "rollday"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "rollday";
      });
    }

    if (collection?.dayCount === "") {
      errorStateMGT = [...errorStateMGT, "dayCount"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "dayCount";
      });
    }

    if (collection?.convention === "") {
      errorStateMGT = [...errorStateMGT, "convention"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "convention";
      });
    }

    if (collection?.clause === "") {
      errorStateMGT = [...errorStateMGT, "clause"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "clause";
      });
    }

    if (collection?.guarantee === "") {
      errorStateMGT = [...errorStateMGT, "guarantee"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "guarantee";
      });
    }

    if (collection?.surchargeRevision === "") {
      errorStateMGT = [...errorStateMGT, "surchargeRevision"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "surchargeRevision";
      });
    }

    if (collection?.interestBasis === "") {
      errorStateMGT = [...errorStateMGT, "interestBasis"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "interestBasis";
      });
    }

    if (collection?.dealerUserId === "") {
      errorStateMGT = [...errorStateMGT, "dealerUserId"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "dealerUserId";
      });
    }

    if (collection?.transactionStartDateTime === "") {
      errorStateMGT = [...errorStateMGT, "transactionStartDateTime"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionStartDateTime";
      });
    }

    if (collection?.transactionEndDateTime === "") {
      errorStateMGT = [...errorStateMGT, "transactionEndDateTime"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionEndDateTime";
      });
    }

    if (collection?.wingmanUserId === "") {
      errorStateMGT = [...errorStateMGT, "wingmanUserId"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "wingmanUserId";
      });
    }

    if (financiersTranChecklistSel.length === 0) {
      errorStateMGT = [...errorStateMGT, "financiersTranChecklistSel"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "financiersTranChecklistSel";
      });
    }

    setErrorState(errorStateMGT);
    return errorStateMGT;
  };

  const { user } = useAuth();

  const onSetTransaction = (event) => {
    setTransection({
      ...transection,
      listOfTask: financialTranChecklistSel,
      listOfFinancier: financiersTranChecklistSel,
    });
    const verified = doVerifyTransData({
      ...transection,
      listOfTask: financialTranChecklistSel,
      listOfFinancier: financiersTranChecklistSel,
    });

    const postObject = {
      //Basic form map
      customerId: transection.customerId ? parseInt(transection.customerId) : 0,
      transactionNo: transection.transactionNo,
      userId: user?.userId,
      transactionType: transection.transactionType
        ? parseInt(transection.transactionType)
        : 0,
      transactionProposal: transection.transactionProposal,
      dealCode: transection.dealCode,
      firstCoupon: transection.firstCoupon,
      principalAmount: transection.principalAmount
        ? parseInt(transection.principalAmount)
        : 0,
      depositDate: transection.depositDate,
      transactionId: 0,
      loanEndDate: transection.loanEndDate,
      interestConverionDate: transection.interestConverionDate,
      repaymentType: transection.repaymentType
        ? parseInt(transection.repaymentType)
        : 0,
      interestMethod: transection.interestMethod,
      rollday: transection.rollday,
      dayCount: transection.dayCount ? parseInt(transection.dayCount) : 0,
      convention: transection.convention ? parseInt(transection.convention) : 0,
      guarantee: transection.guarantee ? parseInt(transection.guarantee) : 0,
      surchargeRevision: transection.surchargeRevision,
      clause: transection.clause ? parseInt(transection.clause) : 0,
      interestBasis: transection.interestBasis
        ? parseInt(transection.interestBasis)
        : 0,
      transactionStartDateTime: transection?.transactionStartDateTime,
      transactionEndDateTime: transection?.transactionEndDateTime,
      dealerUserId: parseInt(transection?.dealerUserId),
      wingmanUserId: parseInt(transection?.wingmanUserId),
      checklistToSave: financialTranChecklistSel,
      financiersToSave: financiersTranChecklistSel,
      conditions: randvoorwaarden,
    };
    console.log(postObject);
    // setTansactionAction(postObject);
  };

  const setTansactionAction = async (postObject) => {
    try {
      const resp = await TransctiesService.setTransaction(postObject);

      if (resp.status) {
        toast.success("transactie succesvol toegevoegd", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTransactionId(resp.message);
        setEmailTemplateEnable(true);
      } else {
        toast.error("Er is iets mis met de server", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      // navigate(`/dashboard/InputBoxPopUp/FinanciersMail`);
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("error", error);
    }
  };

  const selectAuto = (data) => {
    const {
      Aflossingstype,
      AflossingstypeValue,
      Rentebasis,
      Renteconventie,
      dagtelling,
      dagtellingValue,
    } = data;

    setAutoSelect({
      Aflossingstype,
      AflossingstypeValue,
      Rentebasis,
      Renteconventie,
      dagtelling,
      dagtellingValue,
    });
  };

  return (
    <Fragment>
      {/* <Breadcrumbs
            mainTitle="Transactie Toevoegen"
            parent="Forms"
            title="Transactie Toevoegen"
            subParent="Forms Controls"
          /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <h4 className="font-w800">Transacties </h4>
            <BasicFormEdit
              transactionTypeValue={transactionTypeValue}
              editFields={editFields}
              setTransactionTypeValue={setTransactionTypeValue}
              transactionSubTypeValue={transactionSubTypeValue}
              setTransactionSubTypeValue={setTransactionSubTypeValue}
              setModaliteItensSel={setModaliteItensSel}
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              setErrorState={setErrorState}
              transactionFields={transactionFields}
              selectAuto={selectAuto}
            />

            <ModaliteitenFormEdit
              editFields={editFields}
              transactionSubTypeValue={modaliteItens}
              setModaliteItens={setModaliteItens}
              modaliteItensSel={modaliteItensSel}
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              setErrorState={setErrorState}
              transactionFields={transactionFields}
              autoSelect={autoSelect}
            />
            <FinancialTransactieChecklistEdit
              transactionTypeValue={transactionTypeValue}
              transection={transection}
              financialTranChecklistSel={financialTranChecklistSel}
              setFinancialTranChecklistSel={setFinancialTranChecklistSel}
            />
            <RandvoorwaardenFormEdit
              setRandvoorwaarden={setRandvoorwaarden}
              randvoorwaarden={randvoorwaarden}
            />
            <DatumenTijdstipFormEdit
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              setErrorState={setErrorState}
            />
            <FinanciersFormEdit
              financiersTranChecklistSel={financiersTranChecklistSel}
              setFinanciersTranChecklistSel={setFinanciersTranChecklistSel}
              setFinancialTypeIds={setFinancialTypeIds}
              errorState={errorState}
              transactionFields={transactionFields}
              editFields={editFields}
            />
            <FoAndFoAUserSelectionComponentEdit
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              transactionFields={transactionFields}
              editFields={editFields}
            />
            <div className="mb-5">
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "mb-5 float-end",
                  onClick: (e) => onSetTransaction(e),
                }}
              >
                Opslaan en uitvraag versturen
              </Btn>
            </div>
          </Col>
        </Row>
      </Container>
      {/* {isEmailTemplateEnable && (
        <TransactionMail
          financialTypeIds={financialTypeIds}
          transection={transection}
          trainsactionId={trainsactionId}
          isEnable={isEmailTemplateEnable}
        ></TransactionMail>
      )} */}
    </Fragment>
  );
};

export default TransactiesEdit;
