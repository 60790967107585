import { H5 } from "../../../AbstractElements";
import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";

export const RandvoorwaardenFullScreenView = ({ conditions }) => {
  var settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    speed: 100,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    adaptiveHeight: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Card>
      <CardBody>
        <div className="item">
          <Row>
            <Col>
              {conditions.length > 0 ? (
                <div>
                  {" "}
                  {conditions.map((item) => (
                    <div
                      className="item"
                      key={item}
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      <div
                        className="top-dealerbox"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        <H5
                          style={{ whiteSpace: "break-spaces" }}
                          className="font-105rem"
                        >
                          {item}
                        </H5>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="item" style={{ whiteSpace: "break-spaces" }}>
                  <div
                    className="top-dealerbox"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    <H5
                      className="font-105rem"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      Er zijn geen randvoorwaarden toegevoegd
                    </H5>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default RandvoorwaardenFullScreenView;
