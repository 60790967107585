import { Home } from "react-feather";
import { UserRoles } from "utility";

export const MENUITEMS = [
  {
    menutitle: "Navigatie",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        path: `admin-dashboard`,
        title: "Dashboard",
        type: "link",
        roles: [UserRoles.admin],
      },
      {
        path: `transacties`,
        title: "Transacties ",
        type: "link",
        roles: [UserRoles.admin],
      },
      {
        path: `financiers`,
        title: "Financiers",
        type: "link",
        roles: [UserRoles.admin],
      },
      {
        path: `users`,
        title: "Werknemers",
        type: "link",
        roles: [UserRoles.admin],
      },
      {
        path: `customer-management`,
        title: "Klanten",
        type: "link",
        roles: [UserRoles.admin],
      },
      {
        path: `financiers-dashboard`,
        title: "Dashboard",
        type: "link",
        roles: [UserRoles.financier],
      },
      {
        path: `transacties`,
        title: "Transacties ",
        type: "link",
        roles: [UserRoles.financier],
      },



      {
        path: `employee-dashboard`,
        title: "Dashboard",
        type: "link",
        roles: [UserRoles.employee],
      },
      {
        path: `transacties`,
        title: "Transacties ",
        type: "link",
        roles: [UserRoles.employee],
      },
      {
        path: `financiers`,
        title: "Financiers",
        type: "link",
        roles: [UserRoles.employee],
      },


      // {
      //   path: `admin-dashboard`,
      //   title: "Beheerder - dashboard",
      //   type: "link",
      //   roles: [UserRoles.admin],
      // },
      // {
      //   path: `employee-dashboard`,
      //   title: "Werknemer - dashboard",
      //   type: "link",
      //   roles: [UserRoles.employee],
      // },
      // {
      //   path: `financiers-dashboard`,
      //   title: "Financiers",
      //   type: "link",
      //   roles: [UserRoles.admin, UserRoles.financier],
      // },
      // {
      //   path: `transacties/dashboard/10`,
      //   title: "Transactions - dashboard",
      //   type: "link",
      //   roles: [],
      // },
      // {
      //   path: `transactions`,
      //   title: "Transacties ",
      //   type: "link",
      //   roles: [],
      // },
      // {
      //   path: `transacties/add`,
      //   title: "Transactie Toevoegen ",
      //   type: "link",
      //   roles: [],
      // },

      // {
      //   path: `completed-transactions`,
      //   title: "Transacties (voltooid) ",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/InputBoxPopUp/welkom`,
      //   title: "Welkom ",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/InputBoxPopUp/transactietoken`,
      //   title: "TransactieToken ",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/InputBoxPopUp/financiersmail`,
      //   title: "FinanciersMail",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/InputBoxPopUp/TransactieToekennen`,
      //   title: "Transactie Toekennen",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/InputBoxPopUp/financiermail`,
      //   title: "FinancierMail ",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/InputBoxPopUp/gegevens`,
      //   title: "Gegevens ",
      //   type: "link",
      // },
      // {
      //   path: `/dashboard/dashboardlinechart`,
      //   title: "DashboardLineChart",
      //   type: "link",
      // },
      // {
      //   path: `users`,
      //   title: "Gebruikerslijst",
      //   type: "link",
      //   roles: [UserRoles.admin],
      // },
    ],
  },
];
