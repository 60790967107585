import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { BubbleChart } from "../../../Constant";
import HeaderCard from "../../Common/Component/HeaderCard";
import Chart from "react-apexcharts";
import { apex3DbubbleCharts } from "./apexData";
import { DashboardService } from "utility/services/dashboard";
import { get } from "react-hook-form";
import configDB from "../../../Config/ThemeConfig";

const primary =
  localStorage.getItem("default_color") || configDB.data.color.primary_color;
const secondary =
  localStorage.getItem("secondary_color") ||
  configDB.data.color.secondary_color;

const BubbleChartClass = ({ title }) => {
  const [series, setSeries] = useState([]);
  useEffect(() => {
    getTransaction();
  }, []);
  const getTransaction = async () => {
    const response = await DashboardService.getChartData();
    const seriesData = [];
    response?.response.map((item) => {
      let collection = [];
      if (item?.data.length != 0) {
        item.data.map((_item) => {
          collection.push([_item.year, _item.percentage, 5]);
        });
        seriesData.push({
          name: item.name,
          data: collection,
        });
      } else {
        seriesData.push({
          name: item.name,
          data: [[0, 0, 5]],
        });
      }
    });
    setSeries(seriesData);
  };

  const getSeriesName = (index) => {
    return series[index].name;
  };

  const getSeriesYear = (index, dataIndex) => {
    return series[index]?.data[dataIndex][0];
  };

  const apex3DbubbleCharts = {
    options: {
      chart: {
        height: 350,
        type: "bubble",
        toolbar: {
          show: true,
        },
        events: {
          dataPointSelection: function (e, chart, opt) {
            const tooltipEl = opt.w.globals.tooltip.getElTooltip();

            if (opt.selectedDataPoints[0].length) {
              tooltipEl.classList.add("apexcharts-tooltip-active");
            } else {
              tooltipEl.classList.remove("apexcharts-tooltip-active");
            }
          },
        },
      },

      tooltip: {
        shared: false,
        intersect: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="box-col-12 des-xl-25 rate-sec box-secondary col-sm-12 col-md-12 col-xl-12"><div class="income-card card-primary card"><div class="text-left card-body">' +
            "<div {padding:50px}>" +
            "  <h6>Aflossingstype:</h6>" +
            getSeriesName(seriesIndex) +
            "  <br /> <br />" +
            "  <h6>Opslag:</h6>" +
            series[seriesIndex][dataPointIndex] +
            "  <br /> <br />" +
            "  <h6>Aantal jaren:</h6>" +
            getSeriesYear(seriesIndex, dataPointIndex) +
            "  <br /> " +
            "</div>" +
            "</div></div></div>"
          );
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "solid",
      },
      title: {
        text: "",
      },
      xaxis: {
        tickAmount: 10,
        type: "int",
        min: 0,
        max: 50,
        labels: {
          rotate: 0,
        },
      },
      colors: ["#212f56", "#636d88", "#ffaa20", "#ffc15c"],
      yaxis: {
        max: 15,
      },
      theme: {
        palette: "palette",
      },
    },
  };

  return (
    <Fragment>
      <Col sm="12" xl="12">
        <Card>
          <HeaderCard title={title} />
          <CardBody>
            <div id="chart-bubble">
              <Chart
                options={apex3DbubbleCharts.options}
                series={series}
                type="bubble"
                height={350}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default BubbleChartClass;
