import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import BasicForm from "./BasicForm";
import ModaliteitenForm from "./ModaliteitenForm";
import FinancialTransactieChecklist from "./FinancialTransactieChecklist";
import RandvoorwaardenForm from "./RandvoorwaardenForm";
import DatumenTijdstipForm from "./DatumenTijdstipForm";
import FoAndFoAUserSelectionComponent from "./FoAndFoAUserSelectionComponent";
import FinanciersForm from "./FinanciersForm";
import { TransctiesService } from "utility";
import { useNavigate } from "react-router-dom";
import { TransactionMail } from "./TransactionMail";
import { useAuth } from "context/user";
import { toast } from "react-toastify";
import { ItemBullets } from "Constant";
import CommonModal from "Components/UiKits/Modals/common/modal";
import EmployeeDisplayForm from "./EmployeeDisplayForm";

const Transacties = () => {
  const [transactionFields, setTransactionFields] = useState();
  const [autoSelect, setAutoSelect] = useState({
    Aflossingstype: "",
    AflossingstypeValue: "",
    Rentebasis: "",
    RenteBasisValue: "",
    interestMethod: "",
    Renteconventie: "",
    dagtelling: "",
    dagtellingValue: "",
    clause: "",
  });

  useEffect(() => {
    getTransactionFieldsData();
  }, []);

  const getTransactionFieldsData = async () => {
    const result = await TransctiesService.getTransactionFields();
    setTransactionFields(result.response);
  };

  const [transection, setTransection] = useState({
    customerId: "",
    currentFinancierId: "",
    transactionNo: "",
    transactionType: "",
    transactionsubtype: "",
    transactionProposal: "",
    dealCode: "",
    duration: "",
    leningnummer: "",
    principalAmount: "",
    depositDate: "",
    firstCoupon: "",
    loanEndDate: "",
    interestConverionDate: "",
    repaymentType: "",
    interestMethod: "",
    rollday: "",
    dayCount: "",
    convention: "",
    clause: "",
    guarantee: "",
    surchargeRevision: "",
    interestBasis: "",
    dealerUserId: "",
    wingmanUserId: "",
    transactionStartDateTime: "",
    transactionEndDateTime: "",
    endDate: "",
  });

  const [isAddTransaction] = useState(true);
  const [modal, setModal] = useState(false);
  const [trainsactionId, setTransactionId] = useState(0);
  const [transactionTypeValue, setTransactionTypeValue] = useState("");
  const [transactionSubTypeValue, setTransactionSubTypeValue] = useState("1");

  const [modaliteItensSel, setModaliteItensSel] = useState([
    "dealCode",
    "duration",
    "leningnummer",
    "principalAmount",
    "depositDate",
    "firstCoupon",
    "loanEndDate",
    "interestConverionDate",
    "repaymentType",
    "interestMethod",
    "rollday",
    "dayCount",
    "convention",
    "guarantee",
    "surchargeRevision",
    "clause",
    "interestBasis",
  ]);
  const [modaliteItens, setModaliteItens] = useState("");
  const [financialTranChecklistSel, setFinancialTranChecklistSel] = useState(
    []
  );
  const [financialTypeIds, setFinancialTypeIds] = useState([]);
  const [financiersTranChecklistSel, setFinanciersTranChecklistSel] = useState(
    []
  );
  const [randvoorwaarden, setRandvoorwaarden] = useState([]);
  const [errorState, setErrorState] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [transactionObj, setTransactionObj] = useState([]);
  const [loanDuration, setLoanDuration] = useState(0);

  const isNextButtonEnable = false;

  const navigate = useNavigate();

  const checklistSelect = (checklist) => {};

  const doVerifyTransData = (collection) => {
    let errorStateMGT = [];
    if (collection?.customerId === "") {
      errorStateMGT = [...errorStateMGT, "customerId"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "customerId";
      });
    }

    if (collection?.transactionNo === "") {
      errorStateMGT = [...errorStateMGT, "transactionNo"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionNo";
      });
    }

    if (collection?.transactionType === "") {
      errorStateMGT = [...errorStateMGT, "transactionType"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionType";
      });
    }

    if (collection?.transactionsubtype === "") {
      errorStateMGT = [...errorStateMGT, "transactionsubtype"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionsubtype";
      });
    }

    if (collection?.transactionProposal === "") {
      errorStateMGT = [...errorStateMGT, "transactionProposal"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionProposal";
      });
    }

    if (collection?.dealCode === "") {
      errorStateMGT = [...errorStateMGT, "dealCode"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "dealCode";
      });
    }

    // if (collection?.leningnummer === '') {
    //   errorStateMGT = ([...errorStateMGT, 'leningnummer']);
    // } else {
    //   errorStateMGT = (errorStateMGT.filter(obj => { return obj !== 'leningnummer' }));
    // }

    if (collection?.principalAmount === "") {
      errorStateMGT = [...errorStateMGT, "principalAmount"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "principalAmount";
      });
    }

    if (collection?.depositDate === "") {
      errorStateMGT = [...errorStateMGT, "depositDate"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "depositDate";
      });
    }

    if (collection?.firstCoupon === "") {
      errorStateMGT = [...errorStateMGT, "firstCoupon"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "firstCoupon";
      });
    }

    if (collection?.loanEndDate === "") {
      errorStateMGT = [...errorStateMGT, "loanEndDate"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "loanEndDate";
      });
    }

    // if (collection?.interestConverionDate === "") {
    //   errorStateMGT = [...errorStateMGT, "interestConverionDate"];
    // } else {
    //   errorStateMGT = errorStateMGT.filter((obj) => {
    //     return obj !== "interestConverionDate";
    //   });
    // }

    if (collection?.repaymentType === "") {
      errorStateMGT = [...errorStateMGT, "repaymentType"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "repaymentType";
      });
    }

    if (collection?.interestMethod === "Selecteer") {
      errorStateMGT = [...errorStateMGT, "interestMethod"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "interestMethod";
      });
    }

    if (collection?.rollday === "") {
      errorStateMGT = [...errorStateMGT, "rollday"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "rollday";
      });
    }

    if (collection?.dayCount === "") {
      errorStateMGT = [...errorStateMGT, "dayCount"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "dayCount";
      });
    }

    if (collection?.convention === "") {
      errorStateMGT = [...errorStateMGT, "convention"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "convention";
      });
    }

    // if (collection?.clause === "") {
    //   errorStateMGT = [...errorStateMGT, "clause"];
    // } else {
    //   errorStateMGT = errorStateMGT.filter((obj) => {
    //     return obj !== "clause";
    //   });
    // }

    // if (collection?.guarantee === "") {
    //   errorStateMGT = [...errorStateMGT, "guarantee"];
    // } else {
    //   errorStateMGT = errorStateMGT.filter((obj) => {
    //     return obj !== "guarantee";
    //   });
    // }

    if (collection?.surchargeRevision === "") {
      errorStateMGT = [...errorStateMGT, "surchargeRevision"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "surchargeRevision";
      });
    }

    // if (collection?.interestBasis === "") {
    //   errorStateMGT = [...errorStateMGT, "interestBasis"];
    // } else {
    //   errorStateMGT = errorStateMGT.filter((obj) => {
    //     return obj !== "interestBasis";
    //   });
    // }

    if (collection?.dealerUserId === "") {
      errorStateMGT = [...errorStateMGT, "dealerUserId"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "dealerUserId";
      });
    }

    if (collection?.transactionStartDateTime === "") {
      errorStateMGT = [...errorStateMGT, "transactionStartDateTime"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionStartDateTime";
      });
    }

    if (collection?.transactionEndDateTime === "") {
      errorStateMGT = [...errorStateMGT, "transactionEndDateTime"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "transactionEndDateTime";
      });
    }

    if (collection?.wingmanUserId === "") {
      errorStateMGT = [...errorStateMGT, "wingmanUserId"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "wingmanUserId";
      });
    }

    if (financiersTranChecklistSel.length === 0) {
      errorStateMGT = [...errorStateMGT, "financiersTranChecklistSel"];
    } else {
      errorStateMGT = errorStateMGT.filter((obj) => {
        return obj !== "financiersTranChecklistSel";
      });
    }

    setErrorState(errorStateMGT);

    return errorStateMGT;
  };
  const { user } = useAuth();

  const onSetTransaction = (event) => {
    setTransection({
      ...transection,
      // duration: parseInt(loanDuration),
      listOfTask: financialTranChecklistSel,
      listOfFinancier: financiersTranChecklistSel,
    });
    const verified = doVerifyTransData({
      ...transection,
      // duration: parseInt(loanDuration),
      listOfTask: financialTranChecklistSel,
      listOfFinancier: financiersTranChecklistSel,
    });

    const postObject = {
      //Basic form map
      customerId: transection.customerId ? parseInt(transection.customerId) : 0,
      currentFinancierId: transection.currentFinancierId
        ? parseInt(transection.currentFinancierId)
        : null,
      transactionNo: transection.transactionNo,
      userId: user?.userId,
      transactionType: transection.transactionType
        ? parseInt(transection.transactionType)
        : 0,
      transactionProposal: transection.transactionProposal,
      dealCode: transection.dealCode,
      firstCoupon: transection.firstCoupon,
      principalAmount: transection.principalAmount
        ? parseInt(transection.principalAmount)
        : 0,
      depositDate: transection.depositDate,
      transactionId: 0,
      loanEndDate: transection.loanEndDate,
      loanDuration: parseInt(transection.duration),
      interestConverionDate: transection.interestConverionDate,
      repaymentType: transection.repaymentType
        ? parseInt(transection.repaymentType)
        : 0,
      interestMethod: transection.interestMethod,
      rollday: transection.rollday,
      dayCount: transection.dayCount ? parseInt(transection.dayCount) : 0,
      convention: transection.convention ? parseInt(transection.convention) : 0,
      guarantee: transection.guarantee ? parseInt(transection.guarantee) : 0,
      surchargeRevision: transection.surchargeRevision,
      clause: transection.clause ? parseInt(transection.clause) : 0,
      interestBasis: transection.interestBasis ? transection.interestBasis : 0,
      transactionStartDateTime: transection?.transactionStartDateTime,
      transactionEndDateTime: transection?.transactionEndDateTime,
      dealerUserId: parseInt(transection?.dealerUserId),
      wingmanUserId: parseInt(transection?.wingmanUserId),
      checklistToSave: financialTranChecklistSel,
      financiersToSave: financiersTranChecklistSel,
      conditions: randvoorwaarden,
      transactionSubType: transection?.transactionsubtype,
      transactionSubTypeValue: transection?.transactionsubtype,
    };

    // we can igore
    //
    // 0:
    // 'dealCode'
    // 1:
    // 'clause'
    // 2:
    // 'surchargeRevision'
    // 3:
    // 'interestBasis
    if (verified?.length < 4) {
      setTransactionObj(postObject);

      toggle();
    }
  };
  const toggle = () => {
    setModal(!modal);
  };

  const setTansactionAction = async (postObject) => {
    try {
      const resp = await TransctiesService.setTransaction(postObject);
      if (resp.status) {
        toast.success("transactie succesvol toegevoegd", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTransactionId(resp.response);
        setModal(true);
      } else {
        toast.error("Er is iets mis met de server", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // navigate(`/dashboard/InputBoxPopUp/FinanciersMail`);
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log("error", error);
    }
  };

  const selectAuto = (data) => {
    const {
      Aflossingstype,
      AflossingstypeValue,
      Rentebasis,
      RenteBasisValue,
      interestMethod,
      Renteconventie,
      dagtelling,
      dagtellingValue,
      clause,
    } = data;

    setAutoSelect({
      Aflossingstype,
      AflossingstypeValue,
      Rentebasis,
      RenteBasisValue,
      interestMethod,
      Renteconventie,
      dagtelling,
      dagtellingValue,
      clause,
    });
  };
  // console.log(transection)
  return (
    <Fragment>
      {/* <Breadcrumbs
        mainTitle="Transactie Toevoegen"
        parent="Forms"
        title="Transactie Toevoegen"
        subParent="Forms Controls"
      /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <h4 className="font-w800">Transacties </h4>
            <BasicForm
              transactionTypeValue={transactionTypeValue}
              setTransactionTypeValue={setTransactionTypeValue}
              transactionSubTypeValue={transactionSubTypeValue}
              setTransactionSubTypeValue={setTransactionSubTypeValue}
              setModaliteItensSel={setModaliteItensSel}
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              setErrorState={setErrorState}
              transactionFields={transactionFields}
              selectAuto={selectAuto}
            />
            <ModaliteitenForm
              transactionSubTypeValue={modaliteItens}
              setModaliteItens={setModaliteItens}
              modaliteItensSel={modaliteItensSel}
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              setErrorState={setErrorState}
              transactionFields={transactionFields}
              autoSelect={autoSelect}
              loanDuration={loanDuration}
              setLoanDuration={setLoanDuration}
            />

            <RandvoorwaardenForm
              setRandvoorwaarden={setRandvoorwaarden}
              randvoorwaarden={randvoorwaarden}
            />
            <DatumenTijdstipForm
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
              setErrorState={setErrorState}
              loanDuration={loanDuration}
            />
            <FinanciersForm
              financiersTranChecklistSel={financiersTranChecklistSel}
              setFinanciersTranChecklistSel={setFinanciersTranChecklistSel}
              setFinancialTypeIds={setFinancialTypeIds}
              errorState={errorState}
              isNextButtonEnable={isNextButtonEnable}
              checklistSelect={checklistSelect}
              transaction={transection}
            />
            <FoAndFoAUserSelectionComponent
              transection={transection}
              setTransection={setTransection}
              errorState={errorState}
            />
            <FinancialTransactieChecklist
              transactionTypeValue={transactionTypeValue}
              transection={transection}
              financialTranChecklistSel={financialTranChecklistSel}
              setFinancialTranChecklistSel={setFinancialTranChecklistSel}
            />
            <div className="mb-5">
              <Btn
                attrBtn={{
                  color: "primary",
                  className: "mb-5 float-end",
                  onClick: (e) => onSetTransaction(e),
                  // onClick: () => setModal(true),
                }}
              >
                Opslaan en uitvraag versturen
              </Btn>
            </div>
          </Col>
        </Row>
      </Container>
      {/* {isEmailTemplateEnable && (
        <TransactionMail
          financialTypeIds={financialTypeIds}
          transection={transection}
          trainsactionId={trainsactionId}
          isEnable={isEmailTemplateEnable}
          isAddTransaction={isAddTransaction}
          financiersTranChecklistSel={financiersTranChecklistSel}
          // selectedFinancerObject={selectedFinancerObject}
          // setSubpopupOpen={setSubpopupOpen}
        ></TransactionMail>
      )} */}
      {financiersTranChecklistSel.length > 0 && (
        <CommonModal
          isOpen={modal}
          title="Selecteer Contactpersonen"
          toggler={toggle}
          size="xl"
          // displayNone={displayNone}
          showFooter={false}
        >
          <EmployeeDisplayForm
            financialTypeIds={financialTypeIds}
            transection={transection}
            trainsactionId={trainsactionId}
            isAddTransaction={isAddTransaction}
            financiersTranChecklistSel={financiersTranChecklistSel}
            setSelectedEmployees={setSelectedEmployees}
            setTansactionAction={setTansactionAction}
            transactionObj={transactionObj}
            // selectedFinancerObject={selectedFinancerObject}
            // setSubpopupOpen={setSubpopupOpen}
          ></EmployeeDisplayForm>
        </CommonModal>
      )}
    </Fragment>
  );
};

export default Transacties;
