import React, { Fragment, useState, useEffect } from "react";
import { DangerText } from "Components/Common/Comman";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
// import Select from 'react-select'

const ModaliteitenFormEdit = (ModaliteitenData) => {
  const [intrestMethodFields, setintrestMethodFields] = useState([
    { name: "wekelijks", value: "1" },
    { name: "maandelijks", value: "2" },
    { name: "per kwartaal", value: "3" },
    { name: "halfjaarlijks", value: "4" },
    { name: "jaarlijks", value: "5" },
  ]);

  const {
    transection,
    setTransection,
    errorState,
    setErrorState,
    transactionFields,
    autoSelect,
    editFields,
  } = ModaliteitenData;
  // console.log("transactionFields", transactionFields);
  // console.log("editFields", editFields);
  console.log("transection", transection);
  const [counter, setCounter] = useState(0);
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    if (editFields !== null) {
      const principal = editFields.principalAmount;
      setAmount(principal);
    }
  }, [editFields]);

  const handleHuidigeGeldgever = (e) => {
    setTransection({ ...transection, huidigegeldgever: e.target.value });
  };

  const handleDealcode = (e) => {
    setTransection({ ...transection, dealCode: e.target.value });
  };

  const handleLeningnummer = (e) => {
    setTransection({ ...transection, leningnummer: e.target.value });
  };

  const handleHoofdsom = (e) => {
    setAmount(e.target.value);
    setTransection({ ...transection, principalAmount: e.target.value });
  };

  const handleStortingsdatum = (e) => {
    setTransection({ ...transection, depositDate: e.target.value });
  };

  const handleECoupon = (e) => {
    setTransection({ ...transection, firstCoupon: e.target.value });
  };

  const handleEinddatum = (e) => {
    setTransection({ ...transection, loanEndDate: e.target.value });
    console.log(e.target.value);
  };

  const handleRenteconversiedatum = (e) => {
    setTransection({ ...transection, interestConverionDate: e.target.value });
  };

  const handleAflossingstype = (e) => {
    setTransection({ ...transection, repaymentType: e.target.value });
  };

  const handleRentemethode = (e) => {
    setTransection({ ...transection, interestMethod: e.target.value });
  };

  const handleRoldag = (e) => {
    setTransection({ ...transection, rollday: e.target.value });
  };

  const handleDagtelling = (e) => {
    setTransection({ ...transection, dayCount: e.target.value });
  };

  const handleConventie = (e) => {
    setTransection({ ...transection, convention: e.target.value });
  };

  const handleGarantie = (e) => {
    setTransection({ ...transection, guarantee: e.target.value });
  };

  const handleOpslagherziening = (e) => {
    setTransection({ ...transection, surchargeRevision: e.target.value });
  };

  const handleClausule = (e) => {
    setTransection({ ...transection, clause: e.target.value });
  };

  const handleRentebasis = (e) => {
    setTransection({ ...transection, interestBasis: e.target.value });
  };

  useEffect(() => {
    setTransection({
      ...transection,
      repaymentType: autoSelect?.AflossingstypeValue,
      dayCount: autoSelect?.dagtellingValue,
      convention: "1",
      interestBasis: "5",
    });
  }, [
    transection.transactionsubtype,
    autoSelect?.AflossingstypeValue,
    autoSelect?.dagtellingValue,
  ]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const getInterestMethodName = (value) => {
    const objIdx = intrestMethodFields.findIndex(
      (item) => item.value === value
    );
    const objName = intrestMethodFields[objIdx].name;
    return objName;
  };

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">ModaliteitenForm lening</h5>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="g-3">
              {ModaliteitenData?.modaliteItensSel?.includes(
                "huidigegeldgever"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="huidigegeldgever">
                    Huidige geldgever
                  </Label>
                  <select
                    name="huidigegeldgever"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleHuidigeGeldgever}
                  >
                    <option value="">Selecteer </option>
                    <option>BNG Bank</option>
                    <option>NWB Bank</option>
                    <option>Rabobank</option>
                    <option>ING Bank</option>
                    <option>ABN AMRO Bank</option>
                  </select>
                  {errorState.includes("huidigegeldgever") && (
                    <div>* Huidige geldgever is vereist</div>
                  )}
                </Col>
              )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes("dealCode") && (
                  <Col md="4">
                    <Label className="form-label" for="">
                      Dealcode
                    </Label>
                    <input
                      onChange={handleDealcode}
                      value={editFields.dealCode}
                      className="form-control"
                      id="dealCode"
                      type="text"
                      name="dealCode"
                    />
                    {errorState.includes("dealCode") && (
                      <div>*Dealcode is vereist</div>
                    )}
                  </Col>
                )}
              {/* {ModaliteitenData?.modaliteItensSel?.includes("leningnummer") && (
                <Col md="4">
                  <Label className="form-label" for="leningnummer">
                    Leningnummer
                  </Label>
                  <input
                    className="form-control"
                    id="/
                    "
                    type="text"
                    name="leningnummer"
                    onChange={handleLeningnummer}
                  />
                  {errorState.includes("leningnummer") && (
                    <div>*Leningnummer is required</div>
                  )}
                </Col>
              )} */}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes(
                  "principalAmount"
                ) && (
                  <Col md="4">
                    <Label className="form-label" for="principalAmount">
                      Hoofdsom
                    </Label>
                    <input
                      value={amount}
                      className="form-control"
                      id="principalAmount"
                      type="number"
                      name="principalAmount"
                      onChange={handleHoofdsom}
                    />
                    {errorState.includes("principalAmount") && (
                      <div>*Hoofdsom is vereist</div>
                    )}
                  </Col>
                )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes("depositDate") && (
                  <Col md="4">
                    <Label className="form-label" for="depositDate">
                      Stortingsdatum
                    </Label>
                    <input
                      value={formatDate(editFields.depositDate)}
                      className="form-control"
                      id="depositDate"
                      type="date"
                      name="depositDate"
                      onChange={handleStortingsdatum}
                    />
                    {errorState.includes("depositDate") && (
                      <div>*Stortingsdatum is vereist</div>
                    )}
                  </Col>
                )}
              {ModaliteitenData?.modaliteItensSel?.includes("firstCoupon") && (
                <Col md="4">
                  <Label className="form-label" for="firstCoupon">
                    e1 coupon
                  </Label>
                  <input
                    className="form-control"
                    id="firstCoupon"
                    type="date"
                    name="firstCoupon"
                    onChange={handleECoupon}
                  />
                  {errorState.includes("firstCoupon") && (
                    <div>*e1 coupon is benodigd</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("loanEndDate") && (
                <Col md="4">
                  <Label className="form-label" for="loanEndDate">
                    Einddatum
                  </Label>
                  <input
                    className="form-control"
                    id="loanEndDate"
                    type="date"
                    name="loanEndDate"
                    onChange={handleEinddatum}
                  />
                  {errorState.includes("loanEndDate") && (
                    <div>*Einddatum is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "interestConverionDate"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="interestConverionDate">
                    Renteconversiedatum
                  </Label>
                  <input
                    className="form-control"
                    id="interestConverionDate"
                    type="date"
                    name="interestConverionDate"
                    onChange={handleRenteconversiedatum}
                  />
                  {errorState.includes("interestConverionDate") && (
                    <div>*Renteconversiedatum is verplicht</div>
                  )}
                </Col>
              )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes(
                  "repaymentType"
                ) && (
                  <Col md="4">
                    <Label className="form-label" for="repaymentType">
                      Aflossingstype
                    </Label>
                    <select
                      name="repaymentType"
                      className="form-control form-control-primary btn-square form-select"
                      onChange={handleAflossingstype}
                    >
                      <option value={editFields.repaymentTypeValue}>
                        {editFields.repaymentTypeValue}
                      </option>
                      {transactionFields?.RepaymentTypes?.map((item) => {
                        //Key is Needed
                        return item.name != autoSelect.Aflossingstype ? (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option>
                        ) : (
                          ""
                        );
                      })}
                    </select>
                    {errorState.includes("repaymentType") && (
                      <div>*Aflossingstype is vereist</div>
                    )}
                  </Col>
                )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes(
                  "interestMethod"
                ) && (
                  <Col md="4">
                    <Label className="form-label" for="interestMethod">
                      Rentemethode
                    </Label>
                    <select
                      className="form-control form-control-primary btn-square form-select"
                      id="interestMethod"
                      name="interestMethod"
                      onChange={handleRentemethode}
                    >
                      <option value={editFields?.interestMethod}>
                        {/* {getInterestMethodName(editFields?.interestMethod)} */}
                      </option>{" "}
                      //key is needed
                      {intrestMethodFields.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {errorState.includes("interestMethod") && (
                      <div>* Rentemethode is verplicht</div>
                    )}
                  </Col>
                )}
              {ModaliteitenData?.modaliteItensSel?.includes("rollday") && (
                <Col md="4">
                  <Label className="form-label" for="rollday">
                    Roldag
                  </Label>
                  <input
                    className="form-control"
                    id="rollday"
                    type="date"
                    name="rollday"
                    onChange={handleRoldag}
                  />
                  {errorState.includes("rollday") && (
                    <div>*Roldag is verplicht</div>
                  )}
                </Col>
              )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes("dayCount") && (
                  <Col md="4">
                    <Label className="form-label" for="dayCount">
                      Dagtelling
                    </Label>
                    <select
                      name="dayCount"
                      className="form-control form-control-primary btn-square form-select"
                      onChange={handleDagtelling}
                      // value={editFields.dayCountValue}
                    >
                      <option>{editFields.dayCountValue}</option>
                      {transactionFields?.DayTerms?.map((item) => {
                        return item.name != autoSelect.dagtelling ? (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option> //key is needed
                        ) : (
                          ""
                        );
                      })}
                    </select>
                    {errorState.includes("dayCount") && (
                      <div>*Dagtelling is verplicht</div>
                    )}
                  </Col>
                )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes("convention") && (
                  <Col md="4">
                    <Label className="form-label" for="convention">
                      Conventie
                    </Label>
                    <select
                      name="convention"
                      className="form-control form-control-primary btn-square form-select"
                      onChange={handleConventie}
                    >
                      <option>{editFields.conventionValue}</option>
                      {transactionFields?.Conventions?.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {errorState.includes("convention") && (
                      <div>*Conventies zijn vereist</div>
                    )}
                  </Col>
                )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes("guarantee") && (
                  <Col md="4">
                    <Label className="form-label" for="guarantee">
                      Garantie
                    </Label>
                    <select
                      name="dayCount"
                      className="form-control form-control-primary btn-square form-select"
                      onChange={handleGarantie}
                    >
                      <option>{editFields.guaranteeValue} </option>
                      {transactionFields?.Guarantees?.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option>
                        ); //key is needed
                      })}
                    </select>
                    {errorState.includes("guarantee") && (
                      <div>*Garantie is vereist</div>
                    )}
                  </Col>
                )}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "surchargeRevision"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="surchargeRevision">
                    Opslagherziening
                  </Label>
                  <input
                    className="form-control"
                    id="surchargeRevision"
                    type="date"
                    name="surchargeRevision"
                    onChange={handleOpslagherziening}
                  />
                  {errorState.includes("surchargeRevision") && (
                    <div>*Opslagherziening is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("clause") && (
                <Col md="4">
                  <Label className="form-label" for="clause">
                    Clausule
                  </Label>
                  <select
                    name="dayCount"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleClausule}
                  >
                    <option>Selecteer </option>
                    {transactionFields?.Clauses?.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ); //key is needed
                    })}
                  </select>
                  {errorState.includes("clause") && (
                    <div>*Clausule is vereist</div>
                  )}
                </Col>
              )}
              {editFields !== null &&
                ModaliteitenData?.modaliteItensSel?.includes(
                  "interestBasis"
                ) && (
                  <Col md="4">
                    <Label className="form-label" for="interestBasis">
                      Rentebasis
                    </Label>
                    <select
                      name="dayCount"
                      className="form-control form-control-primary btn-square form-select"
                      onChange={handleRentebasis}
                    >
                      <option value="5">{editFields.interestBasisValue}</option>
                      {transactionFields?.InterestBases?.map((item) => {
                        return item.name != autoSelect.Rentebasis ? (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option> //key is needed
                        ) : (
                          ""
                        );
                      })}
                    </select>
                    {errorState.includes("interestBasis") && (
                      <div>* Rentebasis is verplicht</div>
                    )}
                  </Col>
                )}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ModaliteitenFormEdit;
