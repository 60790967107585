import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Biedingen from "./Biedingen";
import Modaliteiten from "./Modaliteiten";
import CardDataSingle from "./CardDataSingle";
import CardGraphSingle from "./CardGraphSingle";
// import TimerComponent from "../FinancerTransactionDetails/TimerComponent";

import TTC from "./Ttc";
import Randvoorwaarden from "./Randvoorwaarden";
import InfoCard from "./InfoCard";
import { useParams } from "react-router-dom";
import { DashboardService } from "utility/services/dashboard";
import { TransctiesService } from "utility/services/transcties";
import { TransactionMail } from "../Transacties/TransactionMail";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import FinanciersForm from "../Transacties/FinanciersForm";
import CommonModal from "Components/UiKits/Modals/common/modal";
import EmployeeDisplayForm from "../Transacties/EmployeeDisplayForm";
import TimerComponent from "./TimerComponent";
import { toast } from "react-toastify";

const TransactieDashboard = () => {
  const [isTTCPassed, setTTCPassed] = useState(false);
  const [minutes, setMinutes] = useState(14);
  const [modal, setModal] = useState(false);
  const [employeeDisplayModal, setEmployeeDisplayModal] = useState(false);
  const [errorState, setErrorState] = useState([]);
  const [displayNone] = useState("d-none");
  const ModalTitle = "Selecteer Financiers to Email";
  const [seconds, setSeconds] = useState(59);
  const [isFinancierTemplateEnable, setFinancierTemplateEnable] =
    useState(false);
  const [isEmailTemplateEnable, setIsEmailTemplateEnable] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [financierType, setFinancierType] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [financialTranChecklistSel, setFinancialTranChecklistSel] = useState(
    []
  );
  const [edited, setEdited] = useState(false);
  const [financiersTranChecklistSel, setFinanciersTranChecklistSel] = useState(
    []
  );
  const [financialTypeIds, setFinancialTypeIds] = useState([]);
  const isNextButtonEnable = true;
  const [initializeEditableFeature, setInitilizeEditableFeature] =
    useState(false);
  const [statusIsVerlopan, setStatusIsVerlopan] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [disableTTC, setDisableTTC] = useState(false);
  const [openTransactionToggle, setOpenTransactionToggle] = useState(false);

  var timer;
  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);
      if (seconds === 0) {
        if ((minutes === 0 || minutes < 0) && seconds === 0) {
          setMinutes(0);
          setSeconds(0);
        }
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }, 100000);
    return () => clearInterval(timer);
  });

  const MINUTES_MS = 2500;

  const [transaction, setTransaction] = useState({
    detail: null,
    conditions: [],
    taskList: [],
    financiar: [],
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const refresh = async () => {
    const trans = await DashboardService.getTransaction(id);
    let detailData = trans.response;
    let setData = {
      detail: detailData,
      conditions: trans.response?.conditions,
      taskList: trans.response?.checkLists,
      financiar: trans.response?.financers,
    };
    const transactionlog = await TransctiesService.getProposals(detailData?.id);
    setProposals(transactionlog.response);
    setTransaction(setData);
    let _financierType = [];
    trans.response?.financers.map((item) => {
      _financierType.push(item.financierTypeId);
    });
    setFinancierType(_financierType);
  };

  useEffect(() => {
    (async () => {
      const trans = await DashboardService.getTransaction(id);
      // GetSwaprateValueAsync(id);
      let detailData = trans.response;
      let setData = {
        detail: detailData,
        conditions: trans.response?.conditions,
        taskList: trans.response?.checkLists,
        financiar: trans.response?.financers,
      };
      setTransactionId(detailData?.id);

      // Get financierType
      let _financierType = [];
      trans.response?.financers.map((item) => {
        _financierType.push(item.financierTypeId);
      });
      setFinancierType(_financierType);
    })();
    // return () => {
    //   setTransaction({
    //     detail: null,
    //     conditions: [],
    //     taskList: [],
    //     financiar: [],
    //   });
    // };
    // }, MINUTES_MS);

    // return () => clearInterval(interval);
  }, []);
  const getProposal = async (id) => {
    const transactionlog = await TransctiesService.getProposals(id);
    const proposalArray = await transactionlog.response;
    proposalArray.sort((a, b) => a.surcharge - b.surcharge);
    setProposals(proposalArray);
  };

  useEffect(() => {
    if (transactionId !== null) {
      getProposal(transactionId);
    }
    const interval = setInterval(() => {
      getProposal(transactionId);
    }, 20000);
    return () => clearInterval(interval);
  }, [transactionId]);

  //interval();
  // useEffect(() => {
  //   (async () => {
  //     const trans = await DashboardService.getTransaction(id);
  //     let detailData = trans.response;

  //     // delete detailData.listOfTask;
  //     // delete detailData.listOfFinancier;
  //     // delete detailData.listOfCondition;
  //     let setData = {
  //       detail: detailData,
  //       conditions: trans.response?.conditions,
  //       taskList: trans.response?.checkLists,
  //       financiar: trans.response?.financers,
  //     };
  //     setTransaction(setData);
  //     // setFinancialTranChecklistSel(setData);

  //     // Get financierType
  //     let _financierType = [];
  //     trans.response?.financers.map((item) => {
  //       _financierType.push(item.financierTypeId);
  //     });
  //     setFinancierType(_financierType);
  //   })();
  //   // return () => {
  //   //   setTransaction({
  //   //     detail: null,
  //   //     conditions: [],
  //   //     taskList: [],
  //   //     financiar: [],
  //   //   });
  //   // };
  // }, []);

  const fetchData = async () => {
    try {
      const trans = await DashboardService.getTransaction(id);
      let detailData = trans.response;

      // delete detailData.listOfTask;
      // delete detailData.listOfFinancier;
      // delete detailData.listOfCondition;
      let setData = {
        detail: detailData,
        conditions: trans.response?.conditions,
        taskList: trans.response?.checkLists,
        financiar: trans.response?.financers,
      };
      setTransaction(setData);

      // Get financierType
      let _financierType = [];
      trans.response?.financers.map((item) => {
        _financierType.push(item.financierTypeId);
      });
      setFinancierType(_financierType);
    } catch (error) {
      // Handle error
    }
  };
  useEffect(() => {
    // Initial API call
    fetchData();

    // Schedule subsequent API calls every 1 minute
    const interval = setInterval(() => {
      fetchData();
    }, 60000); // 1 minute in milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleEmailPopup = () => {
    setFinancierTemplateEnable(!isFinancierTemplateEnable);
  };
  const handleEdit = () => {
    setInitilizeEditableFeature(true);
    navigate("edit");
  };
  const toggle = () => {
    setEmployeeDisplayModal(!employeeDisplayModal);
  };

  useEffect(() => {
    if (transaction.detail !== null) {
      handleEmailPopUpForVerlopan();
    } else {
      return;
    }
  }, [transaction, statusIsVerlopan]);

  const handleEmailPopUpForVerlopan = () => {
    const endDate = transaction.detail.transactionEndDateTime.split("T");
    const todayDate = new Date();
    const d1 = todayDate.getDate();
    const m1 = todayDate.getMonth() + 1;
    const y1 = todayDate.getFullYear();
    const [y2, m2, d2] = endDate[0].split("-");

    if (y1 > parseInt(y2)) {
      setStatusIsVerlopan(true);
    } else if (y1 === parseInt(y2)) {
      if (m1 > parseInt(m2)) {
        setStatusIsVerlopan(true);
      } else if (m1 === parseInt(m2)) {
        if (d1 > parseInt(d2)) {
          setStatusIsVerlopan(true);
        } else {
          setStatusIsVerlopan(false);
        }
      } else {
        setStatusIsVerlopan(false);
      }
    } else {
      setStatusIsVerlopan(false);
    }
  };

  const completeTransactionToggler = () => {
    return setOpenTransactionToggle(!openTransactionToggle);
  };
  const completeTransactionAPICall = async (id) => {
    const response = await TransctiesService.completeTransaction(id);
    if (response?.status) {
      toast.success("Transactie succesvol afgesloten", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Er is een probleem met het sluiten van de transactie", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    completeTransactionToggler();
    fetchData();
  };
  return (
    <Container fluid={true} className="dashboard-2-main">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
        <h4>Transactie Dashboard</h4>
        <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
          <TimerComponent
            completeTransactionToggler={completeTransactionToggler}
            detail={transaction?.detail}
            fetchData={fetchData}
          ></TimerComponent>
          <h5>&nbsp; Indicatoren :</h5>
          {/* <IconButton>
            <Tooltip title="Klantgegevens aanpassen">
              <EditIcon
                onClick={() => handleEdit()}
                style={{ color: "#FF9E00", margin: "10px" }}
              />
            </Tooltip>
          </IconButton> */}
          {transaction?.detail?.status === 4 && (
            <h5>Transactie gesloten: Er is een bod geselecteerd.</h5>
          )}
          {transaction?.detail?.status === 5 && (
            <h5>Transactie gesloten: Er is geen bod geselecteerd.</h5>
          )}
          {transaction?.detail?.status < 4 && (
            <p>
              {proposals?.length > 0 ? (
                <img
                  style={{ marginLeft: "10px" }}
                  src={require("../../../assets/images/icon-financiers-done.png")}
                  alt=""
                  height={30}
                  width={30}
                  onClick={() => handleEmailPopup()}
                  className="img-fluid"
                />
              ) : (
                <img
                  style={{ marginLeft: "10px" }}
                  src={require("../../../assets/images/icon-financiers-not-done.png")}
                  alt=""
                  height={30}
                  width={30}
                  // onClick={() => handleEmailPopup()}
                  className="img-fluid"
                />
              )}
            </p>
          )}
          {isTTCPassed ? (
            <img
              style={{ marginLeft: "10px" }}
              src={require("../../../assets/images/icon-checklist-done.png")}
              alt=""
              height={30}
              width={30}
              className="img-fluid"
            />
          ) : (
            <img
              style={{ marginLeft: "10px" }}
              src={require("../../../assets/images/icon-checklist-not-done.png")}
              alt=""
              height={30}
              width={30}
              className="img-fluid"
            />
          )}
        </div>
      </div>
      <Row>
        <Col xl="8" className=" des-xl-100 box-col-12">
          <Row>
            <Col
              sm="6"
              xl="7"
              className="col-xl-7 box-col-7 top-sell-sec align-items-stretch"
            >
              <Modaliteiten detail={transaction?.detail} />
            </Col>
            <Col sm="6" xl="5" className="col-xl-5 box-col-5 top-sell-sec">
              <CardDataSingle transaction={transaction} />
              <CardGraphSingle />
              <InfoCard detail={transaction?.detail} />
            </Col>
          </Row>
        </Col>
        <Col
          xl="4"
          className="des-xl-50 box-col-12 activity-sec chart_data_left"
        >
          <TTC
            details={transaction?.detail}
            setTTCPassed={setTTCPassed}
            isTTCPassed={isTTCPassed}
            taskList={transaction.taskList}
            transactionId={transactionId}
            disableTTC={disableTTC}
          />
        </Col>
        <Col xl="8" className="box-col-12 des-xl-100 recent-order-sec">
          <Biedingen
            refresh={refresh}
            proposals={proposals}
            financiarList={transaction.financiar}
            isTTCPassed={isTTCPassed}
            detail={transaction?.detail}
            setDisableTTC={setDisableTTC}
          />
        </Col>
        <Col xl="4" className="box-col-12 des-xl-100 top-dealer-sec">
          <Randvoorwaarden conditions={transaction.conditions} />
        </Col>
      </Row>
      <div>
        <CommonModal
          isOpen={isFinancierTemplateEnable}
          title={ModalTitle}
          toggler={handleEmailPopup}
          size="xl"
          displayNone={displayNone}
          // showFooter={false}
        >
          <FinanciersForm
            financialTypeIds={financialTypeIds}
            financiersTranChecklistSel={financiersTranChecklistSel}
            setFinanciersTranChecklistSel={setFinanciersTranChecklistSel}
            setFinancialTypeIds={setFinancialTypeIds}
            errorState={errorState}
            isNextButtonEnable={isNextButtonEnable}
            transaction={transaction}
            // setIsEmailTemplateEnable={setIsEmailTemplateEnable}
            setEmployeeDisplayModal={setEmployeeDisplayModal}
            employeeDisplayModal={employeeDisplayModal}
          ></FinanciersForm>
        </CommonModal>
      </div>
      {isFinancierTemplateEnable && (
        <CommonModal
          isOpen={employeeDisplayModal}
          title={"Selecteer Contactpersonen"}
          toggler={toggle}
          size="xl"
          // displayNone={displayNone}
          showFooter={false}
        >
          <EmployeeDisplayForm
            financialTypeIds={financialTypeIds}
            financiersTranChecklistSel={financiersTranChecklistSel}
            setSelectedEmployees={setSelectedEmployees}
            transection={transaction?.detail}
            trainsactionId={transaction?.detail?.identifier}
            isFinancierTemplateEnable={isFinancierTemplateEnable}
          ></EmployeeDisplayForm>
        </CommonModal>
      )}
      <CommonModal
        isOpen={openTransactionToggle}
        title={"Alert"}
        toggler={completeTransactionToggler}
        size="xl"
      >
        <h3>
          U staat op het punt de transactie te voltooien zonder een bod te
          selecteren. Weet je het zeker?
        </h3>
        <div
          style={{
            margin: "25px 15px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            style={{ marginRight: "15px" }}
            onClick={() =>
              completeTransactionAPICall(transaction?.detail?.identifier)
            }
          >
            Ja
          </Button>
          <Button>Nee</Button>
        </div>
      </CommonModal>
    </Container>
  );
};

export default TransactieDashboard;
