import { BaseService } from "./base";
import { BrowserUtility } from "../browser-utility";
import { APIPath } from "utility/constant/api";

class Customer {
  getCustomerList(reqData) {
    return BaseService.get(`${APIPath.getCustomers}`);
  }

  getCustomerTypes(reqData) {
    return BaseService.get(`${APIPath.getCustomerTypes}`);
  }

  getCustomerById(reqData) {
    return BaseService.get(`${APIPath.customerById}`);
  }

  setCustomerList(reqData) {
    return BaseService.post(`${APIPath.setCustomerList}`, reqData, true);
  }

  deleteCustomer(reqData) {
    return BaseService.post(`${APIPath.deleteCustomer}?identifier=${reqData}`, reqData, true);
  }
}

const CustomerService = new Customer();
Object.freeze(CustomerService);
export { CustomerService };
