import React, { Fragment, useState, useEffect } from "react";
import { DangerText } from "Components/Common/Comman";
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import { FinancerService } from "utility";
// import Select from 'react-select'

const ModaliteitenForm = (ModaliteitenData) => {
  const [intrestMethodFields, setIntrestMethodFields] = useState([
    { name: "wekelijks", value: "1" },
    { name: "maandelijks", value: "2" },
    { name: "kwartaal", value: "3" },
    { name: "halfjaarlijks", value: "4" },
    { name: "jaarlijks", value: "5" },
  ]);
  const [rollDay, setRollDay] = useState([
    { name: "Maandag", value: "1" },
    { name: "Dinsdag", value: "2" },
    { name: "Woensdag", value: "3" },
    { name: "Donderdag", value: "4" },
    { name: "Vrijdag", value: "5" },
  ]);

  const [financierName, setFiancierName] = useState([]);
  const [isInterestMethodWeekly, setIsInterestMethodWeekly] = useState(false);
  const [principal, setPrincipal] = useState(0);

  const {
    transection,
    setTransection,
    errorState,
    setErrorState,
    transactionFields,
    autoSelect,
    modaliteItensSel,
    setLoanDuration,
    loanDuration,
  } = ModaliteitenData;

  // console.log(autoSelect)

  const handleHuidigeGeldgever = (e) => {
    setTransection({ ...transection, currentFinancierId: e.target.value });
  };

  const handleDealcode = (e) => {
    setTransection({ ...transection, dealCode: e.target.value });
  };

  const handleLeningnummer = (e) => {
    setTransection({ ...transection, leningnummer: e.target.value });
  };

  const handleHoofdsom = (e) => {
    setPrincipal(e.target.value);
    setTransection({ ...transection, principalAmount: e.target.value });
  };

  const handleStortingsdatum = (e) => {
    setTransection({ ...transection, depositDate: e.target.value });
  };

  const handleECoupon = (e) => {
    setTransection({ ...transection, firstCoupon: e.target.value });
  };

  const handleEinddatum = (e) => {
    setTransection({
      ...transection,
      loanEndDate: e.target.value,
    });
  };

  const handleDuration = (e) => {
    setTransection({ ...transection, duration: parseInt(e.target.value) });

    setLoanDuration(e.target.value);
  };

  const handleRenteconversiedatum = (e) => {
    setTransection({ ...transection, interestConverionDate: e.target.value });
  };

  const handleAflossingstype = (e) => {
    setTransection({ ...transection, repaymentType: e.target.value });
  };

  const handleRentemethode = (e) => {
    if (e.target.value === "wekelijks") {
      setIsInterestMethodWeekly(true);
    } else {
      setIsInterestMethodWeekly(false);
    }
    setTransection({ ...transection, interestMethod: e.target.value });
  };

  const handleRoldag = (e) => {
    setTransection({ ...transection, rollday: e.target.value });
  };

  const handleDagtelling = (e) => {
    setTransection({ ...transection, dayCount: e.target.value });
  };

  const handleConventie = (e) => {
    setTransection({ ...transection, convention: e.target.value });
  };

  const handleGarantie = (e) => {
    setTransection({ ...transection, guarantee: e.target.value });
  };

  const handleOpslagherziening = (e) => {
    setTransection({ ...transection, surchargeRevision: e.target.value });
  };

  const handleClausule = (e) => {
    setTransection({ ...transection, clause: e.target.value });
  };

  const handleRentebasis = (e) => {
    setTransection({ ...transection, interestBasis: e.target.value });
  };

  useEffect(() => {
    setTransection({
      ...transection,
      repaymentType: autoSelect?.AflossingstypeValue,
      dayCount: autoSelect?.dagtellingValue,
      interestMethod: autoSelect.interestMethod,
      convention: "1",
      interestBasis: autoSelect.RenteBasisValue,
      clause: autoSelect.clause,
    });
  }, [
    transection.transactionsubtype,
    autoSelect?.AflossingstypeValue,
    autoSelect?.dagtellingValue,
    autoSelect.interestMethod,
    autoSelect.RenteBasisValue,
    autoSelect.clause,
  ]);

  useEffect(() => {
    getFinancierData();
  }, []);

  const getFinancierData = async () => {
    try {
      const resp = await FinancerService.getFinancerList();

      const newStateData = resp.response.map((item) => {
        return {
          financierId: item.id,
          financierIdentifier: item.identifier,
          financierName: item.name,
        };
      });
      setFiancierName(newStateData);
    } catch (error) {
      console.log("error", error);
    }
  };

  const convertedPrincipalAmount = (number) => {
    var formatter = new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(number);
  };

  useEffect(() => {
    if (transection?.depositDate !== "" && transection?.loanEndDate !== "") {
      const loanStartDate = transection?.depositDate;
      const loanEndDate = transection?.loanEndDate;
      let newDuration = 0;
      if (transection?.interestConverionDate !== "") {
        const interestDate = transection?.interestConverionDate;
        newDuration = timeDurationFormat(loanStartDate, interestDate);
      } else {
        newDuration = timeDurationFormat(loanStartDate, loanEndDate);
      }
      setTransection({ ...transection, duration: newDuration });
      setLoanDuration(newDuration);
    }
  }, [
    transection?.depositDate,
    transection?.loanEndDate,
    transection?.interestConverionDate,
  ]);

  const timeDurationFormat = (loanStartDate, loanEndDate) => {
    const endDay = new Date(loanEndDate);
    const endDateInSeconds = endDay.getTime() / 1000;
    const startDay = new Date(loanStartDate);
    const startDateInSeconds = startDay.getTime() / 1000;
    let differenceSeconds = endDateInSeconds - startDateInSeconds;
    const days = Math.floor(differenceSeconds / (24 * 60 * 60));
    const duration1 = days / 365;
    const durationWithDecimal = Math.round(duration1);
    return durationWithDecimal;
  };

  // console.log(transection)
  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">Modaliteiten lening</h5>
        </CardHeader>
        <CardBody>
          <Form>
            <Row className="g-3">
              {ModaliteitenData?.modaliteItensSel?.includes(
                "huidige-geldgever"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="huidigegeldgever">
                    Huidige geldgever
                  </Label>
                  <select
                    name="huidigegeldgever"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleHuidigeGeldgever}
                  >
                    <option value={financierName.financierId}>
                      {financierName.financierName}
                    </option>
                    {financierName.map((item) => {
                      //Key is Needed
                      return (
                        <option key={item.financierId} value={item.financierId}>
                          {item.financierName}
                        </option>
                      );
                    })}
                  </select>
                  {/* <select
                    name="huidigegeldgever"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleHuidigeGeldgever}
                  >
                    <option value="">Selecteer </option>
                    <option>BNG Bank</option>
                    <option>NWB Bank</option>
                    <option>Rabobank</option>
                    <option>ING Bank</option>
                    <option>ABN AMRO Bank</option>
                  </select> */}
                  {errorState.includes("huidigegeldgever") && (
                    <div>* Huidige geldgever is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("dealCode") && (
                <Col md="4">
                  <Label className="form-label" for="">
                    Dealcode
                  </Label>
                  <input
                    onChange={handleDealcode}
                    className="form-control"
                    id="dealCode"
                    type="text"
                    name="dealCode"
                  />
                  {errorState.includes("dealCode") && (
                    <div>*Dealcode is vereist</div>
                  )}
                </Col>
              )}
              {/* {ModaliteitenData?.modaliteItensSel?.includes("leningnummer") && (
                <Col md="4">
                  <Label className="form-label" for="leningnummer">
                    Leningnummer
                  </Label>
                  <input
                    className="form-control"
                    id="/
                    "
                    type="text"
                    name="leningnummer"
                    onChange={handleLeningnummer}
                  />
                  {errorState.includes("leningnummer") && (
                    <div>*Leningnummer is required</div>
                  )}
                </Col>
              )} */}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "principalAmount"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="principalAmount">
                    Hoofdsom{" "}
                    <span>
                      {principal > 0 && (
                        <span>
                          {`(€`} {convertedPrincipalAmount(principal)}
                          {`)`}{" "}
                        </span>
                      )}
                    </span>
                  </Label>
                  <input
                    className="form-control"
                    id="principalAmount"
                    type="number"
                    name="principalAmount"
                    onChange={handleHoofdsom}
                  />
                  {errorState.includes("principalAmount") && (
                    <div>*Hoofdsom is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("depositDate") && (
                <Col md="4">
                  <Label className="form-label" for="depositDate">
                    Stortingsdatum
                  </Label>
                  <input
                    className="form-control"
                    id="depositDate"
                    type="date"
                    name="depositDate"
                    onChange={handleStortingsdatum}
                  />
                  {errorState.includes("depositDate") && (
                    <div>*Stortingsdatum is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("firstCoupon") && (
                <Col md="4">
                  <Label className="form-label" for="firstCoupon">
                    1e coupon
                  </Label>
                  <input
                    className="form-control"
                    id="firstCoupon"
                    type="date"
                    name="firstCoupon"
                    onChange={handleECoupon}
                  />
                  {errorState.includes("firstCoupon") && (
                    <div>*e1 coupon is benodigd</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("rollday") && (
                <Col md="4">
                  <Label className="form-label" for="rollday">
                    Roldag
                  </Label>
                  {isInterestMethodWeekly === false ? (
                    <input
                      className="form-control"
                      id="rollday"
                      type="number"
                      name="rollday"
                      min="1"
                      max="31"
                      onChange={handleRoldag}
                      placeholder="Bereik tussen 1 en 31"
                    />
                  ) : (
                    <select
                      className="form-control form-control-primary btn-square form-select"
                      id="rollday"
                      name="rollday"
                      onChange={handleRoldag}
                    >
                      <option value="">Selecteer </option> //key is
                      needed
                      {rollDay.map((item) => {
                        return (
                          <option key={item.value} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                  {errorState.includes("rollday") && (
                    <div>*Roldag is verplicht</div>
                  )}
                </Col>
              )}
              <Col md="4" style={{ display: "flex", gap: 10 }}>
                {ModaliteitenData?.modaliteItensSel?.includes(
                  "loanEndDate"
                ) && (
                  <Col md="6">
                    <Label className="form-label" for="loanEndDate">
                      Einddatum
                    </Label>
                    <input
                      className="form-control"
                      id="loanEndDate"
                      type="date"
                      name="loanEndDate"
                      onChange={handleEinddatum}
                    />
                    {errorState.includes("loanEndDate") && (
                      <div>*Einddatum is vereist</div>
                    )}
                  </Col>
                )}
                {ModaliteitenData?.modaliteItensSel?.includes("duration") && (
                  <Col md="6">
                    <Label className="form-label" for="duration">
                      Looptijd
                    </Label>
                    <input
                      className="form-control"
                      id="duration"
                      type="number"
                      name="duration"
                      value={loanDuration}
                      onChange={handleDuration}
                    />
                  </Col>
                )}
              </Col>
              {ModaliteitenData?.modaliteItensSel?.includes(
                "interestConverionDate"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="interestConverionDate">
                    Renteconversiedatum
                  </Label>
                  <input
                    className="form-control"
                    id="interestConverionDate"
                    type="date"
                    name="interestConverionDate"
                    onChange={handleRenteconversiedatum}
                  />
                  {errorState.includes("interestConverionDate") && (
                    <div>*Renteconversiedatum is verplicht</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "repaymentType"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="repaymentType">
                    Aflossingstype
                  </Label>
                  <select
                    name="repaymentType"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleAflossingstype}
                  >
                    <option value={autoSelect.AflossingstypeValue}>
                      {autoSelect.Aflossingstype}
                    </option>
                    {transactionFields?.RepaymentTypes?.map((item) => {
                      //Key is Needed
                      return item.name != autoSelect.Aflossingstype ? (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ) : (
                        ""
                      );
                    })}
                  </select>
                  {errorState.includes("repaymentType") && (
                    <div>*Aflossingstype is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "interestMethod"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="interestMethod">
                    Rentemethode
                  </Label>
                  <select
                    className="form-control form-control-primary btn-square form-select"
                    id="interestMethod"
                    name="interestMethod"
                    onChange={handleRentemethode}
                  >
                    <option>{autoSelect.interestMethod}</option> //key is needed
                    {intrestMethodFields.map((item) => {
                      return (
                        <option key={item.value} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorState.includes("interestMethod") && (
                    <div>* Rentemethode is verplicht</div>
                  )}
                </Col>
              )}

              {ModaliteitenData?.modaliteItensSel?.includes("dayCount") && (
                <Col md="4">
                  <Label className="form-label" for="dayCount">
                    Dagtelling
                  </Label>
                  <select
                    name="dayCount"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleDagtelling}
                  >
                    <option>{autoSelect.dagtelling}</option>
                    {transactionFields?.DayTerms?.map((item) => {
                      return item.name != autoSelect.dagtelling ? (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option> //key is needed
                      ) : (
                        ""
                      );
                    })}
                  </select>
                  {errorState.includes("dayCount") && (
                    <div>*Dagtelling is verplicht</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("convention") && (
                <Col md="4">
                  <Label className="form-label" for="convention">
                    Conventie
                  </Label>
                  <select
                    name="convention"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleConventie}
                  >
                    {transactionFields?.Conventions?.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorState.includes("convention") && (
                    <div>*Conventies zijn vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("guarantee") && (
                <Col md="4">
                  <Label className="form-label" for="guarantee">
                    Garantie
                  </Label>
                  <select
                    name="guarantee"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleGarantie}
                  >
                    <option value="">Selecteer </option>
                    {transactionFields?.Guarantees?.map((item) => {
                      return (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option>
                      ); //key is needed
                    })}
                  </select>
                  {errorState.includes("guarantee") && (
                    <div>*Garantie is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "surchargeRevision"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="surchargeRevision">
                    Opslagherziening
                  </Label>
                  <input
                    className="form-control"
                    id="surchargeRevision"
                    type="date"
                    name="surchargeRevision"
                    onChange={handleOpslagherziening}
                  />
                  {errorState.includes("surchargeRevision") && (
                    <div>*Opslagherziening is vereist</div>
                  )}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes("clause") && (
                <Col md="4">
                  <Label className="form-label" for="clause">
                    Clausule
                  </Label>
                  <select
                    name="clause"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleClausule}
                    defaultValue="2"
                  >
                    <option value="1">Ja</option>
                    <option value="2">Nee</option>
                  </select>
                  {/* {errorState.includes("clause") && (
                    <div>*Clausule is vereist</div>
                  )} */}
                </Col>
              )}
              {ModaliteitenData?.modaliteItensSel?.includes(
                "interestBasis"
              ) && (
                <Col md="4">
                  <Label className="form-label" for="interestBasis">
                    Rentebasis
                  </Label>
                  <select
                    name="interestBasis"
                    className="form-control form-control-primary btn-square form-select"
                    onChange={handleRentebasis}
                  >
                    <option value={autoSelect.RenteBasisValue}>
                      {autoSelect.Rentebasis}
                    </option>
                    {transactionFields?.InterestBases?.map((item) => {
                      return item.name != autoSelect.Rentebasis ? (
                        <option key={item.value} value={item.value}>
                          {item.name}
                        </option> //key is needed
                      ) : (
                        ""
                      );
                    })}
                  </select>
                  {errorState.includes("interestBasis") && (
                    <div>* Rentebasis is verplicht</div>
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ModaliteitenForm;
