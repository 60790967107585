import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

import { visuallyHidden } from "@mui/utils";
import { GetFinancerList, GetFinancerType } from "hooks";

import { Col, Row, Card, Form, FormGroup } from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Btn } from "../../../AbstractElements";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormSelectField, FormTextField } from "CommonElements/FormElements";
import { UserService, FinancerService } from "utility";
import { toast } from "react-toastify";
import { useAuth } from "context/user";
import { AuthService, UserRoles } from "utility";

import { Button } from "reactstrap";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import Form_ from "react-bootstrap/Form";
import { generate, generateMultiple, validate } from "@wcj/generate-password";
import Employee from "./Employee";

function createData(
  id,
  name,
  address,
  sector,
  financierType,
  awarded,
  participated,
  identifier,
  financierTypeId
) {
  return {
    id,
    name,
    address,
    sector,
    financierType,
    awarded,
    participated,
    identifier,
    financierTypeId,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: "id",
  //   numeric: true,
  //   disablePadding: true,
  //   label: "ID",
  // },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Naam",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Plaats",
  },
  {
    id: "financierType",
    numeric: true,
    disablePadding: false,
    label: "Type financier",
  },
  // {
  //   id: "sector",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Sector",
  // },
  {
    id: "awarded",
    numeric: false,
    disablePadding: false,
    label: "Geselecteerd",
  },
  {
    id: "participated",
    numeric: false,
    disablePadding: false,
    label: "Deelgenomen",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Acties",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell> */}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ color: "#212F56" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCellsPopup = [
  // {
  //   id: "id",
  //   numeric: true,
  //   disablePadding: true,
  //   label: "ID",
  // },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Naam",
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Adres",
  },
  {
    id: "financierType",
    numeric: true,
    disablePadding: false,
    label: "Type financier",
  },
  // {
  //   id: "sector",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Sector",
  // },
  {
    id: "awarded",
    numeric: false,
    disablePadding: false,
    label: "Geselecteerd",
  },
  {
    id: "participated",
    numeric: false,
    disablePadding: false,
    label: "Deelgenomen",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Acties",
  },
];

function EnhancedTableHeadPopup(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell> */}
        {headCellsPopup.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ color: "#212F56" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    user,
    numSelected,
    onClickAddTransaction,
    handleAddCustomer,
    handleDeleteCustomer,
    selected,
    handleEditCustomer,
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Financiers
        </Typography>
      )}

      {numSelected == 1 &&
        user?.userType ==
          UserRoles.admin(
            <IconButton>
              {user?.userType == UserRoles.admin && (
                <Tooltip title="Klantgegevens aanpassen">
                  <EditIcon
                    onClick={() => handleEditCustomer(selected[0])}
                    style={{ color: "#FF9E00", margin: "10px" }}
                  />
                </Tooltip>
              )}
            </IconButton>
          )}

      {numSelected > 0 ? (
        <span>
          {user?.userType == UserRoles.admin && (
            <IconButton>
              <Tooltip title="Delete">
                <DeleteIcon
                  onClick={() => handleDeleteCustomer(selected)}
                  style={{ color: "#FF9E00", margin: "10px" }}
                />
              </Tooltip>
            </IconButton>
          )}
        </span>
      ) : (
        <IconButton>
          {user?.userType == UserRoles.admin && (
            <Tooltip title="Financier toevoegen">
              <AddIcon
                onClick={() => handleAddCustomer()}
                style={{ color: "#FF9E00", margin: "10px", muiName: "Add" }}
              />
            </Tooltip>
          )}
          {/* <Tooltip title="Filters">
            <FilterListIcon style={{ color: "#FF9E00", margin: "10px" }} />
          </Tooltip> */}
        </IconButton>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const GeneratePassword = (selectedListObject) => {
  const generateNewPassword = () => {
    const pass = generate();
    deleteAPICall(pass);
    setPassword(pass);
  };

  const deleteAPICall = async (password) => {
    const response = await UserService.getActivateLoginForPerson(
      selectedListObject?.selectedListObject?.id,
      password
    );
  };

  const [password, setPassword] = useState("");

  const [smShow, setSmShow] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          generateNewPassword();
          setSmShow(true);
        }}
        color="primary"
        className="welkon-form-popup-btn-attr "
      >
        Wachtwoord aanmaken
      </Button>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Wachtwoord genereren
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form_>
            <Form_.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form_.Label>Wachtwoord</Form_.Label>
              <Form_.Control type="text" value={password} autoFocus />
            </Form_.Group>
          </Form_>
        </Modal.Body>
      </Modal>
    </>
  );
};

// const GeneratePassword = () => {
//   const [smShow, setSmShow] = useState(false);
//   return (
//     <>
//       <Button
//         onClick={() => setSmShow(true)}
//         color="primary"
//         className="welkon-form-popup-btn-attr "
//       >
//         Wachtwoord aanmaken
//       </Button>
//       <Modal
//         size="sm"
//         show={smShow}
//         onHide={() => setSmShow(false)}
//         aria-labelledby="example-modal-sizes-title-sm"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="example-modal-sizes-title-sm">
//             Wachtwoord genereren
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form_>
//             <Form_.Group className="mb-3" controlId="exampleForm.ControlInput1">
//               <Form_.Label>Wachtwoord</Form_.Label>
//               <Form_.Control type="text" value={generate()} autoFocus />
//             </Form_.Group>
//           </Form_>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

export const Financiers = () => {
  const { user } = useAuth();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isSubpopupOpen, setSubpopupOpen] = useState(false);
  const [displayNone] = useState("d-none");
  const [errorState, setErrorState] = useState([]);
  const [editFinancier, setIsEditFinancier] = useState(false);

  const handleAddCustomer = () => {
    setIsEditFinancier(false);
    setValue("id", 0);
    setValue("name", "");
    setValue("address", "");
    setValue("sector", "");
    setValue("financierTypeId", 0);
    setSelectedListObject({
      id: 0,
      name: "",
      address: "",
      sector: "",
      financierTypeId: 0,
      identifier: "",
    });
    toggle();
    setErrorState([]);
  };

  const deleteAPICall = async (id) => {
    const response = await UserService.deleteFinancier(id);
    if (response?.status) {
      toast.success("De gegevens van de klant zijn aangepast.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.warning(
        " Financier is verbonden aan transactie dus kan niet worden verwijderd.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    try {
      const result = await FinancerService.getFinancerList();
      setRows(result.response);
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteCustomer = async (selected) => {
    const data = rows.filter((item) => {
      if (item.id === selected) return item;
    });
    try {
      // selected.map((item) => {
      // const object = rows.filter((_item) => { if (_item.id == item) return _item })
      deleteAPICall(data[0].identifier);

      // })
      // setSealected([])
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEditCustomer = (Id) => {
    setIsEditFinancier(true);
    const data = rows.filter((item) => {
      if (item.id === Id) return item;
    });
    setValue("id", data[0]?.id || "");
    setValue("name", data[0]?.name || "");
    setValue("address", data[0]?.address || "");
    setValue("sector", data[0]?.sector || 1);
    setValue("financierTypeId", data[0]?.financierTypeId);
    setSelectedListObject({
      id: data[0].id,
      identifier: data[0].identifier,
      name: data[0].name,
      address: data[0].address,
      sector: data[0].sector,
      financierTypeId: data[0].financierTypeId,
    });
    toggle();
    setErrorState([]);
  };

  const [rows, setRows] = useState([]);
  const { data: financierList, loading } = GetFinancerList();
  const { data: financierType } = GetFinancerType();
  const [_financierType, setFinancierType] = useState([]);

  const [selectedFinancerObject, setSelectedListObject] = useState();
  const [mainUserData, setMainUserData] = useState();
  const navigate = useNavigate();

  const onClickAddTransaction = () => {};

  const onClickDetailsransaction = (e, row) => {
    // navigate(`/transacties/dashboard/` + row.id);
  };

  const Schema = yup.object().shape({
    firstname: yup.string(),
    name: yup.string().required("Geldige naam is vereist"),
    address: yup.string().required("Plaats is verplicht"),
    sector: yup.string(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const handleAddUserSubmit = async (formdata) => {
    let hasError = false;
    let errorColletion = [];
    if (formdata.name === "") {
      hasError = true;
      errorColletion = [...errorColletion, "name"];
    }

    if (formdata.address === "") {
      hasError = true;
      errorColletion = [...errorColletion, "address"];
    }

    // if (formdata.sector === "") {
    //   hasError = true;
    //   errorColletion = [...errorColletion, "sector"];
    // }

    if (formdata.financierTypeId === 0 || formdata.financierTypeId === "0") {
      hasError = true;
      errorColletion = [...errorColletion, "financierTypeId"];
    }

    if (!hasError) {
      setErrorState([]);
      if (!isSubpopupOpen) {
        try {
          const requestObject = {
            identifier: selectedFinancerObject.identifier,
            name: formdata.name,
            address: formdata.address,
            // sector: formdata.sector,
            sector: "",

            financierTypeId: parseInt(formdata.financierTypeId),
          };

          const addRequestObject = {
            name: formdata.name,
            address: formdata.address,
            // sector: formdata.sector,
            sector: "",
            financierTypeId: parseInt(formdata.financierTypeId),
          };
          try {
            await UserService.insertUpdateFinancier(
              selectedFinancerObject.identifier
                ? requestObject
                : addRequestObject
            );
            toggle();

            {
              selectedFinancerObject?.id
                ? toast.success("De gegevens van de klant zijn aangepast.", {
                    position: toast.POSITION.TOP_RIGHT,
                  })
                : toast.success("De gegevens van de klant zijn toegevoegd.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
            }
            const result = await FinancerService.getFinancerList();
            setRows(result.response);
          } catch (error) {
            toast.error("Serverfout", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } catch (error) {
          toast.error("Serverfout", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      setErrorState(errorColletion);
    }
  };

  useEffect(() => {
    if (financierList) {
      let translated = financierList.map((item) => {
        return createData(
          item.id,
          item.name,
          item.address,
          item.sector,
          item.financierType,
          item.awarded,
          item.participated,
          item.identifier,
          item.financierTypeId
        );
      });
      // translated = [...translated, ...translated];
      setRows(translated);
    }
  }, [financierList]);

  useEffect(() => {
    if (financierType) {
      let translated = [];
      translated.push({ name: "Selecteer", value: 0 });
      financierType.map((item) => {
        translated.push({ name: item.name, value: item.id });
      });
      setFinancierType(translated);
    }
  }, [financierType]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const showDeleteIcon = (row) => {
    if (row.awarded > 0 || row.participated > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <React.Fragment>
        <Box sx={{ color: "#212F56", width: "100%" }}>
          <Paper sx={{ color: "#212F56", width: "100%", mb: 2 }}>
            {
              <EnhancedTableToolbar
                numSelected={selected.length}
                onClickAddTransaction={onClickAddTransaction}
                handleAddCustomer={handleAddCustomer}
                handleDeleteCustomer={handleDeleteCustomer}
                handleEditCustomer={handleEditCustomer}
                selected={selected}
                user={user}
              />
            }
            <TableContainer>
              <Table
                sx={{ color: "#212F56", minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.address}</TableCell>
                          <TableCell align="left">
                            {row.financierType}
                          </TableCell>
                          {/* <TableCell align="left">{row.sector}</TableCell> */}
                          <TableCell align="left">{row.awarded}</TableCell>
                          <TableCell align="left">{row.participated}</TableCell>
                          <TableCell allgn="left">
                            {/* <VisibilityIcon
                              onClick={(e) => onClickDetailsransaction(e, row)}
                              style={{ color: "#FF9E00", margin: "10px" }}
                            /> */}
                            <IconButton>
                              <Tooltip title="Klantgegevens aanpassen">
                                <EditIcon
                                  onClick={() => handleEditCustomer(row.id)}
                                  style={{ color: "#FF9E00", margin: "10px" }}
                                />
                              </Tooltip>
                            </IconButton>
                            {user?.userType == UserRoles?.admin && (
                              <span>
                                {showDeleteIcon(row) === true && (
                                  <IconButton>
                                    <Tooltip title="Delete">
                                      <DeleteIcon
                                        onClick={() =>
                                          handleDeleteCustomer(row.id)
                                        }
                                        style={{ color: "#FF9E00" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                )}{" "}
                              </span>
                            )}
                            {/* {user?.userType == UserRoles?.employee && (
                              <span>
                                {showDeleteIcon(row) === true && (
                                  <IconButton>
                                    <Tooltip title="Delete">
                                      <DeleteIcon
                                        onClick={() =>
                                          handleDeleteCustomer(row.id)
                                        }
                                        style={{ color: "#FF9E00" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                )}{" "}
                              </span>
                            )} */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rijen per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} van ${count !== -1 ? count : `meer dan ${to}`}`
              }
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Regelafstand"
          />
        </Box>
        <CommonModal
          isOpen={modal}
          title="Financier"
          toggler={toggle}
          size="xl"
          displayNone={displayNone}
          showFooter={false}
        >
          <Row className="welkon-form-popup-main-div">
            <Col sm="10" className="d-flex flex-column justify-content-between">
              <Form
                className="welkon-form-popup-main"
                noValidate
                onSubmit={handleSubmit(handleAddUserSubmit)}
              >
                <h5>Gegevens financier</h5>
                <Box sx={{ width: "100%" }}>
                  <Paper sx={{ width: "100%", mb: 2, padding: 2 }}>
                    <FormGroup>
                      <Row>
                        <Col>
                          <FormTextField
                            label="Naam"
                            className="form-control"
                            type="text"
                            name="name"
                            required={true}
                            errors={errors?.name}
                            control={control}
                          />
                        </Col>
                        <Col>
                          <FormTextField
                            label="Plaats"
                            className="form-control"
                            type="text"
                            name="address"
                            required={true}
                            errors={errors.address}
                            control={control}
                          />
                        </Col>
                        {/* <Col>
                          <FormTextField
                            label="Sector"
                            className="form-control"
                            type="text"
                            name="sector"
                            // required={true}
                            // errors={errors.sector}
                            control={control}
                          />
                        </Col> */}
                        <Col>
                          <FormSelectField
                            label="Type financier"
                            className="form-control"
                            type="select"
                            name="financierTypeId"
                            control={control}
                            options={_financierType}
                            disabled={editFinancier ? true : false}
                          />
                          {errorState.includes("financierTypeId") && (
                            <div className="text-dark">
                              * Type financier is verplicht
                            </div>
                          )}
                        </Col>
                      </Row>
                    </FormGroup>
                  </Paper>
                </Box>
                {selectedFinancerObject?.id != 0 ? (
                  <Employee
                    selectedFinancerObject={selectedFinancerObject}
                    setSubpopupOpen={setSubpopupOpen}
                  ></Employee>
                ) : (
                  <></>
                )}
                <Row className="step1_header">
                  <Col className="right">
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "welkon-form-popup-btn-attr ",
                      }}
                    >
                      Opslaan
                    </Btn>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CommonModal>
      </React.Fragment>
    </ThemeProvider>
  );
};

export default Financiers;
