import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useState } from "react";
import BubbleChartClass from "../../Charts/ApexCharts/BubbleChart";
import Aankomendetransacties from "./Aankomendetransacties";
import RecenteVoltooideTransacties from "./RecenteVoltooideTransacties";
import IncomeCard from "./IncomeCard";
import { DashboardService } from "utility/services/dashboard";
import { Box } from "@mui/material";

const Dashboard = () => {
  const [transaction, setTransaction] = useState([]);
  const [recentcompleted, setRecentcompleted] = useState([]);
  const [upcomingTransaction, setUpcomingTransaction] = useState([]);

  useEffect(() => {
    getTransaction();
    // return () => {
    //   setTransaction([]);
    // };
  }, []);

  const getTransaction = async () => {
    const response = await DashboardService.getEmployee();
    const listofUpcomingTransaction = response?.response?.listofBox;
    setTransaction(listofUpcomingTransaction);
    setRecentcompleted(response?.response?.recentlyCompletedTransactions);
    setUpcomingTransaction(response?.response?.upcomingTransactions);
  };

  return (
    <Container fluid={true} className="dashboard-default-sec">
      <Row>
        <Col xl="9" className="box-col-12 des-xl-100">
          <Row>
            <Col xl="12" className="box-col-12 des-xl-100">
              <Row>
                {transaction.map((item, i) => (
                  <Col
                    xl="3"
                    md="3"
                    sm="6"
                    key={i}
                    className={
                      i % 2
                        ? `box-col-3 des-xl-25 rate-sec box-primary`
                        : `box-col-3 des-xl-25 rate-sec box-secondary`
                    }
                  >
                    <Box
                      sx={{
                        width: " 100%",
                        height: "85%",
                      }}
                    >
                      <IncomeCard
                        topline={item.title}
                        amount={item.value}
                        title={item.subTitle}
                      />
                    </Box>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xl="12" className="box-col-12 des-xl-100">
              {/* <UserCard /> */}
              <BubbleChartClass title="Opslagcurve" />
            </Col>
          </Row>
        </Col>
        <Col
          xl="3"
          md="12"
          sm="12"
          className="mh-100 box-col-12 des-xl-100 align-item-stretch"
          style={{ paddingBottom: "30px" }}
        >
          <Aankomendetransacties upcomingTransaction={upcomingTransaction} />{" "}
        </Col>
      </Row>
      <Row>
        <Col xl="12" className="box-col-12 des-xl-100">
          <RecenteVoltooideTransacties recentcompleted={recentcompleted} />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
