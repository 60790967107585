import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { DangerText } from "Components/Common/Comman";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TaskService } from "utility";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  InputGroup,
  Row,
} from "reactstrap";
import { SalesReturn } from "Constant";

const BasicFormEdit = (basicFormData) => {
  const {
    editFields,
    transactionTypeValue,
    setTransactionTypeValue,
    transactionSubTypeValue,
    setTransactionSubTypeValue,
    setModaliteItensSel,
    transection,
    setTransection,
    errorState,
    setErrorState,
    transactionFields,
    setAutoSelect,
    autoSelect,
    selectAuto,
  } = basicFormData;
  console.log(editFields);
  const [transactionSubOptions, setTransactionSubOptions] = useState("");
  const [getCustomers, setCustomerList] = useState([]);
  const [updatedCustomer, setUpdatedCustomer] = useState("");
  const [transactionNumber, setTransactionNumber] = useState(0);
  const [editField, setEditField] = useState(
    editFields === null ? "" : editFields.transactionNo
  );
  const [transactionType, setTransactionType] = useState("");

  useEffect(() => {
    if (editFields !== null) {
      setEditField(editFields);
      const customerName = editFields.customer.company.name.replace();
      // const transactionNum = editFields.transactionNo;
      const transactionTy = editFields.transactionTypeValue;
      // console.log(transactionNum);
      setUpdatedCustomer(customerName);
      // setTransactionNumber(transactionNum);
      setTransactionType(transactionTy);
    } else return;
  }, [transection]);

  const [isFlag, setIsFlag] = useState(false);

  const handleTranTypeSelect = (e) => {
    var selectTranType = e.target.value;
    console.log(e.target.value);
    if (selectTranType === "1") {
      setTransactionSubOptions([
        "Fixe",
        "Roll-over variabel",
        "Roll-over vast",
        "Lineair",
        "Annuïtair",
      ]);
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
      setTransection({
        ...transection,
        transactionsubtype: "Fixe",
        transactionType: e.target.value,
      });
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setErrorState(
        errorState.filter((obj) => {
          return obj !== "transactionType";
        })
      );
    } else if (selectTranType === "2") {
      setTransactionSubOptions(["Fixe", "Lineair", "Annuïtair"]);
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
      setTransection({
        ...transection,
        transactionsubtype: "Fixe",
        transactionType: e.target.value,
      });
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setErrorState(
        errorState.filter((obj) => {
          return obj !== "transactionType";
        })
      );
    } else if (selectTranType === "3") {
      setTransactionSubOptions([
        "Basisrentelening",
        "Roll-over variabel",
        "Roll-over vast",
      ]);
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "surchargeRevision",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
      setTransection({
        ...transection,
        transactionsubtype: "Basisrentelening",
        transactionType: e.target.value,
      });
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setErrorState(
        errorState.filter((obj) => {
          return obj !== "transactionType";
        })
      );
    } else {
      setTransactionSubOptions("");
      setTransection({
        ...transection,
        transactionType: "",
        transactionsubtype: "",
      });
      setErrorState([...errorState, "transactionType"]);
    }
    setTransactionTypeValue(e.target.value);
  };

  const handleTranSubTypeSelect = (e) => {
    setTransactionSubTypeValue(e.target.value);
    let transactionSubTypeValue = e.target.value;
    if (
      (transactionTypeValue === "1") &
      ((transactionSubTypeValue === "Fixe" &&
        selectAuto({
          Aflossingstype: "Bullet",
          AflossingstypeValue: "1",
          Rentebasis: "12 maands euribor",
          dagtelling: "act/act",
          dagtellingValue: "1",
          Renteconventie: "(modified) Following",
        })) ||
        (transactionSubTypeValue === "Lineair" &&
          selectAuto({
            Aflossingstype: "Lineair",
            AflossingstypeValue: "3",
            Rentebasis: "12 maands euribor",
            dagtelling: "act/act",
            dagtellingValue: "1",
            Renteconventie: "(modified) Following",
          })) ||
        (transactionSubTypeValue === "Annuïtair" &&
          selectAuto({
            Aflossingstype: "Annuïtair",
            AflossingstypeValue: "2",
            Rentebasis: "12 maands euribor",
            dagtelling: "act/act",
            dagtellingValue: "1",
            Renteconventie: "(modified) Following",
          })))
    ) {
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
    } else if (
      (transactionTypeValue === "1") &
      (transactionSubTypeValue === "Roll-over variabel" ||
        transactionSubTypeValue === "Roll-over vast")
    ) {
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        dagtelling: "act/360",
        dagtellingValue: "2",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "interestConverionDate",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
    } else if (transactionTypeValue === "2") {
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      (transactionTypeValue === "3") &
      ((transactionSubTypeValue === "Roll-over variabel" &&
        selectAuto({
          Aflossingstype: "Bullet",
          AflossingstypeValue: "1",
          Rentebasis: "12 maands euribor",
          dagtelling: "act/360",
          dagtellingValue: "2",
          Renteconventie: "(modified) Following",
        })) ||
        (transactionSubTypeValue === "Roll-over vast" &&
          selectAuto({
            Aflossingstype: "Bullet",
            AflossingstypeValue: "1",
            Rentebasis: "12 maands euribor",
            dagtelling: "act/360",
            dagtellingValue: "2",
            Renteconventie: "(modified) Following",
          })))
    ) {
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "surchargeRevision",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      (transactionTypeValue === "3") &
      (transactionSubTypeValue === "Basisrentelening" &&
        selectAuto({
          Aflossingstype: "Bullet",
          AflossingstypeValue: "1",
          Rentebasis: "12 maands euribor",
          dagtelling: "act/act",
          dagtellingValue: "1",
          Renteconventie: "(modified) Following",
        }))
    ) {
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "surchargeRevision",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    }
    setTransection({ ...transection, transactionsubtype: e.target.value });
  };

  const handleCustomer = (value) => {
    let id = "";
    if (value !== "") {
      id = getCustomers.filter((item) => {
        if (item.name === value) {
          return item;
        }
      })[0].id;
    }
    setTransection({ ...transection, customerId: id });
  };

  const handleTransactionproposalnumber = (e) => {
    console.log(e);
    setTransactionNumber(e.target.value);
    setTransection({
      ...transection,
      transactionNo: e.target.value,
    });
  };

  const handleUserName = (e) => {
    setTransection({ ...transection, transactionProposal: e.target.value });
  };

  const getCustomerList = async () => {
    try {
      const resp = await TaskService.getCustomerList();
      const newStateData = resp.response.map((item) => {
        return { ...item, label: item.name, value: item.id };
      });

      setCustomerList(newStateData);

      // customerName
    } catch (error) {
      console.log("Info", error);
    }
  };

  // Get data once
  useEffect(() => {
    getCustomerList();
  }, []);

  const valueComparator = (getCustomers, updatedCustomer) => {
    return updatedCustomer === getCustomers.name;
  };

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">Basis</h5>
        </CardHeader>
        <CardBody>
          <Form className="" noValidate="">
            <Row className="g-3">
              <Col md="4">
                <Label className="form-label" for="customerId">
                  Klant
                </Label>
                <Autocomplete
                  value={updatedCustomer}
                  disablePortal
                  id="combo-box-demo"
                  options={getCustomers}
                  // getOptionLabel={getCustomers.name}
                  // sx={{ width: 300 }}
                  isOptionEqualToValue={valueComparator}
                  onInputChange={(event, newValue) => handleCustomer(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} className="form-control" />
                  )}
                />
                {errorState.includes("customerId") && (
                  <div>* Klant is vereist</div>
                )}
              </Col>
              <Col md="4">
                <Label className="form-label" for="transactionNo">
                  Transactie voorstel nummer
                </Label>
                <input
                  className="form-control"
                  id="transactionNo"
                  type="text"
                  value={transactionNumber}
                  name="transactionNo"
                  onChange={(e) => handleTransactionproposalnumber(e)}
                />
                {errorState.includes("transactionNo") && (
                  <div>*Transactie voorstel nummer is verplicht</div>
                )}
              </Col>
              <Col md="4">
                <Label className="form-label" for="transactionType">
                  Type transactie
                </Label>

                <select
                  name="transactionType"
                  id="transactionType"
                  onChange={(e) => handleTranTypeSelect(e)}
                  className="form-control form-control-primary btn-square form-select"
                >
                  {editFields?.transactionType ? (
                    <option value={transactionType}>
                      {`${
                        transactionFields.TransactionTypes[
                          editFields.transactionType - 1
                        ].name
                      }`}
                    </option>
                  ) : (
                    <option value="">Selecteer</option>
                  )}
                  {editField !== null &&
                    transactionFields?.TransactionTypes?.map((item, index) =>
                      // item.value == 1 ? setIsFlag(true) : setIsFlag(false)
                      editFields?.transactionType &&
                      item.value !== editFields.transactionType ? (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      ) : (
                        <option key={index} value={item.value}>
                          {item.name}
                        </option>
                      )
                    )}
                  {/* <option value="Nieuwe financiering">
                    Nieuwe financiering
                  </option>
                  <option value="Renteconversie">Renteconversie</option>
                  <option value="Opslagherziening">Opslagherziening</option> */}
                </select>
                {errorState.includes("transactionType") && (
                  <div>*Selecteer is verplicht</div>
                )}
              </Col>
              {transactionSubOptions && (
                <Col md="4">
                  <Label className="form-label" for="transactionsubtype">
                    Subniveau
                  </Label>

                  <select
                    name="transactionsubtype"
                    id="transactionsubtype"
                    onChange={handleTranSubTypeSelect}
                    className="form-control form-control-primary btn-square form-select"
                  >
                    {transactionSubOptions.map((value, index) => (
                      <option key={index} value={value} label={value} />
                    ))}
                  </select>
                  {errorState.includes("transactionsubtype") && (
                    <div>*Subniveau is vereist</div>
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default BasicFormEdit;
