import { H5, H6 } from "../../../AbstractElements";
import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

const Modaliteiten = ({ detail, isModel }) => {
  const principalAmountConverter = (number) => {
    const convertedNumber = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(number);
    return convertedNumber.slice(0, -1);
  };

  const dateTimeConverter = (date) => {
    const dateObj = new Date(Date.parse(date));
    const options = {
      timeZone: "Europe/Amsterdam",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const netherlandsTime = dateObj.toLocaleString("nl-NL", options);
    return netherlandsTime + " uur";
  };
  const dateConverter = (date) => {
    const dateObj = new Date(Date.parse(date));
    const options = {
      timeZone: "Europe/Amsterdam",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const netherlandsTime = dateObj.toLocaleString("nl-NL", options);
    return netherlandsTime;
  };

  const timeDurationFormat = (loanStartDate, loanEndDate) => {
    const endDay = new Date(loanEndDate);
    const endDateInSeconds = endDay.getTime() / 1000;
    const startDay = new Date(loanStartDate);
    const startDateInSeconds = startDay.getTime() / 1000;
    let differenceSeconds = endDateInSeconds - startDateInSeconds;
    const days = Math.floor(differenceSeconds / (24 * 60 * 60));
    const duration = days / 365;
    const durationWithDecimal = duration.toFixed(2).toString();
    const durationWithCommaString = durationWithDecimal.replace(".", ",");

    return `${durationWithCommaString} jaar`;
  };

  return (
    <Card>
      <CardHeader className="pb-0 mb-0">
        <div className="header-top d-sm-flex justify-content-between align-items-center ">
          <H5>Modaliteiten</H5>
        </div>
      </CardHeader>
      <CardBody>
        <Row style={{ margin: "0.0%" }}>
          {detail && isModel ? (
            <>
              <Col className="col-lg-3">
                <div className="mb-2">
                  <H6>Hoofdsom</H6>
                  <p>
                    {detail &&
                      "€ " + principalAmountConverter(detail.principalAmount)}
                  </p>
                </div>
              </Col>
              <Col className="col-lg-3">
                <div className="mb-2">
                  <H6>Looptijd</H6>
                  <p>
                  {detail?.loanDuration +" "+ 'jaar'}
                  </p>
                </div>
              </Col>
              <Col className="col-lg-3">
                {" "}
                <div className="mb-2">
                  <H6>Datum 1* rentecoupon</H6>
                  {detail?.firstCoupon && (
                    <p>{dateConverter(detail.firstCoupon)}</p>
                  )}
                </div>
              </Col>
              <Col className="col-lg-3">
                {" "}
                <div className="mb-2">
                  <H6>Stortingsdatum</H6>
                  {detail?.depositDate && (
                    <p>{dateConverter(detail.depositDate)} </p>
                  )}
                </div>
              </Col>
              <Col className="col-lg-3">
                {" "}
                <div className="mb-2">
                  <H6>Einddatum </H6>
                  {detail?.depositDate && (
                    <p>{dateConverter(detail.loanEndDate)} </p>
                  )}
                </div>
              </Col>
              {/* <Col className="col-lg-3">
                {" "}
                <div className="mb-2">
                  <H6>Stortingsdatum</H6>
                  {detail?.depositDate && (
                    <p>{dateConverter(detail.depositDate)} </p>
                  )}
                </div>
              </Col> */}
              <Col className="col-lg-3">
                <div className="mb-2">
                  <H6>Transactiedatum & tijd</H6>
                  {detail?.transactionStartDateTime && (
                    <p>{dateTimeConverter(detail.transactionStartDateTime)} </p>
                  )}
                </div>
              </Col>
              <Col className="col-lg-3">
                {" "}
                <div className="mb-2">
                  <H6>Aflossingstype</H6>
                  <p>{detail?.repaymentTypeValue}</p>
                </div>
              </Col>
              <Col className="col-lg-3">
                <div className="mb-2">
                  <H6>Garantie & Achtervang</H6>
                  <p>
                    {detail?.guaranteeValue === "" ? (
                      <p>Niet gespecificeerd</p>
                    ) : (
                      <p>{detail?.guaranteeValue}</p>
                    )}
                  </p>
                </div>
              </Col>
            </>
          ) : (
            <>
              <Col md={12} className="d-flex flex-row flex-wrap">
                <div className="mb-4 col-6">
                  <H6>Transactiedatum & tijd</H6>
                  {detail?.transactionStartDateTime && (
                    <p>{dateTimeConverter(detail.transactionStartDateTime)} </p>
                  )}
                </div>
                <div className="mb-4 col-6">
                  <H6>Type transactie</H6>
                  <p>{detail?.transactionTypeValue}</p>
                </div>
                <div className="mb-4 col-6">
                  <H6>Subniveau</H6>
                  <p>{detail?.transactionSubTypeValue}</p>
                </div>
                <div className="mb-4 col-6">
                  <H6>Hoofdsom</H6>
                  <p>
                    {detail &&
                      "€ " + principalAmountConverter(detail.principalAmount)}
                  </p>
                </div>

                {/* {detail?.currentFinancier !== null && (
                  <div className="mb-4 col-6">
                    <H6>Huidige geldgever</H6>
                    <p>{detail?.currentFinancier?.company?.name}</p>
                  </div>
                )} */}
                <div className="mb-4 col-6">
                  <H6>Stortingsdatum</H6>
                  {detail?.depositDate && (
                    <p>{dateConverter(detail.depositDate)} </p>
                  )}
                </div>
                <div className="mb-4 col-6">
                  <H6>Einddatum</H6>
                  {detail?.loanEndDate && (
                    <p>{dateConverter(detail?.loanEndDate)}</p>
                  )}
                </div>
                <div className="mb-4 col-6">
                  <H6>1e coupon</H6>
                  {detail?.firstCoupon && (
                    <p>{dateConverter(detail.firstCoupon)}</p>
                  )}
                </div>
                {detail?.interestConverionDate !== null && (
                  <div className="mb-4 col-6">
                    <H6>Renteconversiedatum</H6>
                    <p>{dateConverter(detail?.interestConverionDate)}</p>
                  </div>
                )}
                {detail?.clause !== 0 && (
                  <div className="mb-4 col-6">
                    <H6>Clausule</H6>
                    {detail?.clause === 1 ? "Ja" : "Nee"} 
                  </div>
                )}
                <div className="mb-4 col-6">
                  <H6>Aflossingstype</H6>
                  <p>{detail?.repaymentTypeValue}</p>
                </div>
                {detail?.interestBasis !== 0 && (
                  <div className="mb-4 col-6">
                    <H6>Rentebasis</H6>
                    <p>{detail?.interestBasisValue}</p>
                  </div>
                )}
                <div className="mb-4 col-6">
                  <H6>Looptijd</H6>
                  <p>
                  {detail?.loanDuration +" "+ 'jaar'}
                  </p>
                </div>
                <div className="mb-4 col-6">
                  <H6>Rentemethode</H6>
                  <p> {detail?.interestMethod}</p>
                </div>
                <div className="mb-4 col-6">
                  <H6>Dagtelling</H6>

                  <p>{detail?.dayCountValue}</p>
                </div>

                {/* </Col>

              <Col md={6}> */}
                <div className="mb-4 col-6">
                  <H6>Conventie</H6>
                  <p>{detail?.conventionValue}</p>
                </div>
                {detail?.guaranteeValue !== "" && (
                  <div className="mb-4 col-6">
                    <H6>Garantie & Achtervang</H6>
                    <p>{detail?.guaranteeValue}</p>
                  </div>
                )}

                {/* <div className="mb-4 col-6">
                  <H6>Clausule</H6>
                  <p>{detail?.clause}</p>
                </div> */}
              </Col>
            </>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default Modaliteiten;
