import { H2, P } from "../../../AbstractElements";
import React from "react";
import { Card, CardBody } from "reactstrap";

const IncomeCard = ({ iconClass, amount, title, percent, topline }) => {
  return (
    <Card className="income-card card-primary">
      <CardBody className="text-left">
        {/* <div className="round-box">{iconClass}</div> */}
        <ul className="m-b-15">
          <li>{topline}</li>
        </ul>
        <H2 className="text-left">{amount}</H2>
        <P>{title}</P>
        {/* <a className="btn-arrow arrow-primary" href="#javascript">
          <i className="toprightarrow-primary fa fa-arrow-up me-2"></i>
          {percent}{" "}
        </a> 
        <div className="parrten">{iconClass}</div>*/}
      </CardBody>
    </Card>
  );
};

export default IncomeCard;
