import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";

const Aankomendetransacties = ({ upcomingTransaction }) => {
  // const [upcomingTransaction, setUpcomingTransaction] = useState([]);
  // useEffect(() => {
  //   const getListofUpcomingTransaction = async () => {
  //     const response = await DashboardService.getEmployee();
  //     const listofUpcomingTransaction =
  //       response?.response?.listofUpcomingTransaction;
  //     setUpcomingTransaction(listofUpcomingTransaction.slice(0, 6));
  //   };
  //   getListofUpcomingTransaction();
  //   return () => {
  //     setUpcomingTransaction([]);
  //   };
  // }, []);

  return (
    // <Card className="full-width full-height">
    <Card style={{ height: "100%" }}>
      <CardHeader>
        <div className="header-top d-flex justify-content-between align-items-center">
          <h5>Aankomende transacties</h5>

          {/* <Link to="/upcoming-transactions">
            <h6 style={{ color: "#FF9E00" }}> Bekijk alles</h6>
          </Link> */}
        </div>
      </CardHeader>
      <CardBody>
        <div className="table-responsive">
          <table className="table table-bordernone m-0">
            <tbody>
              {upcomingTransaction.length === 0 ? (
                <tr
                  style={{
                    color: "#212F56",
                    marginTop: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    textAlign: "center",
                    opacity: "0.7",
                  }}
                >
                  <td> Nog geen transacties bekend.</td>
                </tr> //removed whitespace error
              ) : (
                upcomingTransaction.map((item) => (
                  <tr key={item.transactionId}>
                    <td
                      style={{
                        borderBottom: "1px solid #ced4da",
                      }}
                    >
                      <Link
                        to={`/transacties/dashboard/${item.identifier}`}
                        // to="/transacties/dashboard/"+item.transactionId
                        // to="/transacties/dashboard/"+item.transactionId
                      >
                        <div
                          className="media"
                          // style={{
                          //   backgroundColor: "#FF9E0017",
                          // }}
                        >
                          <div className="bg-secondary  dateicon">
                            {item?.date.replace("-", "\n")}
                          </div>
                          <div
                            className="media-body"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <span>{item.customerName}</span>
                            <p>{item.time.split("-")[0]}</p>
                          </div>
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default Aankomendetransacties;
