// import { LoaderBar } from 'components';
import React, { createContext, useState, useContext, useEffect, useMemo } from 'react'
import { Navigate } from 'react-router-dom';
import { AuthService } from 'utility';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const setData = () => {
        const authenticated = AuthService.isAuthenticated()
        setIsAuthenticated(authenticated);
        if (authenticated) {
            const user = AuthService.getUser();
            setUser({ ...user, "userType": user.userRoleId });
        }
    }

    useEffect(() => {
        const loadToken = async () => {
            setData();
            setLoading(false)
        }
        loadToken()
    }, [])

    const login = (data) => {
        AuthService.storeToken(data.token)
        AuthService.storeUser(data)
        setUser({ ...data, "userType": data.userRoleId });
        setIsAuthenticated(true);
    }

    const logout = () => {
        AuthService.logout();
        setIsAuthenticated(false);
    }

    const contextData = useMemo(() => ({
        loading,
        user,
        isAuthenticated,
        login,
        logout,
    }), [loading, user, isAuthenticated, login, logout])

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectRoute = ({
    redirectPath = '/',
    roles = [],
    children,
}) => {
    const { user, isAuthenticated, loading } = useAuth();
    if (loading) {
        return null;
    }
    if (!isAuthenticated || (roles.length > 0 && !AuthService.hasRole(roles, user.userType))) {
        return <Navigate to={redirectPath} replace />
    }

    return (<>
        {children}
    </>)
}
