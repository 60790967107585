export const ErrorConstant = {
    default: 'Something went wrong',
}

export const CommonConstant = {
    defaultPageSize: 20,
}

export const StorageConstant = {
    token: 'token',
    user: 'user',
}

export const UserRoles = {
    'admin': 1,
    'employee': 2,
    'financier': 3,
}

export const UserRolesName = {
    1: 'admin',
    2: 'employee',
    3: 'financier',
}