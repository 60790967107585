import React from "react";
import Routers from "./Route";
// import ChartjsProvider from "./_helper/Chartjs/ChartProvider";
// import ContactProvider from "./_helper/Contact/ContactProvider";
// import TaskProvider from "./_helper/Task/TaskProvider";
// import TableProvider from "./_helper/Table/TableProvider";
// import BookmarkProvider from "./_helper/Bookmark/BookmarkProvider";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { AuthProvider } from "context/user";
import * as Sentry from "@sentry/react";

const App = () => (
  <div className="App">
    <AuthProvider>
      <CustomizerProvider>
        {/* <FaqProvider> */}
        {/* <LearningProvider> */}
        {/* <SearchResultProvider> */}
        {/* <TodoProvider> */}
        {/* <BookmarkProvider> */}
        {/* <TableProvider> */}
        {/* <GalleryProvider> */}
        {/* <TaskProvider> */}
        {/* <ContactProvider> */}
        {/* <ChartjsProvider> */}
        {/* <ChartistProvider> */}
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
        {/* </ChartistProvider> */}
        {/* </ChartjsProvider> */}
        {/* </ContactProvider> */}
        {/* </TaskProvider> */}
        {/* </GalleryProvider> */}
        {/* </TableProvider> */}
        {/* </BookmarkProvider> */}
        {/* </TodoProvider> */}
        {/* </SearchResultProvider> */}
        {/* </LearningProvider> */}
        {/* </FaqProvider> */}
      </CustomizerProvider>
    </AuthProvider>
  </div>
);

// export default App;
export default Sentry.withProfiler(App);
