import { DealerBox } from "../../Common/Data/Dashboard";
import { H5, H6, Image, P } from "../../../AbstractElements";
import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Label } from "reactstrap";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { fontSize } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import { ModelTraining } from "@mui/icons-material";
import { RandvoorwaardenFullScreenView } from "./RandvoorwaardenFullScreenView";
import CommonModal from "../../UiKits/Modals/common/modal";

const Randvoorwaarden = ({ conditions }) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const popupTitle = "Randvoorwaarden";
  const [displayNone] = useState("d-none");

  var settings = {
    dots: true,
    infinite: true,
    centerMode: false,
    speed: 100,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    adaptiveHeight: true,
    centerPadding: "10px",
    responsive: [
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <Card>
        <CardHeader className="pb-0">
          <div className="d-flex justify-content-between align-items-center ">
            <div>
              <H5>Randvoorwaarden </H5>
            </div>
            <div>
              <IconButton onClick={() => toggle()}>
                <h6 style={{ color: "#FF9E00" }}>Bekijken</h6>
              </IconButton>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="item">
            <Row>
              <Col>
                {conditions.length > 0 ? (
                  <div>
                    {conditions?.map((item) => (
                      <div
                        className="item"
                        key={item}
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        <div
                          className="top-dealerbox"
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          <H5
                            className="font-105rem"
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            {item}
                          </H5>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="item" style={{ whiteSpace: "break-spaces" }}>
                    <div
                      className="top-dealerbox"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      <H5
                        className="font-105rem"
                        style={{ whiteSpace: "break-spaces" }}
                      >
                        Er zijn geen randvoorwaarden toegevoegd
                      </H5>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      <CommonModal
        isOpen={modal}
        title={popupTitle}
        toggler={toggle}
        size="xl"
        // displayNone={displayNone}
      >
        {" "}
        <RandvoorwaardenFullScreenView
          conditions={conditions}
        ></RandvoorwaardenFullScreenView>
      </CommonModal>
    </Fragment>
  );
};

export default Randvoorwaarden;
