import { useEffect, useState } from "react";
import { ErrorConstant } from "utility";
import { CustomerService } from "utility/services/customer";
export const GetCustomerList = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await CustomerService.getCustomerList();
        setData(result.response || {});
      } catch {
        setError(ErrorConstant.default);
      } finally {
        setLoading(false);
      }
    };

    if (!loading) {
      fetchData();
    }
  }, []);

  return {
    data,
    loading,
    error,
    setData,
  };
};

export const GetCustomerTypes = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await CustomerService.getCustomerTypes();
        setData(result.response || {});
      } catch {
        setError(ErrorConstant.default);
      } finally {
        setLoading(false);
      }
    };

    if (!loading) {
      fetchData();
    }
  }, []);

  return {
    data,
    loading,
    error,
    setData,
  };
};

export const GetCustomerById = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await CustomerService.getCustomerById();
        setData(result.response || {});
      } catch {
        setError(ErrorConstant.default);
      } finally {
        setLoading(false);
      }
    };

    if (!loading) {
      fetchData();
    }
  }, []);

  return {
    data,
    loading,
    error,
    setData,
  };
};

export const SetCustomerList = (requestData) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await CustomerService.setCustomerList(requestData);
        setData(result.response || {});
      } catch {
        setError(ErrorConstant.default);
      } finally {
        setLoading(false);
      }
    };

    if (!loading) {
      fetchData();
    }
  }, []);

  return {
    data,
    loading,
    error,
    setData,
  };
};
