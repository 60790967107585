import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Table,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

import { NewMessage } from "../../../Constant";
import TableContext from "../../../_helper/Table";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Btn } from "../../../AbstractElements";
import axios from "axios";
import { TaskApi } from "../../../api";
import { FinancerService } from "utility";
import FinanciersFormCheckListComponentEdit from "./FinanciersFormCheckListComponentEdit";

const FinanciersFormEdit = (financiersData) => {
  const {
    financialTypeIds,
    setIsEmailTemplateEnable,
    isNextButtonEnable,
    financiersTranChecklistSel,
    setFinanciersTranChecklistSel,
    setFinancialTypeIds,
    transaction,
    errorState,
    checklistSelect,
    employeeDisplayModal,
    setEmployeeDisplayModal,
    transactionFields,
    editFields,
  } = financiersData;
  // const { data } = useContext(TableContext);
  const [modal, setModal] = useState(false);
  const [isEmailTemplateEnable, setEmailTemplateEnable] = useState(false);
  const [trainsactionId, setTransactionId] = useState(0);
  const toggle = () => setModal(!modal);

  const [displayNone] = useState("d-none");
  const ModalTitle = "Selecteer Financiers";
  const [financiersCheckList, setFinanciersCheckList] = useState([]);
  const [noFinancerError, setNoFinancerError] = useState(false);

  const getFinancierData = async () => {
    try {
      const resp = await FinancerService.getFinancerList();

      const newStateData = resp.response.map((item) => {
        return { ...item, checked: false };
      });
      setFinanciersCheckList(newStateData);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get data once
  useEffect(() => {
    getFinancierData();
  }, []);

  const onItemChecked = (item) => {
    const newFinanciersCheckList = financiersCheckList.map((obj) => {
      if (item.id === obj.id) {
        return { ...obj, checked: item.checked ? false : true };
      }
      return obj;
    });
    setFinanciersCheckList(newFinanciersCheckList);
    setFinanciersTranChecklistSel(
      newFinanciersCheckList
        .filter((item) => {
          if (item.checked) return true;
        })
        .map((item) => {
          return item.id;
        })
    );
    checklistSelect(newFinanciersCheckList);
    // console.log(financialFinanciersChecklistSel);
  };
  const emailToggle = () => {
    if (financiersTranChecklistSel.length > 0) {
      setNoFinancerError(false);
      setEmployeeDisplayModal(!employeeDisplayModal);
    }
    setNoFinancerError(true);
  };
  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="color-secondary font-w800">Financiers</h5>
            <Btn attrBtn={{ color: "primary", onClick: toggle }}>Toevoegen</Btn>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  {/* <th scope="col"></th> */}
                  <th scope="col">Naam</th>
                  {/* <th scope="col">Code</th> */}
                  {/* <th scope="col">Email</th> */}
                  <th scope="col">Toegekend Graaf</th>
                  <th scope="col">deelgenomen</th>
                </tr>
              </thead>
              <tbody>
                {financiersTranChecklistSel?.length > 0 ? (
                  financiersCheckList
                    .filter((_item) => {
                      if (_item.checked) return true;
                    })
                    .map((item) => (
                      <tr key={item?.id || item?.taskId}>
                        {/* <td>{item?.id || item?.taskId}</td> */}
                        <td>{item.name || item?.taskName}</td>
                        {/* <td>{item.code}</td> */}
                        {/* <td>
                      {item.email}
                    </td> */}
                        <td>{item.awarded}</td>
                        <td>{item.participated}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>No data found</td>
                  </tr>
                )}
              </tbody>
              {errorState.includes("financiersTranChecklistSel") && (
                // <th>*Financiers is vereist</th>
                <tr>
                  <td>*Financiers is vereist</td>
                </tr>
              )}
            </Table>
          </div>
        </CardBody>
      </Card>
      <CommonModal
        isOpen={modal}
        title={ModalTitle}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
      >
        <FinanciersFormCheckListComponentEdit
          data={financiersCheckList}
          setFinancialTranChecklistSel={setFinanciersTranChecklistSel}
          setFinancialTypeIds={setFinancialTypeIds}
          financialTranChecklistSel={financiersTranChecklistSel}
          setTranCheckList={setFinanciersCheckList}
        />
      </CommonModal>
      {noFinancerError && <p>*Financiers is vereist</p>}
      {isNextButtonEnable && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Btn attrBtn={{ color: "primary", onClick: () => emailToggle() }}>
            Volgende
          </Btn>
        </div>
      )}
      {/* {isEmailTemplateEnable && (
        <TransactionMail
          financialTypeIds={financialTypeIds}
          transection={transaction.detail}
          // trainsactionId={trainsactionId}
          data={financiersCheckList}
          financialTranChecklistSel={financiersTranChecklistSel}
          isEnable={isEmailTemplateEnable}
        ></TransactionMail>
      )} */}
    </Fragment>
  );
};

export default FinanciersFormEdit;
