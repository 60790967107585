import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Modaliteiten from "../TransactiesDashboard/Modaliteiten";
import CardData from "../TransactiesDashboard/CardData";

import TTC from "../TransactiesDashboard/Ttc";
import Randvoorwaarden from "../TransactiesDashboard/Randvoorwaarden";
import InfoCard from "../TransactiesDashboard/InfoCard";
import LineChartWithAreaClass from "../../Charts/ChartistCharts/LineChartWithArea";
import ChartistContext from "../../../_helper/Chartist";

const DashboardLineChart = () => {
  const chartData = useContext(ChartistContext);
  const [minutes, setMinutes] = useState(14);
  const [seconds, setSeconds] = useState(59);
  var timer;
  useEffect(() => {
    timer = setInterval(() => {
      setSeconds(seconds - 1);
      if (seconds === 0) {
        if ((minutes === 0 || minutes < 0) && seconds === 0) {
          setMinutes(0);
          setSeconds(0);
        }
        setMinutes(minutes - 1);
        setSeconds(59);
      }
    }, 1000);
    return () => clearInterval(timer);
  });
  return (
    <Container fluid={true} className="dashboard-2-main">
      <div className="d-flex justify-content-between align-items-center mt-2 mb-2">
        <h4>Transctie Dashboard</h4>
        <h5>Aantal minuten actief : {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds} </h5>
      </div>
      <Row>
        <Col xl="8" className="des-xl-100 box-col-12">
          <Row>
            <Col xl="7" className="col-xl-7 box-col-7 top-sell-sec">
              <LineChartWithAreaClass chartData={chartData.chartistData} />
            </Col>
            <Col xl="5" className="col-xl-5 box-col-5 top-sell-sec">
              <CardData />
              <InfoCard />
            </Col>
          </Row>
        </Col>
        <Col
          xl="4"
          className="des-xl-50 box-col-12 activity-sec chart_data_left"
        >
          <TTC />
        </Col>

        <Col xl="4" className="box-col-12 des-xl-100 top-dealer-sec">
          <Randvoorwaarden />
        </Col>
      </Row>
    </Container>
  );
};

export default DashboardLineChart;
