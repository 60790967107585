import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Table } from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Btn } from "../../../AbstractElements";
import FinanciersFormCheckListComponent from "./FinanciersFormCheckListComponent";
import { FinancerService } from "utility";
import { TransactionMail } from "./TransactionMail";

const FinanciersForm = (financiersData) => {
  const {
    financialTypeIds,
    setIsEmailTemplateEnable,
    isNextButtonEnable,
    financiersTranChecklistSel,
    setFinanciersTranChecklistSel,
    setFinancialTypeIds,
    transaction,
    errorState,
    checklistSelect,
    employeeDisplayModal,
    setEmployeeDisplayModal,
  } = financiersData;
  const [modal, setModal] = useState(false);
  // console.log(transaction, financiersTranChecklistSel);
  const [isEmailTemplateEnable, setEmailTemplateEnable] = useState(false);
  const [currentLenderId, setCurrentLenderId] = useState(null);
  // const [trainsactionId, setTransactionId] = useState(0);
  const toggle = () => setModal(!modal);

  const [displayNone] = useState("d-none");
  const ModalTitle = "Selecteer Financiers";
  const [financiersCheckList, setFinanciersCheckList] = useState([]);
  const [noFinancerError, setNoFinancerError] = useState(false);
  // console.log(financiersCheckList);
  const getFinancierData = async () => {
    try {
      const resp = await FinancerService.getFinancerList();

      const newStateData = resp.response.map((item) => {
        return { ...item, checked: false };
      });
      setFinanciersCheckList(newStateData);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (transaction?.currentFinancierId.length > 0) {
      setCurrentLenderId(parseInt(transaction?.currentFinancierId));
      const currentTransactionId = parseInt(transaction?.currentFinancierId);
      const checkedArray = [...financiersTranChecklistSel];
      const currentTransactionIdObjIdx = checkedArray.findIndex(
        (id) => id === currentTransactionId
      );
      if (currentTransactionIdObjIdx === -1) {
        checkedArray.push(currentTransactionId);
        setFinanciersTranChecklistSel(checkedArray);
      }
      return;
    } else {
      setCurrentLenderId(null);
    }
  }, [transaction?.currentFinancierId, financiersTranChecklistSel]);
  // console.log(currentLenderId);
  // Get data once
  useEffect(() => {
    getFinancierData();
  }, []);

  // const onItemChecked = (item) => {
  //   const newFinanciersCheckList = financiersCheckList.map((obj) => {
  //     if (item.id === obj.id) {
  //       return { ...obj, checked: item.checked ? false : true };
  //     }
  //     return obj;
  //   });
  //   setFinanciersCheckList(newFinanciersCheckList);
  //   setFinanciersTranChecklistSel(
  //     newFinanciersCheckList
  //       .filter((item) => {
  //         if (item.checked) return true;
  //       })
  //       .map((item) => {
  //         return item.id;
  //       })
  //   );
  //   checklistSelect(newFinanciersCheckList);
  // };
  const emailToggle = () => {
    if (financiersTranChecklistSel.length > 0) {
      setNoFinancerError(false);
      setEmployeeDisplayModal(!employeeDisplayModal);
    }
    setNoFinancerError(true);
  };

  // console.log(financiersTranChecklistSel);

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="color-secondary font-w800">Financiers</h5>
            <Btn attrBtn={{ color: "primary", onClick: toggle }}>Toevoegen</Btn>
          </div>
        </CardHeader>
        <CardBody>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  {/* <th scope="col"></th> */}
                  <th scope="col">Naam</th>
                  {/* <th scope="col">Code</th> */}
                  {/* <th scope="col">Email</th> */}
                  {/* <th scope="col">Toegekend Graaf</th>
                  <th scope="col">deelgenomen</th> */}
                  <th scope="col">Financier Type</th>
                </tr>
              </thead>
              <tbody>
                {financiersTranChecklistSel?.length > 0 ? (
                  financiersCheckList
                    .filter((_item) => {
                      if (_item.checked) return true;
                    })
                    .map((item) => (
                      <tr key={item?.id || item?.taskId}>
                        {/* <td>{item?.id || item?.taskId}</td> */}
                        <td>{item.name || item?.taskName}</td>
                        {/* <td>{item.code}</td> */}
                        {/* <td>
                      {item.email}
                    </td> */}
                        {/* <td>{item.awarded}</td> */}
                        <td>{item.financierType}</td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td>Geen data</td>
                  </tr>
                )}
              </tbody>
              {errorState.includes("financiersTranChecklistSel") && (
                // <th>*Financiers is vereist</th>
                <tr>
                  <td>*Financiers is vereist</td>
                </tr>
              )}
            </Table>
          </div>
        </CardBody>
      </Card>
      <CommonModal
        isOpen={modal}
        title={ModalTitle}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
      >
        <FinanciersFormCheckListComponent
          data={financiersCheckList}
          setFinancialTranChecklistSel={setFinanciersTranChecklistSel}
          currentLenderId={currentLenderId}
          setFinancialTypeIds={setFinancialTypeIds}
          financialTranChecklistSel={financiersTranChecklistSel}
          setTranCheckList={setFinanciersCheckList}
          toggler={toggle}
        ></FinanciersFormCheckListComponent>
      </CommonModal>
      {noFinancerError && <p>*Financiers is vereist</p>}
      {isNextButtonEnable && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Btn attrBtn={{ color: "primary", onClick: () => emailToggle() }}>
            Volgende
          </Btn>
        </div>
      )}
      {isEmailTemplateEnable && (
        <TransactionMail
          financialTypeIds={financialTypeIds}
          transection={transaction.detail}
          // trainsactionId={trainsactionId}
          data={financiersCheckList}
          financialTranChecklistSel={financiersTranChecklistSel}
          isEnable={isEmailTemplateEnable}
        ></TransactionMail>
      )}
    </Fragment>
  );
};

export default FinanciersForm;
