import { useEffect, useState } from 'react'
import {
    ErrorConstant,
    FinancerService,
} from 'utility'

export const GetFinancerList = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await FinancerService.getFinancerList()
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}

export const GetFinancierEmployees = (id) => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await FinancerService.getFinancierEmployees(id)
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}

export const GetFinancerType = () => {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await FinancerService.getFinancerType()
                setData(result.response || {})
            } catch {
                setError(ErrorConstant.default)
            } finally {
                setLoading(false)
            }
        }

        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        loading,
        error,
        setData,
    }
}