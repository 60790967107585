import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from 'utility/constant'

class Auth {

    login(reqData) {
        return BaseService.post(`${APIPath.login}`, reqData, false)
    }

    uploadFile(reqData) {
        return BaseService.upload(`${APIPath.getContextFromPDF}`, reqData, true)
    }

    validate2FACode(data) {
        return BaseService.post(`${APIPath.validate2FACode}`, data, true)
    }

    reset2FA() {
        return BaseService.post(`${APIPath.reset2FA}`, true)
    }

    get2FAQRcodeImage() {
        return BaseService.get(`${APIPath.get2FAQRcodeImage}`)
    }

    storeToken(token) {
        BrowserUtility.save(StorageConstant.token, token)
    }

    storeUser(user) {
        BrowserUtility.saveObj(StorageConstant.user, user)
    }

    getToken() {
        return BrowserUtility.get(StorageConstant.token) || ''
    }

    getUser() {
        return BrowserUtility.getObj(StorageConstant.user);
    }

    logout() {
        BrowserUtility.removeAll()
    }

    isAuthenticated() {
        const token = this.getToken()
        return !!token
    }

    hasRole(roles, userType) {
        return (roles || []).length === 0 || roles.some(x => x === userType)
    }

}

const AuthService = new Auth()
Object.freeze(AuthService)
export { AuthService }
