import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from 'utility/constant'

class Dashboard {

    getEmployee(reqData) {
        return BaseService.get(`${APIPath.getEmployeeDashboard}`)
    }

    getTransaction(id) {
        return BaseService.get(`${APIPath.transactionDashboard}?transactionid=${id}`)
    }

    getFinancierTransaction(id) {
        return BaseService.get(`${APIPath.transactionFinancierDashboard}?transactionid=${id}`)
    }

    getChartData() {
        return BaseService.get(`${APIPath.getChartData}`)
    }

}

const DashboardService = new Dashboard()
Object.freeze(DashboardService)
export { DashboardService }
