import { BaseService } from "./base";
import { BrowserUtility } from "../browser-utility";
import { APIPath, StorageConstant } from "utility/constant";

class Transcties {
  getSwapRate() {
    return BaseService.get(`${APIPath.getSwaprate}`);
  }

  getSwapRateValue(identifier) {
    return BaseService.get(`${APIPath.getSwaprateValue}?id=${identifier}`);
  }

  getTransactionList(reqData) {
    return BaseService.get(`${APIPath.getTransactions}`);
  }

  getTransactionFields(reqData) {
    return BaseService.get(`${APIPath.getTransactionFields}`);
  }

  getTransactionResentList(reqData) {
    return BaseService.get(`${APIPath.transactionResentList}`);
  }

  getRecentlyCompletedTransactions(reqData) {
    return BaseService.get(`${APIPath.getRecentlyCompletedTransactions}`);
  }

  getTransactionUpcommingList(reqData) {
    return BaseService.get(`${APIPath.transactionUpcommingList}`);
  }

  setTransaction(reqData) {
    return BaseService.post(`${APIPath.setTransaction}`, reqData, true);
  }

  GetEmailTemplateList(
    financierId,
    transactionType,
    transactionSubType,
    existingFinancer
  ) {
    return BaseService.get(
      `${APIPath.GetEmailTemplateList}?financierTypeIds=${financierId}&transactionType=${transactionType}&transactionSubType=${transactionSubType}&existingFinancer=${existingFinancer}`
    );
  }

  setEmailTemplatee(reqData) {
    return BaseService.post(`${APIPath.TransactionMailSend}`, reqData, true);
  }

  rejectTransactionProposal(reqData) {
    return BaseService.post(
      `${APIPath.rejectTransactionProposal}`,
      reqData,
      true
    );
  }

  setTransactionoffer(reqData) {
    return BaseService.post(`${APIPath.setTransactionoffer}`, reqData, true);
  }

  deleteTransaction(transactionId) {
    return BaseService.post(
      `${APIPath.deleteTransaction}?identifier=${transactionId}`
    );
  }

  completeTransaction(identifier) {
    return BaseService.post(`${APIPath.completeTransaction}?id=${identifier}`);
  }

  getTransactionOfferLogs(transactionId) {
    return BaseService.get(
      `${APIPath.getTransactionOfferLogs}?proposalId=${transactionId}`
    );
  }

  getProposals(transactionId) {
    return BaseService.get(
      `${APIPath.getProposals}?transactionId=${transactionId}`
    );
  }
  getTTC(transactionId) {
    return BaseService.get(`${APIPath.getTTC}?transactionId=${transactionId}`);
  }

  setTransactionOfferAccept(reqData) {
    return BaseService.post(
      `${APIPath.setTransactionOfferAccept}`,
      reqData,
      true
    );
  }
}

const TransctiesService = new Transcties();
Object.freeze(TransctiesService);
export { TransctiesService };
