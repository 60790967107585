import React, { useContext, useState, Fragment } from "react";
import { AlignCenter } from "react-feather";
import { Link } from "react-router-dom";
import { Image } from "../../AbstractElements";
import CheckContext from "../../_helper/Customizer";
import { useAuth } from "context/user";

const Leftbar = () => {
  const { mixLayout, toggleSidebar, toggleIcon } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);

  const { user } = useAuth();
  const id = user.userRoleId;
  let roleName;
  if (id === 1) {
    roleName = "admin";
  } else if (id === 2) {
    roleName = "employee";
  } else if (id === 3) {
    roleName = "financiers";
  } else {
    return null;
  }

  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };

  return (
    <Fragment>
      <div className="main-header-left">
        {mixLayout ? (
          <div className="logo-wrapper">
            {
              <Link to={`${process.env.PUBLIC_URL}/${roleName}-dashboard`}>
                <Image
                  attrImage={{
                    className: "img-fluid d-inline",
                    src: `${require("../../assets/images/logo/logo.png")}`,
                    alt: "",
                  }}
                />
              </Link>
            }
          </div>
        ) : (
          <div className="dark-logo-wrapper">
            {
              <Link to={`${process.env.PUBLIC_URL}/${roleName}-dashboard`}>
                <Image
                  attrImage={{
                    className: "img-fluid d-inline",
                    src: `${require("../../assets/images/logo/logo.png")}`,
                    alt: "",
                  }}
                />
              </Link>
            }
          </div>
        )}
        <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
          <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
        </div>
      </div>
    </Fragment>
  );
};

export default Leftbar;
