import React, { useState, useEffect } from "react";
import { Card, CardBody, Input, Label } from "reactstrap";
import { TaskService, TransctiesService } from "utility";
import { useAuth } from "context/user";

const TTC = ({
  setTTCPassed,
  isTTCPassed,
  // taskList,
  details,
  transactionId,
  disableTTC,
}) => {
  // console.log(details);
  const [ttcDataset, setTTCDateset] = useState();
  // const [wingmanCheckboxEnable, setWingmanCheckboxEnable] = useState(false);
  const [ttcTaskList, setttcTasklist] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const newTtcDataset = ttcTaskList?.map((obj) => {
      return {
        ...obj,
        passed:
          obj.checkedByDealer === true && obj.checkedByWingman === true
            ? true
            : false,
      };
    });
    setTTCDateset(newTtcDataset);
    if (newTtcDataset?.length > 0) {
      setTTCPassed(
        newTtcDataset?.filter((item) => {
          if (item.passed === true) return true;
        }).length === newTtcDataset.length
          ? true
          : false
      );
    }
  }, [ttcTaskList]);

  useEffect(() => {
    if (transactionId !== null) {
      getCurrentTTC(transactionId);
    }
    const interval = setInterval(() => {
      getCurrentTTC(transactionId);
    }, 10000);

    return () => clearInterval(interval);
  }, [transactionId]);

  const getCurrentTTC = async (id) => {
    const transactionlog = await TransctiesService.getTTC(id);
    const tTCArray = await transactionlog.response;
    setttcTasklist(tTCArray);
  };

  // useEffect(() => {
  //   if (ttcDataset?.length > 0) {
  //     const checkListArray = ttcDataset.filter(
  //       (item) => item.checkedByDealer === true
  //     );
  //     if (checkListArray.length === ttcDataset.length) {
  //       setWingmanCheckboxEnable(true);
  //     } else {
  //       setWingmanCheckboxEnable(false);
  //       return;
  //     }
  //   } else return;
  // }, [ttcDataset]);

  const onClickFOhandler = (item) => {
    const newTtcDataset = ttcDataset.map((obj) => {
      if (item.checklistId === obj.checklistId) {
        return {
          ...obj,
          checkedByDealer: item.checkedByDealer ? false : true,
          passed:
            item.checkedByDealer === false && item.checkedByWingman === true
              ? true
              : false,
        };
      }
      return obj;
    });

    setTTCPassed(
      newTtcDataset.filter((item) => {
        if (item.passed === true) return true;
      }).length === newTtcDataset.length
        ? true
        : false
    );
    setTTCDateset(newTtcDataset);
    // setTask({
    //   "userId": user?.userId,
    //   "transactionId": details?.transactionId,
    //   "listOfTaskCheck": [
    //     {
    //       "transactionTaskId": item?.transactionTaskId,
    //       "taskId": item?.taskId,
    //       "isFOUser": item.isFOUser ? false : true,
    //       "isFOAUser": item.isFOAUser
    //     }
    //   ]
    // })
    setTask({
      transactionId: details?.id,
      checklistId: item.checklistId,
      checkedByDealer: item.checkedByDealer ? false : true,
      checkedByWingman: item.checkedByWingman,
    });
  };

  const setTask = async (obj) => {
    try {
      const resp = await TaskService.setTransactionTaskCheck(obj);
    } catch (error) {
      console.log("Info", error);
    }
  };

  const onClickFOAhandler = (item) => {
    const newTtcDataset = ttcDataset.map((obj) => {
      if (item.checklistId === obj.checklistId) {
        return {
          ...obj,
          checkedByWingman: item.checkedByWingman ? false : true,
          passed:
            item.checkedByWingman === false && item.checkedByDealer === true
              ? true
              : false,
        };
      }
      return obj;
    });

    setTTCPassed(
      newTtcDataset.filter((item) => {
        if (item.passed === true) return true;
      }).length === newTtcDataset.length
        ? true
        : false
    );
    setTTCDateset(newTtcDataset);

    // setTask({
    //   "userId": user?.userId,
    //   "transactionId": details?.transactionId,
    //   "listOfTaskCheck": [
    //     {
    //       "transactionTaskId": item?.transactionTaskId,
    //       "taskId": item?.taskId,
    //       "isFOAUser": item.isFOAUser ? false : true,
    //       "isFOUser": item.isFOUser
    //     }
    //   ]
    // })

    setTask({
      transactionId: details?.id,
      checklistId: item.checklistId,
      checkedByWingman: item.checkedByWingman ? false : true,
      checkedByDealer: item.checkedByDealer,
    });
  };

  return (
    <Card>
      {details?.checkLists.length === 0 ? (
        <CardBody>
          <h4>TTC</h4>
          <p>Voor dit klanttype is TTC niet vereist</p>
        </CardBody>
      ) : (
        <CardBody>
          <h4>TTC</h4>
          <div className="col-sm-12">
            <div className="table-responsive">
              <table className="table table-hover table-compact">
                <thead>
                  <tr>
                    <th>
                      {/* <div className="checkbox checkbox-dark"> */}
                      {/* <Input
                          id="inline-main"
                          type="checkbox"
                          disabled={true}
                        /> */}
                      <div>
                        <Label
                          htmlFor="inline-main"
                          style={{ fontWeight: "bold" }}
                        >
                          Taak
                        </Label>
                      </div>
                    </th>
                    <th scope="col">D</th>
                    <th scope="col">W</th>
                  </tr>
                </thead>
                <tbody>
                  {ttcDataset?.map((item) => (
                    <tr key={item.checklistId}>
                      <td>
                        <div>
                          {/* <div className="checkbox checkbox-dark"> */}
                          {/* <Input
                            type="checkbox"
                            disabled={true}
                            checked={item?.passed}
                          /> */}
                          <Label
                            style={{
                              fontWeight: "200 !important",
                              fontSize: "15px",
                              padding: "5px",
                            }}
                          >
                            {item.checklistTitle}
                          </Label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox checkbox-dark">
                          <Input
                            id={item.checklistId}
                            disabled={
                              details?.status === "1" ||
                              details?.status == "4" ||
                              details?.status == "5" ||
                              disableTTC === true
                                ? true
                                : details?.dealerUserId === user?.userId
                                ? false
                                : true
                            }
                            type="checkbox"
                            checked={item.checkedByDealer}
                            onChange={() => onClickFOhandler(item)}
                          />
                          <Label htmlFor={item.checklistId}>{Option}</Label>
                        </div>
                      </td>
                      <td>
                        <div className="checkbox checkbox-dark">
                          <Input
                            id={"a" + item.checklistId}
                            disabled={
                              details?.status === "1" ||
                              details?.status == "4" ||
                              details?.status == "5" ||
                              disableTTC === true
                                ? // wingmanCheckboxEnable === false
                                  true
                                : details?.wingmanUserId === user?.userId
                                ? false
                                : true
                            }
                            type="checkbox"
                            checked={item.checkedByWingman}
                            onChange={() => onClickFOAhandler(item)}
                          />
                          <Label htmlFor={"a" + item.checklistId}>
                            {Option}
                          </Label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </CardBody>
      )}
    </Card>
  );
};

export default TTC;
