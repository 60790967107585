import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from 'utility/constant'

class Tasks {

    getTasksList(customerId, transactionType) {
        if (customerId && transactionType) {
            return BaseService.get(`${APIPath.tasksList}?customerId=${customerId}&transactionType=${transactionType}`)
        }
    }

    setTask(reqData) {
        return BaseService.post(`${APIPath.setTask}`, reqData, true)
    }

    setTransactionTaskCheck(reqData) {
        return BaseService.post(`${APIPath.setTransactionTaskCheck}`, reqData, true)
    }

    getCustomerList(reqData) {
        return BaseService.get(`${APIPath.getCustomers}`)
    }

}

const TaskService = new Tasks()
Object.freeze(TaskService)
export { TaskService }
