import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { DangerText } from "Components/Common/Comman";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { TaskService } from "utility";

import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Label,
  InputGroup,
  Row,
} from "reactstrap";

const BasicForm = (basicFormData) => {
  const {
    transactionTypeValue,
    setTransactionTypeValue,
    transactionSubTypeValue,
    setTransactionSubTypeValue,
    setModaliteItensSel,
    transection,
    setTransection,
    errorState,
    setErrorState,
    transactionFields,
    setAutoSelect,
    autoSelect,
    selectAuto,
  } = basicFormData;

  const [transactionSubOptions, setTransactionSubOptions] = useState("");
  const [transSubTypeList, setTransSubTypeList] = useState([]);
  const [transSubTpevalue, setTransSubTypeValue] = useState(null);

  const [getCustomers, setCustomerList] = useState([]);
  const [isFlag, setIsFlag] = useState(false);

  const handleTranTypeSelect = (e) => {
    setTransSubTypeValue("1");
    var selectTranType = e.target.value;
    if (selectTranType === "1") {
      setTransactionSubOptions(["1", "2", "3", "4", "5"]);
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
      setTransection({
        ...transection,
        transactionsubtype: "1",
        transactionType: e.target.value,
      });
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        interestMethod: "jaarlijks",
        Rentebasis: "",
        RenteBasisValue: "0",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setErrorState(
        errorState.filter((obj) => {
          return obj !== "transactionType";
        })
      );
    } else if (selectTranType === "2") {
      setTransactionSubOptions(["1", "4", "5"]);
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
      setTransection({
        ...transection,
        transactionsubtype: "1",
        transactionType: e.target.value,
      });
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        // Rentebasis: "12 maands euribor",
        // RenteBasisValue:'5',
        dagtelling: "act/act",
        interestMethod: "Selecteer",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
        clause: "2",
      });
      setErrorState(
        errorState.filter((obj) => {
          return obj !== "transactionType";
        })
      );
    } else if (selectTranType === "3") {
      setTransactionSubOptions(["2", "3", "6"]);
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "surchargeRevision",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
      setTransection({
        ...transection,
        transactionsubtype: "2",
        transactionType: e.target.value,
      });
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "Selecteer",
        dagtelling: "act/360",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
        clause: "2",
      });
      setErrorState(
        errorState.filter((obj) => {
          return obj !== "transactionType";
        })
      );
    } else {
      setTransactionSubOptions("");
      setTransection({
        ...transection,
        transactionType: "",
        transactionsubtype: "",
      });
      setErrorState([...errorState, "transactionType"]);
    }
    setTransactionTypeValue(e.target.value);
    handleTranSubTypeSelect("fromType");
  };
  useEffect(() => {
    if (transactionSubOptions.length > 0) {
      filteredList();
    }
  }, [transactionSubOptions]);

  const handleTranSubTypeSelect = (e) => {
    if (transSubTpevalue !== null && e === "fromType") {
      setTransactionSubTypeValue("1");
    } else {
      setTransactionSubTypeValue(e.target.value);
    }
    let transactionSubTypeValue = e.target.value || "1";

    if (transactionTypeValue === "1" && transactionSubTypeValue === "1") {
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "1" &&
      transactionSubTypeValue === "4"
    ) {
      selectAuto({
        Aflossingstype: "Lineair",
        AflossingstypeValue: "3",
        // Rentebasis: null,
        RenteBasisValue: "0",
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "1" &&
      transactionSubTypeValue === "5"
    ) {
      selectAuto({
        Aflossingstype: "Annuïtair",
        AflossingstypeValue: "2",
        // Rentebasis: null,
        // RenteBasisValue:'0',
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "1" &&
      transactionSubTypeValue === "2"
    ) {
      selectAuto({
        Aflossingstype: "Variabel",
        AflossingstypeValue: "4",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "Selecteer",
        dagtelling: "act/360",
        dagtellingValue: "2",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        // "interestConverionDate",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
        "surchargeRevision",
      ]);
    } else if (
      transactionTypeValue === "1" &&
      transactionSubTypeValue === "3"
    ) {
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "Selecteer",
        dagtelling: "act/360",
        dagtellingValue: "2",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        // "interestConverionDate",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "guarantee",
        "surchargeRevision",
      ]);
    } else if (
      transactionTypeValue === "2" &&
      transactionSubTypeValue === "1"
    ) {
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "2" &&
      transactionSubTypeValue === "4"
    ) {
      selectAuto({
        Aflossingstype: "Lineair",
        AflossingstypeValue: "3",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "2" &&
      transactionSubTypeValue === "5"
    ) {
      selectAuto({
        Aflossingstype: "Annuïtair",
        AflossingstypeValue: "2",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
      });
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "interestConverionDate",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "3" &&
      transactionSubTypeValue === "2"
    ) {
      selectAuto({
        Aflossingstype: "Variabel",
        AflossingstypeValue: "4",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "Selecteer",
        dagtelling: "act/360",
        dagtellingValue: "2",
        Renteconventie: "(modified) Following",

        clause: "2",
      });
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "surchargeRevision",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "3" &&
      transactionSubTypeValue === "3"
    ) {
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "Selecteer",
        dagtelling: "act/360",
        dagtellingValue: "2",
        Renteconventie: "(modified) Following",
        clause: "2",
      });
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "surchargeRevision",
        "repaymentType",
        "interestBasis",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    } else if (
      transactionTypeValue === "3" &&
      transactionSubTypeValue === "6"
    ) {
      selectAuto({
        Aflossingstype: "Bullet",
        AflossingstypeValue: "1",
        Rentebasis: "12 maands euribor",
        RenteBasisValue: "5",
        interestMethod: "jaarlijks",
        dagtelling: "act/act",
        dagtellingValue: "1",
        Renteconventie: "(modified) Following",
        clause: "2",
      });
      setModaliteItensSel([
        "huidige-geldgever",
        "dealcode",
        "leningnummer",
        "principalAmount",
        "depositDate",
        "firstCoupon",
        "loanEndDate",
        "duration",
        "surchargeRevision",
        "repaymentType",
        "interestMethod",
        "rollday",
        "dayCount",
        "convention",
        "clause",
        "guarantee",
      ]);
    }
    setTransection({ ...transection, transactionsubtype: e.target.value });
  };

  const handleCustomer = (value) => {
    let id = "";
    if (value !== "") {
      id = getCustomers.filter((item) => {
        if (item.name === value) {
          return item;
        }
      })[0].id;
    }
    setTransection({ ...transection, customerId: id });
  };

  const handleTransactionproposalnumber = (e) => {
    setTransection({
      ...transection,
      transactionNo: e.target.value,
    });
  };

  const handleUserName = (e) => {
    setTransection({ ...transection, transactionProposal: e.target.value });
  };
  const getCustomerList = async () => {
    try {
      const resp = await TaskService.getCustomerList();
      const newStateData = resp.response.map((item) => {
        return { ...item, label: item.name, value: item.id };
      });
      // const newCustomers = newStateData.filter(
      //   (item) => item.customerType !== "M4"
      // );

      setCustomerList(newStateData);

      // customerName
    } catch (error) {
      console.log("Info", error);
    }
  };

  const filteredList = () => {
    const list1 = transactionFields?.TransactionSubType;

    const list2 = transactionSubOptions;
    const filteredItems = [];
    for (let i = 0; i < list1.length; i++) {
      const item = list1[i];
      if (list2.includes(item.value)) {
        filteredItems.push({ name: item.name, value: item.value });
      }
    }
    setTransSubTypeList(filteredItems);
  };

  // Get data once
  useEffect(() => {
    getCustomerList();
  }, []);

  return (
    <Fragment>
      <Card className="px-5">
        <CardHeader className="pb-0">
          <h5 className="color-secondary font-w800">Basis</h5>
        </CardHeader>
        <CardBody>
          <Form className="" noValidate="">
            <Row className="g-3 d-flex">
              <Col md="4">
                <Label className="form-label" for="customerId">
                  Geldnemer
                </Label>
                <Autocomplete
                  disablePortals
                  id="combo-box-demo"
                  size="small"
                  options={getCustomers}
                  sx={{
                    margin: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    boxSizing: "border-box",
                    maxHeight: "37px",
                    // maxHeight: "38px",
                  }}
                  onInputChange={(event, newValue) => handleCustomer(newValue)}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        margin: "0px",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                        boxSizing: "border-box",
                        maxHeight: "37px",
                      }}
                      {...params}
                      className="form-control"
                    />
                  )}
                />
                {errorState.includes("customerId") && (
                  <div>* Klant is vereist</div>
                )}
              </Col>
              <Col md="4">
                <Label className="form-label" for="transactionNo">
                  Transactie
                </Label>
                <input
                  className="form-control"
                  id="transactionNo"
                  type="text"
                  name="transactionNo"
                  onChange={handleTransactionproposalnumber}
                />
                {errorState.includes("transactionNo") && (
                  <div>*Transactie is verplicht</div>
                )}
              </Col>
              <Col md="4">
                <Label className="form-label" for="transactionType">
                  Type transactie
                </Label>

                <select
                  name="transactionType"
                  value={transactionTypeValue}
                  id="transactionType"
                  onChange={handleTranTypeSelect}
                  className="form-control form-control-primary btn-square form-select"
                >
                  <option value="">Selecteer</option>
                  {transactionFields?.TransactionTypes?.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errorState.includes("transactionType") && (
                  <div>*Selecteer is verplicht</div>
                )}
              </Col>
              {transactionSubOptions && (
                <Col md="4">
                  <Label className="form-label" for="transactionsubtype">
                    Subniveau
                  </Label>

                  <select
                    name="transactionsubtype"
                    id="transactionsubtype"
                    value={transactionSubTypeValue}
                    onChange={handleTranSubTypeSelect}
                    className="form-control form-control-primary btn-square form-select"
                  >
                    {transSubTypeList.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errorState.includes("transactionsubtype") && (
                    <div>*Subniveau is vereist</div>
                  )}
                </Col>
              )}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default BasicForm;
