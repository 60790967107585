import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "reactstrap";
import { Btn, H4, Image } from "../../../AbstractElements";
import { AuthService } from "utility";


const FirstStap = () => {
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const navigate = useNavigate()

  const GoToSecond = () => {
    navigate(`/verification`);
  };

  useEffect(async () => {
    const response = await AuthService.get2FAQRcodeImage()
    setImg(response.response.qrCode);
  }, []);

  return (
    <Container fluid={true} className="sec-stap-container">
      <Row>
        <Col ms="9" sm="12" xs="12">
          <div className="fsform-card-main p-b-50">
            <Form className="theme-form fsverify-form p-t-50 p-b-50">
              <center>
                <H4 className="font-w800">Beveiliging - stap 1</H4>
              </center>
              <Row>
                <Col md="6" sm="6" xs="12" className="p-r-20">

                  <img
                    src={require("../../../assets/images/Group 74.png")}
                    alt=""
                    className="img-fluid"
                  />
                  {/* <img
                    src={img}
                    alt=""
                    className="img-fluid"
                  /> */}
                </Col>
                <Col md="6" sm="6" xs="12" className="p-l-20">
                  <Row className=" fsform-title">
                    TOM is een platform dat gebruik maakt van Google 2-factor
                    authenticatie voor extra veiligheid. Om het platform te
                    kunnen gebruiken, is het noodzakelijk om Google 2-factor
                    authenticatie in te stellen. Deze extra veiligheidsmaatregel
                    zorgt ervoor dat alleen geautoriseerde gebruikers toegang
                    hebben tot het platform. Het helpt ook om gebruikersgegevens
                    te beschermen tegen toegang door onbevoegde gebruikers.
                  </Row>
                  <Row className=" fsform-strong">
                    <strong>
                      Uitleg over gebruik van Google 2-factor autenticatie
                    </strong>
                  </Row>
                  <Row>&nbsp;</Row>

                  <Row className="justify-center">
                    <Btn
                      attrBtn={{
                        className: "fsverify-btn",
                        disabled: loading ? loading : loading,
                        onClick: (e) => GoToSecond(e),
                      }}
                    >
                      <span className="fsform-btn-stap">Volgende stap</span>
                      <i
                        className="fa fa-arrow-right arrow-style-first"
                        aria-hidden="true"
                      ></i>
                    </Btn>
                  </Row>

                  {/* <div className="row"></div> */}
                </Col>
              </Row>
            </Form>
            {/* </div> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default FirstStap;
