import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { TransctiesService } from "utility";

import { visuallyHidden } from "@mui/utils";

function createData(
  id,
  proposalPercentage,
  financierName,
  fee,
  showFees,
  surcharge,
  swapRate,
  dateAndTime
) {
  return {
    id,
    proposalPercentage,
    financierName,
    fee,
    showFees,
    surcharge,
    swapRate,
    dateAndTime,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: false,
    label: "Naam geldgever",
  },
  {
    id: "dateAndTime",
    numeric: false,
    disablePadding: false,
    label: "Datum en tijd",
  },
  {
    id: "tariefExclFee",
    numeric: false,
    disablePadding: false,
    label: "Tarief (excl. fee)",
  },

  {
    id: "fee",
    numeric: false,
    disablePadding: false,
    label: "Fee",
  },
  {
    id: "tariefInclFee",
    numeric: false,
    disablePadding: false,
    label: "Tarief (incl. fee)",
  },
  {
    id: "opslag",
    numeric: false,
    disablePadding: false,
    label: "Opslag",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, onClickAddTransaction } = props;

  return <></>;
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const BiedingenHistory = (props) => {
  const { historyId, financiarList, isTTCPassed, detail, proposals } = props;
  const [history, setHistory] = useState([]);
  // console.log(proposals);
  useEffect(() => {
    const proposalId = proposals.filter(
      (item) => item.financierId === historyId
    );
    // console.log(proposalId);
    getData(proposalId[0].id);
  }, []);
  // useEffect(() => {}, []);

  const getData = async (id) => {
    const transactionlog = await TransctiesService.getTransactionOfferLogs(id);
    setHistory(transactionlog.response);
    // console.log(transactionlog.response);
  };

  const row = history?.map((item) => {
    return createData(
      item.id,
      item.proposalPercentage,
      item.financierName,
      item.fee,
      item.showFees,
      item.surcharge,
      item.swapRate,
      item.proposalDateTime
    );
  });

  const rows = row?.filter((item) => item != undefined);

  // const rows = financiarList?.map((item) => {
  //   return createData(item.transactionId, item.companyType, item.status, item.offerPercentage, item.offerDate, item.companyName, item.comapnyCode);
  // })

  const navigate = useNavigate();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("companyType");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.transactionId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, transactionId) => {
    const selectedIndex = selected.indexOf(transactionId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, transactionId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const { control, handleSubmit } = useForm();

  const isSelected = (transactionId) => selected.indexOf(transactionId) !== -1;

  const [modal, setModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);

  const [isAKL, setAKL] = useState(false);
  const toggle = () => setModal(!modal);
  const historyViewToggle = () => setHistoryModal(!historyModal);
  const [displayNone] = useState("d-none");

  const handleButton = (e, item) => {
    toggle();
  };

  const handleCheckBox = (e) => {
    setAKL(e.target.value);
  };
  const handleAddUserSubmit = async (formdata) => {
    const submitObject = {
      userId: 1,
      transactionId: 10,
      companyId: 0,
      offerDate: "string",
      offerPercentage: 0,
      surcharge: 0,
    };
    try {
      const resp = await TransctiesService.setTransactionoffer(submitObject);
      toast.success(resp.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.success(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function convertToCustomFormat(date) {
    // console.log(date);
    const newDate = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
    };
    const dutchDateString = newDate.toLocaleString("nl-NL", options);
    return dutchDateString;
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  // console.log(rows);
  return (
    <ThemeProvider theme={outerTheme}>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.transactionId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.transactionId)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        // selected={isItemSelected}
                      >
                        <TableCell align="left">{row.financierName}</TableCell>
                        {/* <TableCell align="left">{row.offerDate}</TableCell>
                        <TableCell align="left">{row.companyName}</TableCell> */}
                        <TableCell align="left">
                          {convertToCustomFormat(row.dateAndTime)} uur
                        </TableCell>
                        <TableCell align="left">
                          {(row.surcharge / 100).toLocaleString("nl-NL", {
                            style: "percent",
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                            useGrouping: true,
                          })}
                        </TableCell>
                        {row?.showFees === true ? (
                          <TableCell align="left">
                            {(row.fee / 100).toLocaleString("nl-NL", {
                              style: "percent",
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                              useGrouping: true,
                            })}
                          </TableCell>
                        ) : (
                          <TableCell align="left">N/A</TableCell>
                        )}
                        <TableCell align="left">
                          {(row.proposalPercentage / 100).toLocaleString(
                            "nl-NL",
                            {
                              style: "percent",
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                              useGrouping: true,
                            }
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {(row.swapRate / 100).toLocaleString("nl-NL", {
                            style: "percent",
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                            useGrouping: true,
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </ThemeProvider>
  );
};

export default BiedingenHistory;
