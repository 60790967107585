import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { visuallyHidden } from "@mui/utils";
import { GetUserList } from "hooks";

import { UserRolesName } from "utility";
import { Icon } from "@iconify/react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormSelectField, FormTextField } from "CommonElements/FormElements";
import { UserService } from "utility";
import { toast } from "react-toastify";

import { Col, Row, Card, Form, FormGroup } from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Btn } from "../../../AbstractElements";
import Modal from "react-bootstrap/Modal";
import Form_ from "react-bootstrap/Form";
import { generate, generateMultiple, validate } from "@wcj/generate-password";
import { Button } from "reactstrap";
import { AuthService, UserRoles } from "utility";
import { useAuth } from "context/user";

function createData(
  email,
  firstName,
  middleName,
  lastName,
  id,
  phone,
  userRoleId,
  personId,
  identifier
) {
  return {
    email,
    firstName,
    middleName,
    lastName,
    id,
    phone,
    userRoleId,
    personId,
    identifier,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: "id",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "ID",
  // },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Naam",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "E-mail adres",
  },
  // {
  //   id: "mobileNo",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Telefoonnummer ",
  // },
  {
    id: "userType",
    numeric: false,
    disablePadding: false,
    label: "Rol",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Acties",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:Mui-selected': {
//     backgroundColor: "#FF9E00",
//   }
// }));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </StyledTableCell> */}
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ color: "secondary" }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "desc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const GeneratePassword = (selectedListObject) => {
  const generateNewPassword = () => {
    const pass = generate().replaceAll("#", "A");
    deleteAPICall(pass);
    setPassword(pass);
  };

  const deleteAPICall = async (password) => {
    const response = await UserService.getActivateLoginForPerson(
      selectedListObject?.selectedListObject?.personId,
      password
    );
  };

  const [password, setPassword] = useState("");

  const [smShow, setSmShow] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          generateNewPassword();
          setSmShow(true);
        }}
        color="primary"
        className="welkon-form-popup-btn-attr "
      >
        Wachtwoord aanmaken
      </Button>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Wachtwoord genereren
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form_>
            <Form_.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form_.Label>Wachtwoord</Form_.Label>
              <Form_.Control type="text" value={password} autoFocus />
            </Form_.Group>
          </Form_>
        </Modal.Body>
      </Modal>
    </>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    onClickAddTransaction,
    handleAddUser,
    selected,
    handleEditUser,
  } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Werknemers
        </Typography>
      )}

      {numSelected == 1 && (
        <IconButton>
          <Tooltip title="Gebruiker aanpassen">
            <EditIcon
              onClick={() => handleEditUser(selected[0])}
              style={{ color: "#FF9E00", margin: "10px" }}
            />
          </Tooltip>{" "}
        </IconButton>
      )}

      {numSelected > 0 ? (
        // <IconButton>
        //   <Tooltip title="Delete Gebruikers">
        //     <DeleteIcon style={{ color: "#FF9E00", margin: "10px" }} />
        //   </Tooltip>
        // </IconButton>
        <></>
      ) : (
        <IconButton>
          <Tooltip title="Gebruiker toevoegen">
            <AddIcon
              onClick={() => handleAddUser()}
              style={{ color: "#FF9E00", margin: "10px", muiName: "Add" }}
            />
          </Tooltip>
          {/* <Tooltip title="Filters">
            <FilterListIcon style={{ color: "#FF9E00", margin: "10px" }} />
          </Tooltip> */}
        </IconButton>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const UserList = () => {
  const [rows, setRows] = useState([]);
  const [mainUserData, setMainUserData] = useState();
  const [selectedListObject, setSelectedListObject] = useState();
  const { data: userList, loading } = GetUserList();
  const { user } = useAuth();
  const [errorState, setErrorState] = useState([]);

  useEffect(() => {
    // const { data: userList, loading } = GetUserList();
    if (userList) {
      setMainUserData(userList);
    }
  }, [userList]);

  useEffect(() => {
    if (mainUserData) {
      // setMainUserData(userList)
      let translated = mainUserData.map((item) => {
        return createData(
          item.email,
          item.firstName,
          item.middleName,
          item.lastName,
          item.userId,
          item.phone,
          item.userRoleId,
          item.personId,
          item.identifier
        );
      });
      // translated = [...translated, ...translated];
      setRows(translated);
    }
  }, [mainUserData]);

  // useEffect(() => {
  //     if (mainUserData) {
  //         // setMainUserData(userList)
  //         let translated = userList.map((item) => {
  //             return createData(item.id, item.name, item.firstName, item.middleName, item.lastName, item.email,
  //                 item.mobileNo, item.userType, item.userRoleId, item.companyId);
  //         })
  //         // translated = [...translated, ...translated];
  //         setRows(translated)
  //     }
  // }, [mainUserData]);

  const navigate = useNavigate();

  const onClickAddTransaction = () => {
    navigate(`/transacties/add`);
  };

  const onClickDetailsransaction = (e, row) => {
    navigate(`/transacties/dashboard/` + row.id);
  };

  // useEffect(() => {
  //     if (transactionList) {
  //         let translated = transactionList.map((item) => {
  //             return createData(item.id, item.name, item.firstName, item.middleName, item.lastName);
  //         })
  //         // translated = [...translated, ...translated];
  //         setRows(translated)
  //     }
  // }, [transactionList])

  // const userList =
  const [flag, setFlag] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [displayNone] = useState("d-none");

  const userTypeOptions = [
    { name: "Select", value: 0 },
    { name: "Admin", value: 1 },
    { name: "Employee", value: 2 },
  ];

  const handleAddUser = () => {
    setFlag(0);
    setValue("firstName", "");
    setValue("middleName", "");
    setValue("lastName", "");
    setValue("email", "");
    // setValue("phone", "");
    setValue("userRoleId", 0);
    setValue("password", generate());
    setSelectedListObject({
      firstName: "",
      middleName: "",
      lastName: "",
      phone: "",
      email: "",
      userRoleId: 0,
      userId: 0,
    });
    toggle();
    setErrorState([]);
  };

  const deleteAPICall = async (id) => {
    const response = await UserService.deleteUser(id);
    if (response?.status) {
      toast.success("Werknemersgegevens zijn succesvol verwijderd.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(
        "Gegevens van medewerkers kunnen op dit moment niet worden verwijderd.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }

    try {
      const result = await UserService.getUserList();
      setMainUserData(result.response);
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteCustomer = async (selected) => {
    const data = rows.filter((item) => {
      if (item.id === selected) return item;
    });
    try {
      // selected.map((item) => {
      // const object = rows.filter((_item) => { if (_item.id == item) return _item })
      deleteAPICall(data[0].identifier);

      // })
      // setSelected([])
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleEditUser = (id) => {
    setFlag(1);
    const data = rows.filter((item) => {
      if (item.id === id) return item;
    });
    setValue("firstName", data[0]?.firstName || "");
    setValue("middleName", data[0]?.middleName || "");
    setValue("lastName", data[0]?.lastName || "");
    setValue("email", data[0]?.email || "");
    // setValue("phone", data[0]?.phone || "");
    setValue("userRoleId", data[0]?.userRoleId || 0);
    setSelectedListObject({
      firstName: data[0]?.firstName,
      middleName: data[0]?.middleName,
      lastName: data[0]?.lastName,
      // phone: data[0]?.phone,
      email: data[0]?.email,
      userRoleId: data[0]?.userRoleId,
      userId: data[0]?.userId,
      personId: data[0]?.personId,
    });
    // // toggleUpdate();

    toggle();
    setErrorState([]);
  };

  const Schema = yup.object().shape({
    firstName: yup.string().required("voornaam is verplicht"),
    lastName: yup.string().required("Achternaam is vereist"),
    // middleName: yup.string().matches(/^[a-zA-Z]+$/),
    email: yup.string().email().required("Voer een geldig emailadres in"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(Schema),
  });

  const handleAddUserSubmit = async (formdata) => {
    let hasError = false;
    let errorColletion = [];
    if (formdata.firstName === "") {
      hasError = true;
      errorColletion = [...errorColletion, "firstName"];
    }
    // if (formdata.middleName === "") {
    //   hasError = true;
    //   errorColletion = [...errorColletion, "middleName"];
    // }

    if (formdata.lastName === "") {
      hasError = true;
      errorColletion = [...errorColletion, "lastName"];
    }

    // if (formdata.email === "") {
    //   hasError = true;
    //   errorColletion = [...errorColletion, "email"];
    // }

    // if (formdata.phone === '') {
    //   hasError = true;
    //   errorColletion = [...errorColletion, 'phone']
    // }

    if (formdata.userRoleId === 0 || formdata.userRoleId === "0") {
      hasError = true;
      errorColletion = [...errorColletion, "userRoleId"];
    }

    if (!hasError) {
      setErrorState([]);
      try {
        const requestObject = {
          id: selectedListObject.personId,
          firstName: formdata.firstName,
          middleName: formdata.middleName,
          lastName: formdata.lastName,
          email: formdata.email,
          phone: formdata.phone,
          roleId: parseInt(formdata.userRoleId),
        };

        const addRequestObject = {
          firstName: formdata.firstName,
          middleName: formdata.middleName,
          lastName: formdata.lastName,
          email: formdata.email,
          phone: formdata.phone,
          roleId: parseInt(formdata.userRoleId),
          password: formdata.password,
        };
        try {
          await UserService.insertUpdateFinancierEmployee(
            selectedListObject.personId ? requestObject : addRequestObject
          );

          {
            selectedListObject?.id
              ? toast.success("Gegevens werknemer aangepast", {
                  position: toast.POSITION.TOP_RIGHT,
                })
              : toast.success("Werknemer toegevoegd", {
                  position: toast.POSITION.TOP_RIGHT,
                });
          }
          const result = await UserService.getUserList();
          setMainUserData(result.response);
        } catch (error) {
          toast.error("Serverfout", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        toggle();
      } catch (error) {
        toast.error("Serverfout", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setErrorState(errorColletion);
    }

    // try {
    //   const requestObject = {
    // id: selectedListObject?.id | 0,
    // firstName: formdata.firstName,
    // middleName: formdata.middleName,
    // lastName: formdata.lastName,
    // email: formdata.email,
    // mobileNo: formdata.phone,
    // userRoleId: parseInt(formdata.userRoleId),
    //   };
    //   try {
    //     await UserService.setUserList(requestObject);
    //     toggle();

    //     {
    //       selectedListObject?.id
    //         ? toast.success("User updated successfuly", {
    //           position: toast.POSITION.TOP_RIGHT,
    //         })
    //         : toast.success("User added successfuly", {
    //           position: toast.POSITION.TOP_RIGHT,
    //         });
    //     }
    // const result = await UserService.getUserList();
    // setMainUserData(result.response);
    //   } catch (error) {
    //     console.log("error", error);
    //     toast.error("Serverfout", {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   }
    // } catch (error) {
    //   console.log("error", error);
    //   toast.error("Serverfout", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  };

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [adminCount, setAdminCount] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    const numberOfAdmins = () => {
      const totalAdminCount = rows.filter((user) => user.userRoleId === 1);
      setAdminCount(totalAdminCount.length);
    };
    numberOfAdmins();
  }, [rows]);

  return (
    <ThemeProvider theme={outerTheme}>
      <React.Fragment>
        <Box sx={{ color: "primary", width: "100%" }}>
          <Paper sx={{ color: "primary", width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              onClickAddTransaction={onClickAddTransaction}
              handleAddUser={handleAddUser}
              handleEditUser={handleEditUser}
              selected={selected}
            />
            <TableContainer>
              <Table
                sx={{ color: "primary", minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        row.userRoleId !== 1 && (
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            // style={{ display: isUserAdmin(row.identifier) }}
                          >
                            <TableCell align="left">
                              {row.firstName} {row.middleName} {row.lastName}
                            </TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            {/* <TableCell align="left">{row.phone}</TableCell> */}
                            <TableCell align="left">
                              {row.userRoleId == 1
                                ? "Admin"
                                : row.userRoleId == 2
                                ? "Employee"
                                : "Financer"}
                            </TableCell>
                            <TableCell align="left" sx={{ color: "#212F56" }}>
                              <IconButton>
                                <Tooltip title="Klantgegevens aanpassen">
                                  <EditIcon
                                    onClick={() => handleEditUser(row.id)}
                                    style={{ color: "#FF9E00" }}
                                  />
                                </Tooltip>{" "}
                              </IconButton>
                              {user?.userType == UserRoles.admin && (
                                <IconButton>
                                  <Tooltip title="Delete">
                                    <DeleteIcon
                                      onClick={() =>
                                        handleDeleteCustomer(row.id)
                                      }
                                      style={{ color: "#FF9E00" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}

                              {/* <IconButton>
                              <Tooltip title="Delete">
                                <DeleteIcon
                                  onClick={() => handl(row.id)}
                                  style={{ color: "#FF9E00" }} />
                              </Tooltip>
                            </IconButton> */}
                            </TableCell>
                          </TableRow>
                        )
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        color: "primary",
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              sx={{ color: "primary" }}
              rowsPerPageOptions={[10, 20, 25]}
              component="div"
              count={rows.length - adminCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rijen per pagina"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} van ${count !== -1 ? count : `meer dan ${to}`}`
              }
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Regelafstand"
          />
        </Box>
        <CommonModal
          isOpen={modal}
          title={flag == 1 ? "Bewerk Gebruiker" : "Toevoegen Gebruiker"}
          toggler={toggle}
          size="xl"
          displayNone={displayNone}
          showFooter={false}
        >
          <Row className="welkon-form-popup-main-div">
            <Col sm="10" className="d-flex flex-column justify-content-between">
              <Form
                className="welkon-form-popup-main"
                noValidate
                onSubmit={handleSubmit(handleAddUserSubmit)}
              >
                <FormGroup>
                  <Row>
                    <Col>
                      <FormTextField
                        label="Voornaam"
                        className="form-control"
                        type="text"
                        name="firstName"
                        required={true}
                        errors={errors.firstName}
                        control={control}
                      />
                    </Col>
                    <Col>
                      <FormTextField
                        label="Tussenvoegsel"
                        className="form-control"
                        type="text"
                        name="middleName"
                        control={control}
                        required={false}
                      />
                    </Col>
                    <Col>
                      <FormTextField
                        label="Achternaam"
                        className="form-control"
                        type="text"
                        name="lastName"
                        required={true}
                        errors={errors.lastName}
                        control={control}
                      />
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col>
                      <FormTextField
                        label="Telefoon"
                        className="form-control"
                        type="number"
                        name="phone"
                        control={control}
                      />
                      {errorState.includes("phone") && (
                        <div>
                          *Telefoon is required
                        </div>
                      )}
                    </Col> */}

                    <Col>
                      <FormTextField
                        label="Email"
                        className="form-control"
                        type="text"
                        name="email"
                        errors={errors.email}
                        required={true}
                        control={control}
                      />
                    </Col>

                    <Col>
                      <FormSelectField
                        label="Type gebruiker"
                        className="form-control"
                        type="select"
                        name="userRoleId"
                        control={control}
                        required={true}
                        errors={errors.userRoleId}
                        options={userTypeOptions}
                      />
                      {errorState.includes("userRoleId") && (
                        <div className="text-dark">
                          *Type gebruiker is verplicht
                        </div>
                      )}
                    </Col>
                  </Row>
                  {selectedListObject?.userRoleId == 0 &&
                    selectedListObject?.userRoleId != 1 && (
                      <Row>
                        <Col>
                          <FormTextField
                            label="Wachtwoord"
                            className="form-control"
                            type="text"
                            name="password"
                            control={control}
                          />
                        </Col>
                      </Row>
                    )}
                  {selectedListObject?.userRoleId != 0 &&
                    selectedListObject?.userRoleId != 1 && (
                      <Row>
                        <Col>
                          <GeneratePassword
                            selectedListObject={selectedListObject}
                          />
                        </Col>
                      </Row>
                    )}
                </FormGroup>
                <Row className="step1_header">
                  <Col className="right">
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "welkon-form-popup-btn-attr ",
                      }}
                    >
                      Versturen
                    </Btn>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CommonModal>
      </React.Fragment>
    </ThemeProvider>
  );
};
