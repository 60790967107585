// dashbaord
import Dashboard from "../Components/Pages/EmployeeDashboard";
import TransactionsDashboard from "../Components/Pages/TransactiesDashboard";
import Financiers from "../Components/Pages/Financiers/Financiers";
import FinancierDashboard from "../Components/Pages/FinancierDashboard";

import Transacties from "../Components/Pages/Transacties";
import TransactiesEdit from "../Components/Pages/TransactiesEditPage/TransactiesEdit";
import Transcties from "../Components/Pages/Transcties/Transcties";
import RecenteVoltooideTranscties from "../Components/Pages/Transcties/RecenteVoltooideTranscties";
import UpcomingTranscties from "../Components/Pages/Transcties/UpcomingTranscties";
import FirstStap from "../Auth/Tabs/LoginTab/FirstStep";
import Verification from "../Auth/Tabs/LoginTab/Verification";
// import Welkom from "../Components/Pages/InputBoxPopUp/Welkom";
// import TransactieToken from "../Components/Pages/InputBoxPopUp/TransactieToken";
// import FinancierMail from "../Components/Pages/InputBoxPopUp/FinancierMail";
// import FinanciersMail from "../Components/Pages/InputBoxPopUp/FinanciersMail";
// import TransactieToekennen from "../Components/Pages/InputBoxPopUp/TransactieToekennen";
import FinancerTransactiesDashboard from "../Components/Pages/FinancerTransactionDetails";
import DashboardLineChart from "../Components/Pages/DashboardLineChart";
import Gebruikers from "../Components/Pages/Gebruikers";
import { UserRoles } from "utility";
import { UserList } from "Components/Pages/Users/UserList";
import { CustomerManagementList } from "Components/Pages/Customers/CustomerManagementList";
import Gewonnenbiedingen from "../Components/Pages/FinancierDashboard/Gewonnenbiedingen";
export const routes = [
  //dashboard
  {
    path: `dashboard/gebruikers`,
    Component: <Gebruikers />,
  },
  {
    path: `dashboard/dashboardlinechart`,
    Component: <DashboardLineChart />,
  },
  {
    path: `FirstStep`,
    Component: <FirstStap />,
  },
  {
    path: `verification`,
    Component: <Verification />,
  },
  {
    path: `employee-dashboard`,
    Component: <Dashboard />,
    roles: [UserRoles.employee],
  },
  {
    path: `admin-dashboard`,
    Component: <Dashboard />,
    roles: [UserRoles.admin],
  },
  {
    path: `financiers`,
    Component: <Financiers />,
    roles: [UserRoles.admin, UserRoles.employee],
  },
  {
    path: `financiers-dashboard`,
    Component: <FinancierDashboard />,
    roles: [UserRoles.financier, UserRoles.admin],
  },
  {
    path: `financiers-transctie-dashboard/:id`,
    Component: <FinancerTransactiesDashboard />,
    roles: [UserRoles.financier, UserRoles.admin],
  },
  {
    path: `transacties/dashboard/:id`,
    Component: <TransactionsDashboard />,
    roles: [UserRoles.admin, UserRoles.employee, UserRoles.financier],
  },
  {
    path: `transacties/add`,
    Component: <Transacties />,
    roles: [UserRoles.admin, UserRoles.employee, UserRoles.financier],
  },
  {
    path: `transacties/dashboard/:id/edit`,
    Component: <TransactiesEdit />,
    roles: [UserRoles.admin, UserRoles.employee, UserRoles.financier],
  },
  {
    path: `transacties`,
    Component: <Transcties />,
    roles: [UserRoles.admin, UserRoles.employee, UserRoles.financier],
  },
  {
    path: `upcoming-transactions`,
    Component: <UpcomingTranscties />,
    roles: [UserRoles.admin, UserRoles.employee, UserRoles.financier],
  },
  {
    path: `RecenteVoltooideTranscties`,
    Component: <RecenteVoltooideTranscties />,
    roles: [UserRoles.admin, UserRoles.employee, UserRoles.financier],
  },
  {
    path: `dashboard/datumenTijdstip`,
    Component: <Transacties />,
  },
  // {
  //   path: `dashboard/InputBoxPopUp/Welkom`,
  //   Component: <Welkom />,
  // },
  // {
  //   path: `dashboard/InputBoxPopUp/TransactieToken`,
  //   Component: <TransactieToken />,
  // },
  // {
  //   path: `dashboard/InputBoxPopUp/FinanciersMail`,
  //   Component: <FinanciersMail />,
  // },
  // {
  //   path: `dashboard/InputBoxPopUp/TransactieToekennen`,
  //   Component: <TransactieToekennen />,
  // },
  // {
  //   path: `dashboard/InputBoxPopUp/FinancierMail`,
  //   Component: <FinancierMail />,
  // },
  {
    path: `users`,
    Component: <UserList />,
    roles: [UserRoles.admin],
  },
  {
    path: `customer-management`,
    Component: <CustomerManagementList />,
    roles: [UserRoles.admin],
  },
  {
    path: `Gewonnen-biedingen`,
    Component: <Gewonnenbiedingen />,
    // roles: [UserRoles.admin]
  },
];
