import React, { useState, useEffect, useRef } from "react";
import CommonModal from "../../UiKits/Modals/common/modal";
import { Row, Col, Label, Form, FormGroup } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { FinancerService, TransctiesService } from "utility";
import { useForm } from "react-hook-form";
import { FormSelectField, FormTextField } from "CommonElements/FormElements";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
// import Employee from "../Financiers/Employee";
// import EmployeeDisplayForm from "./EmployeeDisplayForm";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from "@tinymce/tinymce-react";
// import { Key } from "react-feather";

export const TransactionMail = ({
  isEnable,
  trainsactionId,
  isAddTransaction,
  data,
  financiersTranChecklistSel,
  transection,
  financialTypeIds,
  addNewTransaction,
  selectedFinancerObject,
  setSubpopupOpen,
  isFinancierTemplateEnable,
  selected,
  transactionObj,
}) => {
  const editorRef = useRef();
  const inputRef = useRef();
  const navigate = useNavigate();
  const [temp, setTemp] = useState();
  const [modal, setModal] = useState(true);
  const [displayNone] = useState("d-none");
  const ModalTitle = "Financiers mailen";
  const [transTemplate, setTransTemplate] = useState();
  const [financiersCheckList, setFinanciersCheckList] = useState([]);
  const [transTemplatePreviusState, setTransTemplatePreviusState] = useState(0);
  const [mailBody, setMailBody] = useState("");
  const [superKey, setSuperKey] = useState(0);
  const [isMailSent, setIsMailSent] = useState(false);

  const location = useLocation();
  // const [tempateModel, setTemplateModel] = useState({
  //   transactionId: 0,
  //   subject: 0,
  //   body: "",
  // });
  // const [mailSentModal, setMailSentModal] = useState(false);
  // const MailSentModalTitle = "Mail Status:-";
  const defaultFontSize = "12px";

  useEffect(() => {
    setTansactionAction();
  }, []);

  useEffect(() => {
    setModal(isEnable);
  }, [isEnable]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const handleOnSelect = () => {
    const value = parseInt(inputRef.current.value);
    setSuperKey(value);
    let hasValue = false;
    let previousBodyObject = "";
    let previousSubjectObject = "";
    let index = 0;
    if (transTemplatePreviusState !== 0) {
      previousBodyObject = mailBody;
      previousSubjectObject = getValues("subject");
      index = transTemplatePreviusState;
    }
    if (value > 0) {
      transTemplate.map((item) => {
        if (item.emailTemplateId === value) {
          setMailBody(item.emailTemplateBody);
          setValue("subject", item.emailTemplateSubject);
          setTransTemplatePreviusState(item.emailTemplateId);
          hasValue = true;
        }
      });
    } else {
      setTransTemplatePreviusState(0);
      setMailBody("");
      setValue("subject", "");
    }

    if (index !== 0) {
      const collection = [...transTemplate];
      const newEmailObjIdx = collection.findIndex(
        (item) => index === item.emailTemplateId
      );
      const newEmailObj = {
        emailTemplateBody: editorRef.current.getContent(),
        emailTemplateId: index,
        emailTemplateName: collection[newEmailObjIdx].emailTemplateName,
        emailTemplateSubject:
          previousSubjectObject.length === 0 ? "" : previousSubjectObject,
      };

      collection[newEmailObjIdx] = newEmailObj;
      setTransTemplate(collection);
      return collection;
    }

    if (!hasValue) {
      setTransTemplatePreviusState(0);
      setMailBody("");
      setValue("subject", "");
    }
  };
  const handeleTitle = () => {
    try {
      const titleArr = transTemplate.filter(
        (item) => item.emailTemplateId === superKey
      );
      const title = titleArr[0].emailTemplateSubject;
      return title ?? " ";
    } catch (error) {
      return " ";
    }
  };
  const handleAddUserSubmit = async (formdata) => {
    const value = parseInt(inputRef.current.value);
    const collection = handleOnSelect(value);
    let mailBodies = {};

    collection.map((item) => {
      mailBodies = {
        ...mailBodies,
        [item?.emailTemplateId]: item?.emailTemplateBody,
      };
    });
    let mailSubjects = {};
    collection.map((item) => {
      mailSubjects = {
        ...mailSubjects,
        [item?.emailTemplateId]:
          item?.emailTemplateSubject.length === 0
            ? ""
            : item?.emailTemplateSubject,
      };
    });
    // const requestObject = {
    //   "mailBodies":mailBodies[0]
    //   ,
    //   "mailSubjects": mailSubjects[0]
    // };

    const requestObject = {
      mailBodies: mailBodies,
      mailSubjects: mailSubjects,
      employeeIds: selected,
      transactionId: trainsactionId,
    };
    try {
      setIsMailSent(true);
      const resp = await TransctiesService.setEmailTemplatee(requestObject);
      if (resp.message === "Success") {
        toast.success("E-mail succesvol verzonden", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Serverfout", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      toggle();
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (isFinancierTemplateEnable) {
      navigate(location.pathname);
    } else {
      navigate(`/transacties`);
    }
  };

  const setTansactionAction = async () => {
    try {
      let ids = "";
      let existingFinancer = false;
      if (transection?.currentFinancierId.length !== 0) {
        existingFinancer = true;
      }

      financialTypeIds.map((it) => {
        if (ids) {
          ids = ids + "," + it;
        } else {
          ids = it;
        }
      });
      const resp = await TransctiesService.GetEmailTemplateList(
        ids,
        transection?.transactionType,
        transection?.transactionsubtype,
        existingFinancer
      );

      let keyCollection = [];
      let counter = 1;
      Object.entries(resp.response).map((key, valueOf, index) => {
        if (key) {
          keyCollection.push({
            emailTemplateId: key[0] === "Huidige geldgever" ? 99 : counter,
            emailTemplateName: key[0],
            emailTemplateBody: key[1],
            emailTemplateSubject: "",
          });
          counter++;
        }
      });
      setTransTemplate(keyCollection.reverse());
    } catch (error) {
      console.log("info", error);
    }
    // navigate(`/dashboard/InputBoxPopUp/FinanciersMail`);
  };
  const getFinancierData = async () => {
    try {
      const resp = await FinancerService.getFinancerList();

      const newStateData = resp.response.map((item) => {
        return { ...item, checked: false };
      });
      setFinanciersCheckList(newStateData);
    } catch (error) {
      console.log("error", error);
    }
  };

  // Get data once
  useEffect(() => {
    getFinancierData();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };
  return (
    <div>
      {modal && (
        <CommonModal
          isOpen={isEnable}
          title={ModalTitle}
          toggler={toggle}
          size="xl"
          // displayNone={displayNone}
          showFooter={false}
        >
          <Row className="welkon-form-popup-main-div">
            <Col sm="10" className="d-flex flex-column justify-content-between">
              <div className="welkon-form-popup-cont-div">
                Nodig hieronder financiers uit om mee te bieden op deze
                transactie.
              </div>
              <div className="welkon-form-popup-cont-div">
                <b>
                  Omdat de applicatie op de test server staat, is de mail server
                  niet actief. Hierdoor kan de mail niet worden verstuurd.
                </b>
              </div>
              <Form
                className="welkon-form-popup-main"
                onSubmit={handleSubmit(handleAddUserSubmit)}
              >
                <FormGroup>
                  <Row>
                    <Col>
                      {/* <Label className="col-form-label" for="recipient-name">
                      {"Titel"}
                    </Label> */}
                      <FormTextField
                        key={superKey}
                        className="form-control"
                        label="Titel"
                        value={handeleTitle()}
                        type="text"
                        name="subject"
                        control={control}
                      />
                    </Col>
                    {transTemplate && (
                      <Col>
                        <Label className="col-form-label" for="recipient-name">
                          {"Doelgroep "}
                        </Label>
                        <select
                          name="recipient-name"
                          id="recipient-name"
                          ref={inputRef}
                          onChange={(e) => {
                            handleOnSelect(e.target.value);
                          }}
                          className="form-control form-control-primary btn-square form-select"
                        >
                          <option value={0}>Selecteer </option>
                          {transTemplate.map((item, index) => {
                            return (
                              <option
                                value={item.emailTemplateId}
                                label={item.emailTemplateName}
                                key={index}
                              >
                                {item.emailTemplateName}
                              </option>
                            );
                          })}
                        </select>
                      </Col>
                    )}
                  </Row>
                  <Row key={superKey} className="m-t-15" welkon-form-popup-cont>
                    <Col>
                      {/* <FormTextField
                        name="body"
                        control={control}
                        type="textarea"
                        className="FinanciersMail-form-cont form-control"
                        rows="10"
                        contentEditable="true"
                      /> */}
                      <Editor
                        apiKey="yes6264ah229vgep4ftkqfteqelhyp4z5hgpou61u02f8u9f"
                        textareaName="body"
                        na
                        className="FinanciersMail-form-cont form-control"
                        initialValue={mailBody}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        key={superKey}
                        init={{
                          height: 400,
                          // plugins: ["table"],
                          menubar: false,
                          toolbar:
                            "undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify |" +
                            "|btnIncreaseFontSize | btnDecreaseFontSize",
                          content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:${defaultFontSize} }`,
                          setup: (editor) => {
                            editor.ui.registry.addButton(
                              "btnIncreaseFontSize",
                              {
                                text: "A",
                                icon: "action-prev",
                                onAction: (api) => {
                                  let fontSize =
                                    editor.getBody().style.fontSize;
                                  if (!fontSize) fontSize = defaultFontSize;
                                  let value = parseInt(
                                    fontSize.substring(0, 2)
                                  );
                                  fontSize = `${value + 1}px`;
                                  editor.getBody().style.fontSize = fontSize;
                                },
                              }
                            );
                            editor.ui.registry.addButton(
                              "btnDecreaseFontSize",
                              {
                                text: "A",
                                icon: "action-next",
                                onAction: (api) => {
                                  let fontSize =
                                    editor.getBody().style.fontSize;
                                  if (!fontSize) fontSize = defaultFontSize;
                                  let value = parseInt(
                                    fontSize.substring(0, 2)
                                  );
                                  fontSize = `${value - 1}px`;
                                  editor.getBody().style.fontSize = fontSize;
                                },
                              }
                            );
                          },
                        }}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <Row className="step1_header">
                  <Col>
                    {isMailSent === false ? (
                      <Btn
                        attrBtn={{
                          color: "primary",
                          className: "welkon-form-popup-btn-attr ",
                        }}
                      >
                        Bericht versturen
                      </Btn>
                    ) : (
                      // <div class="fa-3x">
                      //   {" "}
                      //   {/* <i class="fa-solid fa-spinner fa-spin-pulse"></i> */}
                      // </div>
                      <CircularProgress sx={{ color: '#FF9E00',}} />
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CommonModal>



      )}
    </div>
  );
};


