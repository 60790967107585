import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "react-bootstrap";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { orange } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { UserRoles } from "utility";
import { useAuth } from "context/user";
import { toast } from "react-toastify";
import CommonModal from "../../UiKits/Modals/common/modal";
import { visuallyHidden } from "@mui/utils";
import { GetTransactionList } from "hooks";
import { TransctiesService } from "utility";
import SelectedFinancierTransaction from "./SelectedFinancierTransaction";
import { DashboardService } from "utility/services/dashboard";
// import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { MarginRight } from "Constant";
import { diff } from "react-ace";

function createData(
  transactionId,
  transactionNo,
  financer,
  customerName,
  status,
  principalAmount,
  startdate,
  enddate,
  identifier,
  startDateTime,
  endDateTime,
  loanEndDate,
  transactionSubType,
  transactionType,
  isLockedUp,
  lockupTime
) {
  return {
    transactionId,
    transactionNo,
    financer,
    customerName,
    status,
    principalAmount,
    startdate,
    enddate,
    identifier,
    startDateTime,
    endDateTime,
    loanEndDate,
    transactionSubType,
    transactionType,
    isLockedUp,
    lockupTime,
  };
}

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9E00",
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return array;
}

const headCells = [
  // {
  //   id: "transactionNo",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Transactie ID",
  // },
  {
    id: "enddate",
    numeric: false,
    disablePadding: false,
    label: "Transactiedatum ",
  },
  {
    id: "loanEndDate",
    numeric: false,
    disablePadding: false,
    label: "Einddatum ",
  },
  // {
  //   id: "financer",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Financer",
  // },

  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Klant",
  },
  {
    id: "transactionType",
    numeric: false,
    disablePadding: false,
    label: "Type transactie",
  },

  {
    id: "transactionSubType",
    numeric: false,
    disablePadding: false,
    label: "Subniveau",
  },

  {
    id: "principalAmount",
    numeric: false,
    disablePadding: false,
    label: "Hoofdsom",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "view",
    numeric: false,
    label: "Opties",
  },
];

const headCellsFinancerFlow = [
  // {
  //   id: "transactionNo",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Transactie ID",
  // },
  {
    id: "selected",
    numeric: true,
    disablePadding: false,
    label: "Selecteer",
  },
  {
    id: "enddate",
    numeric: false,
    disablePadding: false,
    label: "Startdatum",
  },

  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Geldnemer",
  },
  {
    id: "transactionType",
    numeric: false,
    disablePadding: false,
    label: "Type transactie",
  },

  {
    id: "transactionSubType",
    numeric: false,
    disablePadding: false,
    label: "Subniveau",
  },

  {
    id: "principalAmount",
    numeric: false,
    disablePadding: false,
    label: "Hoofdsom",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "view",
    numeric: false,
    label: "Opties",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#212F56",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#212F56",
  },
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:Mui-selected': {
//     backgroundColor: "#FF9E00",
//   }
// }));

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    handleDeleteCustomer,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    user,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />

        </StyledTableCell> */}
        {(user.userType === UserRoles.employee ||
          user.userType === UserRoles.admin) &&
          headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        {user.userType === UserRoles.financier &&
          headCellsFinancerFlow.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
              align={headCell.numeric ? "center" : "left"}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    onClickAddTransaction,
    canBid,
    user,
    handleDeleteCustomer,
    selected,
    status,
    identifier,
    identifierForDel,
    handleOffer,
    multipleDelete,
    deleteToggler,
  } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} geselecteerd
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%", margin: "3" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Transacties
        </Typography>
      )}

      {selected.length > 0 && user.userType === UserRoles.financier && (
        <Typography
          sx={{ flex: "end " }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {canBid === true ? (
            <Button
              className="btn-primary"
              style={{ borderRadius: "10px" }}
              size="sm"
              onClick={() => {
                props.handleOffer(props.identifier);
              }}
            >
              Meerdere voorstellen indienen
            </Button>
          ) : (
            <Typography
              sx={{ flex: "1 1 100%", margin: "3" }}
              variant="subtitle1"
              component="div"
            >
              Kan niet bieden op transacties met de status Gesloten of
              Vergrendeld
            </Typography>
          )}
        </Typography>
      )}

      {numSelected > 0 && user.userType === UserRoles.admin && (
        <span>
          {multipleDelete === true ? (
            <IconButton>
              <Tooltip title="Delete">
                <DeleteIcon
                  // onClick={() => handleDeleteCustomer(identifierForDel)}
                  onClick={() => deleteToggler(identifierForDel)}
                  style={{ color: "#FF9E00", margin: "10px" }}
                />
              </Tooltip>
            </IconButton>
          ) : (
            "kan niet verwijderen met status Voltooid"
          )}
        </span>
      )}

      {numSelected === 0 &&
        (user.userType === UserRoles.employee ||
          user.userType === UserRoles.admin) && (
          <IconButton>
            <Tooltip title="Transactie toevoegen">
              <AddIcon
                onClick={onClickAddTransaction}
                style={{ color: "#FF9E00", margin: "10px", muiName: "Add" }}
              />
            </Tooltip>
          </IconButton>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const Transcties = () => {
  const [tranObj, setTranObj] = useState({});
  const [status_, setStatus_] = useState("");
  const [numVoltooid, setNumVoltooid] = useState(0);
  const { user } = useAuth();
  const [rows, setRows] = useState([]);
  const [transDetail, setTransDetail] = useState([{}]);
  const details = [{}];
  const { data: transactionList, loading } = GetTransactionList();
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteToggle, setOpenDeleteToggle] = useState(false);
  const identifier = [];
  const ids = [];
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  const onClickAddTransaction = () => {
    navigate(`/transacties/add`);
  };

  const onClickDetailsransaction = (e, row) => {
    if (user.userType === UserRoles.admin) {
      navigate(`/transacties/dashboard/` + row.identifier);
    } else if (user.userType === UserRoles.employee) {
      navigate(`/transacties/dashboard/` + row.identifier);
    } else if (user.userType === UserRoles.financier) {
      navigate(`/financiers-transctie-dashboard/` + row.identifier);
    }
  };

  // useEffect(() => {
  //   if (transactionList) {
  //     let translated = transactionList.map((item) => {
  //       return createData(
  //         item.transactionId,
  //         item.transactionNo,
  //         item.financer,
  //         item.customerName,
  //         item.status,
  //         item.principalAmount,
  //         item.startdate,
  //         item.endDateTime,
  //         item.identifier,
  //         item.startDateTime,
  //         item.enddate,
  //         item.loanEndDate,
  //         item.transactionSubType,
  //         item.transactionType
  //       );
  //     });
  //     // translated = [...translated, ...translated];
  //     translated.sort((a, b) => b.transactionId - a.transactionId);
  //     setRows(translated);
  //     setRefresh(false);
  //   }
  //   // return setRows([]);
  // }, [transactionList,]);
  const fetchData = async () => {
    try {
      const result = await TransctiesService.getTransactionList();
      if (result.response) {
        let translated = result.response.map((item) => {
          return createData(
            item.transactionId,
            item.transactionNo,
            item.financer,
            item.customerName,
            item.status,
            item.principalAmount,
            item.startdate,
            item.endDateTime,
            item.identifier,
            item.startDateTime,
            item.enddate,
            item.loanEndDate,
            item.transactionSubType,
            item.transactionType,
            item.isLockedUp,
            item.lockupTime
          );
        });
        // translated = [...translated, ...translated];
        translated.sort((a, b) => b.transactionId - a.transactionId);
        setRows(translated);
        setSelected([]);
      }
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    fetchData(); // Run immediately

    const interval = setInterval(() => {
      fetchData(); // Run every 5 minutes
    }, 1 * 60 * 1000);

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, [transactionList]);

  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("transactionId");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [identifierForDel, setIdentifierForDel] = useState([]);
  const [deleteArray, setDeleteArray] = useState([]);
  const [canBid, setCanbid] = useState(false);

  const deleteButtonActive = () => {
    let selectedObjs = [];
    selected.forEach((id) => {
      const selectedObj = rows.find((obj) => obj.transactionId === id);
      selectedObjs.push(selectedObj);
    });
    const allTrue = selectedObjs.filter((obj) => obj.status === "Voltooid");

    if (allTrue.length === 0) {
      setMultipleDelete(true);
    } else {
      setMultipleDelete(false);
    }
  };
  const financierCanBid = () => {
    let selectedObjs = [];
    selected.forEach((id) => {
      const selectedObj = rows.find((obj) => obj.transactionId === id);
      selectedObjs.push(selectedObj);
    });
    const allTrue = selectedObjs.filter(
      (obj) =>
        obj.status === "Voltooid" ||
        obj.status === "Voltooid zonder selectie" ||
        obj.isLockedUp === true
    );

    if (allTrue.length > 0) {
      setCanbid(false);
    } else {
      setCanbid(true);
    }
  };
  useEffect(() => {
    if (selected.length > 0) {
      deleteButtonActive();
      financierCanBid();
    }
  }, [selected]);

  const deleteIdentifiers = (selected) => {
    let deleteIdentifiers = [];
    selected.forEach((id) => {
      const delIdentifiers = rows.find((obj) => obj.transactionId === id);
      deleteIdentifiers.push(delIdentifiers);
    });
    const identifierDel = deleteIdentifiers.map((obj) => {
      return obj.identifier;
    });
    setIdentifierForDel(identifierDel);
  };

  useEffect(() => {
    if (selected.length > 0) {
      deleteIdentifiers(selected);
    }
  }, [selected]);

  const Schema = yup.object().shape({
    firstname: yup.string(),
    // name: yup.string().matches(/^[a-zA-Z]+$/), //this will validate the type of name should only be text
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const deleteAPICall = async (id) => {
    const response = await TransctiesService.deleteTransaction(id);
    if (response?.status) {
      toast.success("Transactie succesvol verwijderd", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Er is een probleem om de transactie te verwijderen", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    try {
      const result = await TransctiesService.getTransactionList();
      if (result.response) {
        let translated = result.response.map((item) => {
          return createData(
            item.transactionId,
            item.transactionNo,
            item.financer,
            item.customerName,
            item.status,
            item.principalAmount,
            item.startdate,
            item.endDateTime,
            item.identifier,
            item.startDateTime,
            item.enddate,
            item.loanEndDate,
            item.transactionSubType,
            item.transactionType,
            item.isLockedUp,
            item.lockupTime
          );
        });
        // translated = [...translated, ...translated];
        translated.sort((a, b) => b.transactionId - a.transactionId);
        setRows(translated);
        setSelected([]);
      }
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteToggler = (selected) => {
    setDeleteArray(selected);

    return setOpenDeleteToggle(!openDeleteToggle);
  };

  const cancelButtonClickHandler = () => {
    setOpenDeleteToggle(!openDeleteToggle);
    setDeleteArray([]);
    setSelected([]);
  };

  const handleDeleteCustomer = async () => {
    const deleteSel = [...deleteArray];
    // const data = rows.filter((item) => {
    //   if (item.id === selected) return item;
    // });
    try {
      deleteSel.map((item) => {
        deleteAPICall(item);
      });
    } catch (error) {
      toast.error("Serverfout", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setDeleteArray([]);
    setOpenDeleteToggle(!openDeleteToggle);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.transactionId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const count = 0;
  const handleClick = (event, transactionId, status) => {
    setStatus_(status);

    const selectedIndex = selected.indexOf(transactionId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, transactionId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (transactionId) => selected.indexOf(transactionId) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const toggler = () => {
    return setIsOpen(!isOpen);
  };

  const handleOffer = (identifier) => {
    const id = identifier.length - 1;
    details.shift();
    ids.length >= details &&
      ids.map(async (idss) => {
        return DashboardService.getFinancierTransaction(idss).then(
          (response) => {
            details.push(response.response);
          }
        );
      });
    setTransDetail(details);
    selected.length <= 10
      ? setIsOpen(!isOpen)
      : toast.success("u kunt maximaal tien velden selecteren.", {
          position: toast.POSITION.TOP_RIGHT,
        });
  };

  const principalAmountConverter = (number) => {
    const convertedNumber = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
    }).format(number);
    return convertedNumber.slice(0, -1);
  };

  const isLockedUp = (date, transactionSubType) => {
    const transactionDate = new Date(date);
    const currentDate = new Date();
    const transationDateInSec = transactionDate.getTime() / 1000;
    const CurrentDateInSec = currentDate.getTime() / 1000;
    let timer = 0;
    if (
      transactionSubType === "Fixe" ||
      transactionSubType === "Lineair " ||
      transactionSubType === "Annuïtair"
    ) {
      timer = 300;
    } else {
      timer = 900;
    }

    if (
      transationDateInSec > CurrentDateInSec ||
      transationDateInSec + timer < CurrentDateInSec
    ) {
      return true;
    } else {
      const difference = Math.floor(
        transationDateInSec + timer - CurrentDateInSec
      );
      return false;
    }
  };
  let differenceArr = [];

  const arrayOfDiff = (diff) => {
    differenceArr.push(diff);
  };

  const displayDate = (date) => {
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const year = newDate.getFullYear();
    const simpleDateString = `${day}- ${month}-${year}`;
    return simpleDateString;
  };
  return (
    <ThemeProvider theme={outerTheme}>
      <Box sx={{ color: "#212F56", width: "100%" }}>
        <Paper sx={{ color: "#212F56", width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            onClickAddTransaction={onClickAddTransaction}
            handleDeleteCustomer={handleDeleteCustomer}
            user={user}
            selected={selected}
            status={status_}
            identifier={identifier}
            identifierForDel={identifierForDel}
            handleOffer={handleOffer}
            multipleDelete={multipleDelete}
            deleteToggler={deleteToggler}
            fetchData={fetchData}
            canBid={canBid}
          />
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                handleDeleteCustomer={handleDeleteCustomer}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                user={user}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}

                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.transactionId);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    {
                      user.userType === UserRoles.financier &&
                        selected.length <= 10 &&
                        isItemSelected &&
                        identifier.push(row.transactionId) &&
                        ids.push(row.identifier);
                    }

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.transactionId, row.status)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.transactionId}
                        selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell> */}
                        {/* <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.transactionId}
                        </TableCell> */}

                        {user.userType === UserRoles.financier && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              // disabled="true"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                        )}
                        {user.userType === UserRoles.financier && (
                          <TableCell align="left">
                            {row?.startDateTime}
                          </TableCell>
                        )}
                        {(user.userType === UserRoles.employee ||
                          user.userType === UserRoles.admin) && (
                          <TableCell align="left">
                            {displayDate(row.startDateTime)}
                          </TableCell>
                        )}
                        {/* {(user.userType === UserRoles.employee ||
                          user.userType === UserRoles.admin) && (
                          <TableCell align="left">
                            <b>{row.financer}</b>
                          </TableCell>
                        )} */}
                        {(user.userType === UserRoles.employee ||
                          user.userType === UserRoles.admin) && (
                          <TableCell align="left">
                            {displayDate(row.loanEndDate)}
                          </TableCell>
                        )}
                        <TableCell align="left">{row.customerName}</TableCell>
                        <TableCell align="left">
                          {row.transactionType}
                        </TableCell>
                        <TableCell align="left">
                          {row.transactionSubType}
                        </TableCell>
                        <TableCell align="left">
                          {row &&
                            "€ " +
                              principalAmountConverter(row.principalAmount)}
                        </TableCell>

                        {(user.userType === UserRoles.employee ||
                          user.userType === UserRoles.admin) && (
                          <TableCell align="center">
                            {row?.status === "Voltooid" ||
                            row?.status === "Voltooid zonder selectie" ? (
                              <div className="bg-primary  dateicon">
                                {row?.status}
                              </div>
                            ) : (
                              <div>
                                {isLockedUp(
                                  row.startDateTime,
                                  row.transactionSubType
                                ) === false ? (
                                  <div className={"bg-secondary  dateicon"}>
                                    Vergrendeld
                                  </div>
                                ) : (
                                  <div
                                    className={"dateicon"}
                                    style={{
                                      backgroundColor: "#ff9e0047",
                                      color: "#212F56",
                                    }}
                                  >
                                    Actief
                                  </div>
                                )}
                              </div>
                            )}
                          </TableCell>
                        )}

                        {user.userType === UserRoles.financier && (
                          <TableCell align="center" style={{ width: "auto" }}>
                            {row?.status === "Voltooid" ||
                            row?.status === "Voltooid zonder selectie" ? (
                              <div className="bg-primary  dateicon">
                                {row?.status}
                              </div>
                            ) : (
                              <div>
                              {row?.isLockedUp === true ? (
                                <div className={"bg-secondary  dateicon"}>
                                  Vergrendeld
                                </div>
                              ) : (
                                <div
                                  className={"dateicon"}
                                  style={{
                                    backgroundColor: "#ff9e0047",
                                    color: "#212F56",
                                  }}
                                >
                                  Actief
                                </div>
                              )}
                            </div>
                            )}
                          </TableCell>
                        )}

                        <TableCell align="left" style={{ paddingLeft: "15px" }}>
                          <VisibilityIcon
                            onClick={(e) => onClickDetailsransaction(e, row)}
                            style={{ color: "#FF9E00", margin: "3px" }}
                          />
                          {user?.userType === UserRoles.admin &&
                            row.status !== "Voltooid" && (
                              <DeleteIcon
                                // onClick={() =>
                                //   handleDeleteCustomer([row.identifier])
                                // }
                                onClick={() => deleteToggler([row.identifier])}
                                style={{ color: "#FF9E00", margin: "3px" }}
                              />
                            )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Rijen per pagina"
            labelDisplayedRows={({ from, to, count }) =>
              `${from} - ${to} van ${count !== -1 ? count : `meer dan ${to}`}`
            }
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Regelafstand"
        />
        <CommonModal
          isOpen={isOpen}
          title={"Aanbieding Indienen"}
          toggler={toggler}
          size="xl"
        >
          <SelectedFinancierTransaction
            transaction={identifier}
            details={transDetail}
            toggler={toggler}
          />
        </CommonModal>
        <CommonModal
          isOpen={openDeleteToggle}
          title={"Alert"}
          toggler={deleteToggler}
          size="xl"
        >
          <h3>Weet u zeker dat u deze Transactie wilt verwijderen</h3>
          <div
            style={{
              margin: "25px 15px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ marginRight: "15px" }}
              onClick={() => handleDeleteCustomer()}
            >
              Delete
            </Button>
            <Button onClick={() => cancelButtonClickHandler()}>Cancel</Button>
          </div>
        </CommonModal>
      </Box>
    </ThemeProvider>
  );
};

export default Transcties;
