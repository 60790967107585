import React, { Fragment, useState } from "react";
import { Btn, H6 } from "../../../AbstractElements";
import ConfigDB from "../../../Config/ThemeConfig";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import CommonModal from "../../UiKits/Modals/common/modal";
import "./Knob.css";
import { useForm } from "react-hook-form";
import { FormNumberField } from "CommonElements/FormElements";
import { toast } from "react-toastify";
import { TransctiesService } from "utility";

import styled from "styled-components";

import { useAuth } from "context/user";
const DangerText = styled.div`
  color: #ff0000cc !important;
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 15px;
`;

const TransationRequestUi = ({ doRefresh, detail }) => {
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const { user } = useAuth();
  const [isAKL, setAKL] = React.useState(false);
  const [tariefForDisplay, setTariefForDisplay] = useState(0);
  const [feesForDisplay, setFeesForDisplay] = useState(0);

  const [displayNone] = useState("d-none");
  const ModalTitle = "Aanbieding indienen";

  const handleCheckBox = (e) => {
    setAKL(!isAKL);
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState("");

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      setAKL(false);
    }
  };
  const toggle1 = () => {
    setModal1(!modal1);
    if (!modal1) {
      setAKL(false);
    }
  };

  const handleEditFee = () => {
    toggle();
    // setValue("offerPercentage", detail?.proposal?.surcharge);
    // setValue("Fee", detail?.proposal?.fee);
    setFeesForDisplay(0);
    setTariefForDisplay(0);
  };

  const handleAddUserSubmit = async (formdata) => {
    if (isAKL) {
      setError("");
      const submitObject = {
        // "userId": user.userId,
        transactionId: parseFloat(detail.id),
        fee: detail?.showFees === true ? feesForDisplay : 0,
        offerPercentage: parseFloat(tariefForDisplay),
        // "offerDate": ""
      };
      // console.log(submitObject);
      try {
        const resp = await TransctiesService.setTransactionoffer(submitObject);
        doRefresh();
        toast.success("Uw voorstel is verstuurd.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        toggle();
        setFeesForDisplay(0);
        setTariefForDisplay(0);
      } catch (error) {
        toast.success(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      setError("Bevestiging is verplicht.");
    }
  };

  const handlRefresh = (formdata) => {
    toggle1();
  };
  // console.log(detail);

  const feesChangeHandler = (e) => {
    // console.log(e);
    setFeesForDisplay(e);
  };
  const tariefChangeHandler = (e) => {
    setTariefForDisplay(e);
  };
  function decimalToCommaSeparatedInt(decimalString) {
    const number = Number(decimalString);
    const checkForInteger = Number.isInteger(number);
    // console.log(number);
    if (checkForInteger === true) {
      return `(${decimalString} %)`;
    } else if (checkForInteger === false) {
      const numberAdjustment = parseFloat(decimalString).toFixed(3);
      // console.log(numberAdjustment);
      if (numberAdjustment === ".") {
        let num = decimalString.replace(".", "");
        return `(${num} %)`;
      } else {
        const parts = numberAdjustment.toString().split(".");
        const integarValue = parts[0];
        const decimalValue = parts[1];
        // console.log(integarValue);
        return `(${integarValue},${decimalValue} %)`;
      }
    }
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Card className="o-hidden border-0 min-h-400">
            <CardBody>
              <div className="media-body text-center">
                <H6>{user.userName.split(" ")[0]}</H6> <br />
                {detail?.status === 4 ? (
                  <div>
                    {detail?.proposal !== null ? (
                      <div>
                        {detail?.proposal.isSelected === true ? (
                          <>
                            <p>
                              Transactie is voltooid, uw bod is geselecteerd
                            </p>

                            {detail?.showFees === true && (
                              <p style={{ padding: "10px" }}>
                                Fee:{" "}
                                {(detail?.proposal?.fee / 100).toLocaleString(
                                  "nl-NL",
                                  {
                                    style: "percent",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                    useGrouping: true,
                                  }
                                )}
                              </p>
                            )}
                            {detail?.showFees === false && (
                              <p>
                                Tarief:{" "}
                                {(
                                  detail?.proposal?.surcharge / 100
                                ).toLocaleString("nl-NL", {
                                  style: "percent",
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                  useGrouping: true,
                                })}
                              </p>
                            )}
                            {detail?.showFees === true && (
                              <p>
                                Tarief (excl. fee):{" "}
                                {(
                                  detail?.proposal?.surcharge / 100
                                ).toLocaleString("nl-NL", {
                                  style: "percent",
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                  useGrouping: true,
                                })}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <p>
                              Transactie is geselecteerd, maar uw bod is niet
                              geselecteerd
                            </p>

                            {detail?.showFees === true && (
                              <p style={{ padding: "10px" }}>
                                Fee:{" "}
                                {(detail?.proposal?.fee / 100).toLocaleString(
                                  "nl-NL",
                                  {
                                    style: "percent",
                                    minimumFractionDigits: 3,
                                    maximumFractionDigits: 3,
                                    useGrouping: true,
                                  }
                                )}
                              </p>
                            )}
                            {detail?.showFees === false && (
                              <p>
                                Tarief:{" "}
                                {(
                                  detail?.proposal?.surcharge / 100
                                ).toLocaleString("nl-NL", {
                                  style: "percent",
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                  useGrouping: true,
                                })}
                              </p>
                            )}
                            {detail?.showFees === true && (
                              <p>
                                Tarief (excl. fee):{" "}
                                {(
                                  detail?.proposal?.surcharge / 100
                                ).toLocaleString("nl-NL", {
                                  style: "percent",
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                  useGrouping: true,
                                })}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {detail?.proposal == null && detail?.status !== 4 ? (
                          <>
                            <p style={{ padding: "10px" }}>
                              Nog geen bieding gedaan.
                            </p>
                            {detail?.isLockedUp === false && (
                              <Btn
                                attrBtn={{
                                  color: "primary",
                                  className: "text-white m-t-50",
                                  onClick: () => handleEditFee(),
                                }}
                              >
                                Voorstel doen
                              </Btn>
                            )}
                          </>
                        ) : (
                          <>
                            <p style={{ padding: "10px" }}>
                              Transactie is geselecteerd, U hebt niet geboden op
                              deze transactie.
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    {detail?.proposal !== null ? (
                      <>
                        {detail?.showFees === true && (
                          <p style={{ padding: "10px" }}>
                            Fee:{" "}
                            {(detail?.proposal?.fee / 100).toLocaleString(
                              "nl-NL",
                              {
                                style: "percent",
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                                useGrouping: true,
                              }
                            )}
                          </p>
                        )}
                        {detail?.showFees === false && (
                          <p>
                            Tarief:{" "}
                            {(detail?.proposal?.surcharge / 100).toLocaleString(
                              "nl-NL",
                              {
                                style: "percent",
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                                useGrouping: true,
                              }
                            )}
                          </p>
                        )}
                        {detail?.showFees === true && (
                          <p>
                            Tarief (excl. fee):{" "}
                            {(detail?.proposal?.surcharge / 100).toLocaleString(
                              "nl-NL",
                              {
                                style: "percent",
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                                useGrouping: true,
                              }
                            )}
                          </p>
                        )}
                        {detail?.isLockedUp === false &&
                          detail?.status !== 5 && (
                            <Btn
                              attrBtn={{
                                color: "primary",
                                className: "text-white m-t-50 ",
                                onClick: () => handleEditFee(),
                              }}
                            >
                              Aanpassen
                            </Btn>
                          )}
                      </>
                    ) : (
                      <div>
                        {detail?.proposal == null && (
                          <>
                            <p style={{ padding: "10px" }}>
                              Nog geen bieding gedaan.
                            </p>
                            {detail?.isLockedUp === false &&
                              detail?.status !== 5 && (
                                <Btn
                                  attrBtn={{
                                    color: "primary",
                                    className: "text-white m-t-50",

                                    onClick: () => handleEditFee(),
                                  }}
                                >
                                  Voorstel doen
                                </Btn>
                              )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CommonModal
        isOpen={modal}
        title={ModalTitle}
        toggler={toggle}
        size="xl"
        displayNone={displayNone}
      >
        <Row className="d-flex justify-content-center">
          <Col sm="10" className="d-flex flex-column justify-content-between">
            {/* <div className="text-center">
              2Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum,
              dolor! Sit minus rerum, sapiente amet praesentium labore animi
              aut, eius nesciunt, adipisci incidunt quos corrupti cum. Modi
              sequi ratione a? Lorem, ipsum dolor sit amet consectetur
              adipisicing elit. Earum, dolor! Sit minus rerum, sapiente amet
            </div> */}
            In de onderstaande velden kunt u een tarief (exclusief eventuele
            fee) en de fee (indien van toepassing) voor de geselecteerde lening
            invoeren. Wanneer u vervolgens op de knop ‘aanbieding indienen’
            klikt, wordt het tarief verstuurd.
            <Form className="p-5" onSubmit={handleSubmit(handleAddUserSubmit)}>
              <FormGroup>
                <Row>
                  {/* <Label className="col-form-label" for="recipient-name"> 
                      {"Hoofdsom"}
                    </Label> */}
                  {detail?.showFees === true && (
                    <Col>
                      <FormNumberField
                        className="form-control"
                        min="0"
                        max="100"
                        step="0.001"
                        required={true}
                        type="number"
                        label={`Fee ${decimalToCommaSeparatedInt(
                          feesForDisplay
                        )}`}
                        name="Fee"
                        control={control}
                        // placeholder="Fee"
                        value={feesForDisplay}
                        onChange={(e) => feesChangeHandler(e)}
                      />
                    </Col>
                  )}
                  {detail?.showFees === false && (
                    <Col>
                      <FormNumberField
                        className="form-control"
                        min="0"
                        max="100"
                        type="number"
                        step="0.001"
                        label={`Tarief ${decimalToCommaSeparatedInt(
                          tariefForDisplay
                        )}
                        `}
                        required={true}
                        name="offerPercentage"
                        control={control}
                        // placeholder="Tarief"
                        value={tariefForDisplay}
                        onChange={(e) => tariefChangeHandler(e)}
                      />
                    </Col>
                  )}{" "}
                  {detail?.showFees === true && (
                    <Col>
                      <FormNumberField
                        className="form-control"
                        min="0"
                        max="100"
                        type="number"
                        step="0.001"
                        label={`Tarief (excl. fee) ${decimalToCommaSeparatedInt(
                          tariefForDisplay
                        )}
                        `}
                        required={true}
                        name="offerPercentage"
                        control={control}
                        value={tariefForDisplay}
                        // placeholder="Tarief (excl. fee)"
                        onChange={(e) => tariefChangeHandler(e)}
                      />
                    </Col>
                  )}
                </Row>
              </FormGroup>
              <Row className="justify-content-center">
                <td>
                  <div className="checkbox checkbox-dark">
                    {/* <Input id="inline-1" type="checkbox" />
                    <Label for="inline-1">  Ik ga akkoord met het versturen van dit voorstel.</Label> */}

                    <Input
                      id="inline-3"
                      type="checkbox"
                      checked={isAKL}
                      onClick={handleCheckBox}
                      name="akl"
                      control={control}
                    />
                    <Label for="inline-3">
                      Ik ga akkoord met het versturen van dit voorstel.
                    </Label>
                  </div>
                  {error && <DangerText>*{error}</DangerText>}
                </td>
              </Row>
              <Row className=" text-center">
                <Col>
                  {isAKL ? (
                    <Btn attrBtn={{ color: "primary", className: "mt-5 " }}>
                      Aanbieding indienen
                    </Btn>
                  ) : (
                    <Btn attrBtn={{ color: "secondary", className: "mt-5 " }}>
                      Aanbieding indienen
                    </Btn>
                  )}
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CommonModal>

      <CommonModal
        isOpen={modal1}
        title="Voorstel intrekken"
        toggler={toggle1}
        size="xl"
        displayNone={displayNone}
      >
        <Row className="d-flex justify-content-center">
          <Col sm="10" className="d-flex flex-column justify-content-between">
            <div className="welkon-form-popup-cont-div">
              <p>
                Vanaf dit moment zijn de bieding opties voor financiers
                gedeacteerd voor deze transactie.
              </p>
            </div>
            <Form
              className="welkon-form-popup-main"
              onSubmit={handleSubmit(handlRefresh)}
            >
              <Row className="step1_header">
                <Col>
                  <Btn
                    attrBtn={{
                      color: "primary",
                      className: "welkon-form-popup-btn-attr ",
                    }}
                  >
                    Okay
                  </Btn>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CommonModal>
    </Fragment>
  );
};

export default TransationRequestUi;
